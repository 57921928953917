import PropTypes, { object } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import './TunnelHeader.css';

const TunnelArianeItem = ({ TunnelNavigation, UserChoice, title, index }) => {
  let userChoiceShow;
  const isDoing = TunnelNavigation.filter(
    (element) => element.status === 'doing'
  ).map((element2) => element2.index === index)[0];

  const isDone = TunnelNavigation.filter(
    (element) => element.status === 'done'
  ).filter((element2) => element2.index === index)[0];

  switch (title) {
    case 'Univers':
      userChoiceShow = UserChoice.Univers;
      break;
    case 'Type':
      userChoiceShow = UserChoice.Type;
      break;
    case 'Thématiques':
      userChoiceShow = UserChoice.Thématiques;
      break;
    case 'Déploiement':
      userChoiceShow = UserChoice.Déploiement;
      break;
    default:
      userChoiceShow = '';
  }

  return (
    <div className="Tunnel-Header-Action-Container">
      <div className="Tunnel-Header-Action-Pastille-Container">
        <span
          className={
            isDone
              ? 'Tunnel-Header-Action-Pastille-Container-Title-Done'
              : 'Tunnel-Header-Action-Pastille-Container-Title'
          }
        >
          {title}
        </span>
        {!isDone ? (
          <>
            <div
              className={
                isDoing
                  ? `Tunnel-Header-Action-Pastille-${index + 1}-doing`
                  : `Tunnel-Header-Action-Pastille-${index + 1}`
              }
            >
              <p>{index + 1}</p>
            </div>
          </>
        ) : (
          <>
            <div className={`Tunnel-Header-Action-Pastille-${index + 1}-done`}>
              <span className="fas fa-check-circle" />
            </div>
            <div
              className={`Tunnel-Header-Action-Behind-Pastille-${
                index + 1
              }-done`}
            />
            <div className="Tunnel-Header-User-Choice">
              <span>{userChoiceShow}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

TunnelArianeItem.propTypes = {
  TunnelNavigation: PropTypes.arrayOf(object).isRequired,
  UserChoice: PropTypes.arrayOf(object).isRequired,
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  TunnelNavigation: state.Tunnel.TunnelNavigation,
  UserChoice: state.Tunnel.UserChoice,
});

export default connect(mapStateToProps)(TunnelArianeItem);
