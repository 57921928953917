/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes, { object } from 'prop-types';
import { connect } from 'react-redux';
import Animation from '../../Animation';

const TunnelChoiceCompany = ({
  name,
  description,
  imageColor,
  imageGrey,
  idReponse,
  smallDesc,
  dispatch,
  initialChoice,
  Company,
}) => {
  const [reduxChoice, setReduxChoice] = useState(true);
  const handleClick = (choiceId) => {
    const choosenCompany = Company[initialChoice].filter(
      (element) => element.choosen
    ).length;
    if (choosenCompany > 0) {
      dispatch({
        type: 'RESET_COMPANY_CLICKED',
        payload: { typeOfCompany: initialChoice },
      });
    }
    setReduxChoice(!reduxChoice);
    dispatch({
      type: 'COMPANY_CLICKED',
      payload: {
        idChoice: choiceId,
        choosen: reduxChoice,
        typeOfCompany: initialChoice,
      },
    });
  };

  const isChoosen = Company[initialChoice].filter(
    (element) => element.idReponse === idReponse
  )[0].choosen;

  return (
    <>
      <div
        onClick={() => handleClick(idReponse)}
        onKeyDown={() => handleClick(idReponse)}
        role="button"
        tabIndex={0}
        className={
          isChoosen
            ? `Tunnel-Choice-Container-${idReponse}-choosen`
            : `Tunnel-Choice-Container-${idReponse}`
        }
      >
        <div className="Tunnel-Choice-Univers-Img">
          <img src={isChoosen ? imageColor : imageGrey} alt={name} />
        </div>
        <p>
          {' '}
          {name} <br />
          <span className="Tunnel-Choice-Container-Desc">({smallDesc})</span>
        </p>
        {isChoosen ? (
          <div className="Tunnel-Choice-Check-Good">
            <span className="fas fa-check-circle good" />
          </div>
        ) : (
          <div className="Tunnel-Choice-Check" />
        )}
      </div>
      {isChoosen && name !== 'Autre' && (
        <Animation
          wrapperElement="div"
          direction="up"
          className="Tunnel-Choice-Infos-Action"
          distance={200}
        >
          {initialChoice === 'Université' || name === 'Structure sanitaire' ? (
            <h3>{name} :</h3>
          ) : (
            <h3>{smallDesc} :</h3>
          )}
          <div>
            {' '}
            {/* <span>{description}</span> */}
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </Animation>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  Company: state.Company,
  initialChoice: state.Tunnel.UserChoice.Univers,
});

TunnelChoiceCompany.propTypes = {
  Company: PropTypes.arrayOf(object).isRequired,
  initialChoice: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  idReponse: PropTypes.number.isRequired,
  imageColor: PropTypes.string.isRequired,
  imageGrey: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  smallDesc: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(TunnelChoiceCompany);
