import React from 'react';
import PropTypes from 'prop-types';

import Header from '../Home/Header';
import ConversionTunnel from './ConversionTunnel';

import '../../styles/css/components/ConvertionTunnel/Univers.css';

const Univers = ({ match, history }) => {
  const { params } = match;
  return (
    <div className="Univers">
      <Header isTunnel />
      <ConversionTunnel params={params} history={history} />
    </div>
  );
};

Univers.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({}).isRequired,
  }).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default Univers;
