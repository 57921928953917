import React, { useState } from 'react';
import useSound from 'use-sound';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { tada, zoomOutLeft, zoomIn } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import styled, { keyframes } from 'styled-components';
import './Bureau.css';
import './Drawer.css';
import imageDrawer from '../../styles/img/Game/BUREAU-4_tirroir-1_CROPPED-01.svg';
import imageButton from '../../styles/img/Game/BUREAU-4_tirroirZ-4_CROPPED_zaxspv.svg';
import knife from '../../styles/img/Game/BUREAU-coupepapier_45.svg';
import woosh from '../../styles/sound/woosh.mp3';
import pop from '../../styles/sound/pop.mp3';
import tear from '../../styles/sound/tear.mp3';
import drawersound1 from '../../styles/sound/drawer1.mp3';
import drawersound2 from '../../styles/sound/drawer2.mp3';
import drawersound3 from '../../styles/sound/drawer3.mp3';
import error from '../../sound/Error.mp3';
import {
  counterClick,
  drawerButton1Click,
  drawerButton2Click,
  drawerButton3Click,
  drawerButton1Reset,
  drawerButton2Reset,
  drawerButton3Reset,
  showMessage,
  addObject,
} from '../../actions/index';

const FlipAnimation = keyframes`${zoomIn}`;
const FlipDiv = styled.div`
  animation: 0.5s ${FlipAnimation};
`;

const Drawer = ({
  Objects,
  ObjectStatus,
  drawerCounter,
  havingKnife,
  Modal,
  dispatch,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showDrawerClosed, setShowDrawerClosed] = useState(false);
  const [play1] = useSound(drawersound1);
  const [play2] = useSound(drawersound2);
  const [play3] = useSound(drawersound3);
  const [playError] = useSound(error);

  const styles = {
    tada: {
      animation: 'infinite 5s',
      animationName: Radium.keyframes(tada, 'tada'),
    },
    zoomOutLeft: {
      animation: '1s',
      animationName: Radium.keyframes(zoomOutLeft, 'zoomOutLeft'),
    },
  };

  const handleButton1 = () => {
    dispatch(drawerButton1Click());
    play1();
  };

  const handleButton2 = () => {
    dispatch(drawerButton2Click());
    play2();
  };

  const handleButton3 = () => {
    dispatch(drawerButton3Click());
    play3();
  };

  const handleCloseKnife = () => {
    dispatch({
      type: 'SHOW_MODAL_KNIFE',
      payload: false,
    });
  };

  const handleClick = () => {
    setShowModal(false);
    dispatch(counterClick());
    dispatch({ type: 'ADDING_KNIFE_DESKTOP', payload: true });
    dispatch(
      showMessage({
        title: 'Informations',
        message: 'Le couteau à papier a été ajouté à votre inventaire',
        show: true,
      })
    );
    dispatch({ type: 'KNIFE_FOUND', payload: true });
    dispatch(
      addObject({
        name: Objects.AllObjects.filter(
          (item) => item.name === 'Couteau à papier'
        ).map((item) => item.name)[0],
        image: Objects.AllObjects.filter(
          (item) => item.name === 'Couteau à papier'
        ).map((item) => item.image)[0],
      })
    );
  };

  const handleTryDrawer = () => {
    if (
      drawerCounter.count1 === 1 &&
      drawerCounter.count2 === 0 &&
      drawerCounter.count3 === 3
    ) {
      dispatch({ type: 'DRAWER_SUCCESS', payload: true });
      dispatch(counterClick());
      setShowDrawerClosed(!showDrawerClosed);
      setShowModal(!showModal);
    } else {
      setShowDrawerClosed(false);
      playError();
      dispatch(counterClick());
      dispatch(
        showMessage({
          title: 'Informations',
          message: 'La combinaison ne semble pas fonctionner',
          show: true,
        })
      );
    }
  };

  if (drawerCounter.count1 === 4) {
    dispatch(drawerButton1Reset());
  } else if (drawerCounter.count2 === 4) {
    dispatch(drawerButton2Reset());
  } else if (drawerCounter.count3 === 4) {
    dispatch(drawerButton3Reset());
  }

  let button1ClassName = 'button1-0';

  if (drawerCounter.count1 === 0) {
    button1ClassName = 'button1-0';
  } else if (drawerCounter.count1 === 1) {
    button1ClassName = 'button1-1';
  } else if (drawerCounter.count1 === 2) {
    button1ClassName = 'button1-2';
  } else if (drawerCounter.count1 === 3) {
    button1ClassName = 'button1-3';
  }

  let button2ClassName = 'button2-0';

  if (drawerCounter.count2 === 0) {
    button2ClassName = 'button2-0';
  } else if (drawerCounter.count2 === 1) {
    button2ClassName = 'button2-1';
  } else if (drawerCounter.count2 === 2) {
    button2ClassName = 'button2-2';
  } else if (drawerCounter.count2 === 3) {
    button2ClassName = 'button2-3';
  }

  let button3ClassName = 'button3-0';

  if (drawerCounter.count3 === 0) {
    button3ClassName = 'button3-0';
  } else if (drawerCounter.count3 === 1) {
    button3ClassName = 'button3-1';
  } else if (drawerCounter.count3 === 2) {
    button3ClassName = 'button3-2';
  } else if (drawerCounter.count3 === 3) {
    button3ClassName = 'button3-3';
  }

  let button1Zoom = 'zoom-button1-0';

  if (drawerCounter.count1 === 0) {
    button1Zoom = 'zoom-button1-0';
  } else if (drawerCounter.count1 === 1) {
    button1Zoom = 'zoom-button1-1';
  } else if (drawerCounter.count1 === 2) {
    button1Zoom = 'zoom-button1-2';
  } else if (drawerCounter.count1 === 3) {
    button1Zoom = 'zoom-button1-3';
  }

  let button2Zoom = 'zoom-button2-0';

  if (drawerCounter.count2 === 0) {
    button2Zoom = 'zoom-button2-0';
  } else if (drawerCounter.count2 === 1) {
    button2Zoom = 'zoom-button2-1';
  } else if (drawerCounter.count2 === 2) {
    button2Zoom = 'zoom-button2-2';
  } else if (drawerCounter.count2 === 3) {
    button2Zoom = 'zoom-button2-3';
  }

  let button3Zoom = 'zoom-button3-0';

  if (drawerCounter.count3 === 0) {
    button3Zoom = 'zoom-button3-0';
  } else if (drawerCounter.count3 === 1) {
    button3Zoom = 'zoom-button3-1';
  } else if (drawerCounter.count3 === 2) {
    button3Zoom = 'zoom-button3-2';
  } else if (drawerCounter.count3 === 3) {
    button3Zoom = 'zoom-button3-3';
  }

  return (
    <>
      {ObjectStatus.LetterOpen && (
        <audio src={tear} autoPlay>
          <track kind="captions" />
        </audio>
      )}
      {drawerCounter.addedKnife && (
        <audio src={woosh} autoPlay>
          <track kind="captions" />
        </audio>
      )}
      {havingKnife.hasKnife && (
        <div className="zoomleft">
          <img src={knife} alt="Couteau à papier" draggable="false" />
        </div>
      )}
      {drawerCounter.drawerSucceed ? (
        <>
          <audio src={pop} autoPlay>
            <track kind="captions" />
          </audio>
          <div
            className={
              showDrawerClosed
                ? 'ModalTricky-Background'
                : 'ModalTricky-Background Hide-Modal'
            }
          >
            <div className="ModalTricky-Container">
              <div className="ModalTricky-p">
                <p>
                  Vous avez déjà trouvé un couteau à papier dans ce tiroir.
                  <br /> Il figure dans votre inventaire.
                </p>
              </div>
              <div>
                <button
                  className="ButtonAction Action"
                  type="button"
                  onClick={() => setShowDrawerClosed(false)}
                >
                  Bien compris !
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          className={
            showDrawerClosed
              ? 'ModalTricky-Background'
              : 'ModalTricky-Background Hide-Modal'
          }
        >
          <div className="ModalTricky-Container">
            <div className="ModalTricky-p">
              <p>Une combinaison semble nécessaire pour ouvrir ce tiroir.</p>
            </div>
            <FlipDiv className="zoom-drawer">
              <div
                id={button1Zoom}
                role="button"
                onClick={handleButton1}
                onKeyDown={handleButton1}
                tabIndex={0}
              >
                <img src={imageButton} alt="button1" draggable="false" />
              </div>
              <div
                id={button2Zoom}
                role="button"
                onClick={handleButton2}
                onKeyDown={handleButton2}
                tabIndex={0}
              >
                <img src={imageButton} alt="button2" draggable="false" />
              </div>
              <div
                id={button3Zoom}
                role="button"
                onClick={handleButton3}
                onKeyDown={handleButton3}
                tabIndex={0}
              >
                <img src={imageButton} alt="button3" draggable="false" />
              </div>
            </FlipDiv>
            <div className="Buttons">
              <button
                className="ButtonAction Action"
                type="button"
                onClick={() => handleTryDrawer()}
              >
                Valider
              </button>
              <button
                className="ButtonAction Cancel"
                type="button"
                onClick={() => setShowDrawerClosed(false)}
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        className={
          showModal
            ? 'ModalTricky-Background'
            : 'ModalTricky-Background Hide-Modal'
        }
      >
        <div className="ModalTricky-Container">
          <div className="ModalTricky-p">
            <p>Vous avez trouvé un couteau à papier dans ce tiroir.</p>
          </div>
          <div className="ZoomDrawer">
            <div className="ZoomDrawerKnife">
              <StyleRoot>
                <img src={knife} alt="Couteau à papier" style={styles.tada} />
              </StyleRoot>
            </div>
          </div>
          <div>
            <button
              className="ButtonAction Action"
              type="button"
              onClick={handleClick}
            >
              Ajouter à l&apos;inventaire
            </button>
          </div>
        </div>
      </div>
      <div
        className="drawer"
        onClick={() => setShowDrawerClosed(true)}
        role="button"
        onKeyDown={() => setShowDrawerClosed(true)}
        tabIndex={0}
      >
        <img src={imageDrawer} alt="tirroir" draggable="false" />
        <div id={button1ClassName}>
          <img src={imageButton} alt="button1" draggable="false" />
        </div>
        <div id={button2ClassName}>
          <img src={imageButton} alt="button2" draggable="false" />
        </div>
        <div id={button3ClassName}>
          <img src={imageButton} alt="button3" draggable="false" />
        </div>
      </div>
      <div
        className={
          Modal.ModalKnife
            ? 'ModalTricky-Background'
            : 'ModalTricky-Background Hide-Modal'
        }
      >
        <div className="ModalTricky-Container">
          <div className="ModalObjectTricky-p">
            <p>Le couteau à papier peut-être utiliser pour couper du papier</p>
            <img src={knife} alt="lettre" className="w-50" draggable="false" />
          </div>
          <div className="ModalObject-Button">
            <div>
              <button
                className="ButtonAction Action"
                type="button"
                onClick={handleCloseKnife}
              >
                Bien compris !
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  Objects: state.Objects,
  ObjectStatus: state.Office.ObjectStatus,
  havingKnife: state.havingKnife,
  drawerCounter: state.drawerCounter,
  Modal: state.Office.Modal,
});

Drawer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  Objects: PropTypes.shape({
    AllObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
    object: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  ObjectStatus: PropTypes.shape({
    LetterClicked: PropTypes.bool.isRequired,
    LetterOpen: PropTypes.bool.isRequired,
  }).isRequired,
  Modal: PropTypes.shape({
    ModalMug: PropTypes.bool.isRequired,
    ModalKnife: PropTypes.bool.isRequired,
  }).isRequired,
  drawerCounter: PropTypes.shape({
    count1: PropTypes.number.isRequired,
    count2: PropTypes.number.isRequired,
    count3: PropTypes.number.isRequired,
    count4: PropTypes.number.isRequired,
    drawerSucceed: PropTypes.bool.isRequired,
    addedKnife: PropTypes.bool.isRequired,
  }).isRequired,
  havingKnife: PropTypes.shape({
    hasKnife: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(Drawer);
