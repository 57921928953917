/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable prefer-object-spread */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Bureau.css';
import './Modals.css';
import confetti from 'canvas-confetti';
import { connect } from 'react-redux';
import axios from 'axios';
import bureauEmpty from '../../styles/img/Game/bureau_empty_4.svg';
import Computer from './Computer';
import Mug from './Mug';
import Drawer from './Drawer';
import PowerChord from './PowerChord';
import Folder from './Folder';
import Board from './Board';
import Phone from './Phone';
import Intro from './Intro';
import Clock from './WrongObjects/Clock';
import Hat from './WrongObjects/Hat';
import Trash from './WrongObjects/Trash';
import DesktopChair from './WrongObjects/DesktopChair';
import DesktopLamp from './WrongObjects/DesktopLamp';
import DesktopTopLamp from './WrongObjects/DesktopTopLamp';
import DesktopDoor from './WrongObjects/DesktopDoor';
import DesktopPapers from './WrongObjects/DesktopPapers';
import DesktopCupboard from './WrongObjects/DesktopCupboard';
import DesktopLockers from './WrongObjects/DesktopLockers';
import desktopofdesktop from '../../styles/img/Game/BUREAU-desktop-of-desktop-2.svg';
import Notifications from './Notifications';
import introtext from './ContentText.json';
import GameBoardHeader from './GameBoardHeader';
import succes from '../../sound/successhorns1.mp3';
import cross from '../../img/cross.svg';
import ModalEmotionCard from '../Reusable/Modals/ModalEmotionCard';
import PreventionMessages from './PreventionMessages';
import pessimismeAudio from '../../styles/sound/board_pessimisme_fr.mp3';
import MusicThemeOffice from '../../styles/sound/My Sunshine.mp3';
import Congratulation from './Score/Congratulation';
import BubblesContent from './UserBoard/BubblesContent.json';

import Scoreboard from './Score/Scoreboard';

const Bureau = ({ ModalBureau, GameUsers, dispatch, Office }) => {
  const emotionPessimismeId = '24';
  const [inputs, setInputs] = useState({
    email: '',
  });

  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const postEmail = (event) => {
    event.preventDefault();
    if (inputs.email.length > 0) {
      const url = `${process.env.REACT_APP_BASE_URL}/api/scores/contact`;
      axios.post(url, inputs).then();
    }
    const win = window.open('https://tricky.fr/contact-us', '_self');
    win.focus();
  };

  useEffect(() => {
    if (
      GameUsers.gameStarted &&
      !GameUsers.timerPause &&
      !GameUsers.soundMuted
    ) {
      document.getElementById('audioOffice').volume = 0.05;
    }
  });

  useEffect(() => {
    const duration = 1.5 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    if (GameUsers.scoreboard) {
      const interval = setInterval(function () {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        const particleCount = 500 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.1, 0.4), y: Math.random() - 0.2 },
          })
        );
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.6, 0.9), y: Math.random() - 0.2 },
          })
        );
      }, 250);
    }
  }, [GameUsers.scoreboard]);

  useEffect(() => {
    const { position, advice } = BubblesContent.chattingHat1;
    const { position1, advice1 } = BubblesContent.chattingHat2;
    const { position2, advice2 } = BubblesContent.chattingHat3;
    const { position3, advice3 } = BubblesContent.chattingHat4;
    const { position4, advice4 } = BubblesContent.chattingHat5;
    if (Office.hatClicked) {
      setTimeout(() => {
        dispatch({
          type: 'SET_BUBBLE',
          payload: {
            position,
            advice,
          },
        });
        dispatch({
          type: 'GAME_ACTION',
          payload: {
            typeOfAction: 'bubbleDisplayed',
            action: true,
          },
        });
        setTimeout(() => {
          dispatch({
            type: 'GAME_ACTION',
            payload: {
              typeOfAction: 'bubbleDisplayed',
              action: false,
            },
          });
          setTimeout(() => {
            dispatch({
              type: 'SET_BUBBLE',
              payload: {
                position: position1,
                advice: advice1,
              },
            });
            dispatch({
              type: 'GAME_ACTION',
              payload: {
                typeOfAction: 'bubbleDisplayed',
                action: true,
              },
            });
            setTimeout(() => {
              dispatch({
                type: 'GAME_ACTION',
                payload: {
                  typeOfAction: 'bubbleDisplayed',
                  action: false,
                },
              });
            }, 3000);
            setTimeout(() => {
              dispatch({
                type: 'SET_BUBBLE',
                payload: {
                  position: position2,
                  advice: advice2,
                },
              });
              dispatch({
                type: 'GAME_ACTION',
                payload: {
                  typeOfAction: 'bubbleDisplayed',
                  action: true,
                },
              });
              setTimeout(() => {
                dispatch({
                  type: 'GAME_ACTION',
                  payload: {
                    typeOfAction: 'bubbleDisplayed',
                    action: false,
                  },
                });
              }, 3000);
              setTimeout(() => {
                dispatch({
                  type: 'SET_BUBBLE',
                  payload: {
                    position: position3,
                    advice: advice3,
                  },
                });
                dispatch({
                  type: 'GAME_ACTION',
                  payload: {
                    typeOfAction: 'bubbleDisplayed',
                    action: true,
                  },
                });
                setTimeout(() => {
                  dispatch({
                    type: 'GAME_ACTION',
                    payload: {
                      typeOfAction: 'bubbleDisplayed',
                      action: false,
                    },
                  });
                }, 3000);
                setTimeout(() => {
                  dispatch({
                    type: 'SET_BUBBLE',
                    payload: {
                      position: position4,
                      advice: advice4,
                    },
                  });
                  dispatch({
                    type: 'GAME_ACTION',
                    payload: {
                      typeOfAction: 'bubbleDisplayed',
                      action: true,
                    },
                  });
                  setTimeout(() => {
                    dispatch({
                      type: 'GAME_ACTION',
                      payload: {
                        typeOfAction: 'bubbleDisplayed',
                        action: false,
                      },
                    });
                  }, 2000);
                }, 3000);
              }, 3000);
            }, 3000);
          }, 2000);
        }, 2000);
        dispatch({
          type: 'OFFICE_OBJECT',
          payload: {
            typeOfObject: 'hatClicked',
            object: false,
          },
        });
      }, 2000);
    }
  }, [Office.hatClicked]);

  function parralax(e) {
    // eslint-disable-next-line react/no-this-in-sfc
    this.querySelectorAll('.layer').forEach((layer) => {
      const speed = layer.getAttribute('data-speed');
      const x = (window.innerWidth - e.pageX * speed) / 1000;
      const y = (window.innerHeight - e.pageY * speed) / 1000;
      // eslint-disable-next-line no-param-reassign
      layer.style.transform = `translateX(${x}px) translateY(${y}px)`;
    });
  }
  document.addEventListener('mousemove', parralax);

  return (
    <>
      {GameUsers.gameStarted && !GameUsers.timerPause && !GameUsers.soundMuted && (
        <audio src={MusicThemeOffice} id="audioOffice" autoPlay loop>
          <track kind="captions" />
        </audio>
      )}
      {GameUsers.scoreboard && (
        <audio src={succes} autoPlay>
          <track kind="captions" />
        </audio>
      )}
      <div className="Bureau-img">
        <Notifications />
        {!GameUsers.wannaLeave && (
          <Intro
            title={introtext.room1.title}
            content={introtext.room1.content}
            img={introtext.room1.img}
          />
        )}
        {/* <GameBoardHeader /> */}
        <div
          onClick={() => dispatch({ type: 'INCREMENT_COUNTER' })}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}
          className="Bureau-img-div"
        >
          <img src={bureauEmpty} alt="bureau" draggable="false" />
        </div>
        <PowerChord />
        <Computer />
        <Mug />
        <Board />
        <Drawer />
        <Folder />
        <Phone />
        <Clock />
        <Hat />
        <Trash />
        <DesktopChair />
        <DesktopLamp />
        <DesktopPapers />
        <DesktopTopLamp />
        <DesktopDoor />
        <DesktopCupboard />
        <DesktopLockers />
        <div
          onClick={() => dispatch({ type: 'INCREMENT_COUNTER' })}
          onKeyDown={() => {}}
          role="presentation"
          className="desktopdesktop-office"
        >
          <img
            src={desktopofdesktop}
            className="layer"
            data-speed="5"
            alt="desktop"
            draggable="false"
          />
        </div>
        {ModalBureau.ModalPessimisme && (
          <PreventionMessages
            typeEmotion={emotionPessimismeId}
            endTimer={25000}
            sound={pessimismeAudio}
          />
        )}
        {GameUsers.wannaLeave && (
          <div className="ModalTricky-Intro">
            <div className="ModalTricky-Intro-Card-DemoMulti">
              <h3 className="ModalTricky-Intro-Title-DemoMulti">
                Quitter le jeu
              </h3>
              <p className="ModalTricky-Intro-Text-DemoMulti">
                <strong>
                  Vous souhaitez tester l&apos;expérience en situation réelle ?
                </strong>
                Laissez-nous votre email pour être recontactés.
              </p>
            </div>
            <form onSubmit={postEmail} method="post">
              <input
                type="text"
                name="email"
                id="email"
                className="Congrat-Input-DemoMulti"
                placeholder="Email"
                onChange={onChange}
                autoComplete="off"
              />
              <div className="Board-Buttons">
                <button type="submit" className="ButtonAction Action">
                  Quitter
                </button>
                <button
                  className="ButtonAction Cancel"
                  type="button"
                  onClick={() =>
                    dispatch({
                      type: 'GAME_ACTION',
                      payload: {
                        typeOfAction: 'wannaLeave',
                        action: false,
                      },
                    })
                  }
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        )}
        {GameUsers.modalCardFound && (
          <>
            <div
              className={
                GameUsers.modalCardFound
                  ? 'ModalTricky-Background'
                  : 'Hide-Modal'
              }
            >
              <div className="ModalTricky-Emotion-Card">
                <div className="Emotion-Card-Container">
                  <ModalEmotionCard />
                </div>
              </div>
              <div
                className="CloseWindow"
                role="presentation"
                onClick={() =>
                  dispatch({
                    type: 'SHOW_MODAL_PREVENTION_GLOBAL',
                    payload: false,
                  })
                }
              >
                <img src={cross} alt="Fermer" title="Fermer" />
              </div>
            </div>
          </>
        )}
        {GameUsers.gameFinished && <Congratulation />}
        {GameUsers.scoreboard && <Scoreboard />}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  preventionMessages: state.prevention_messages.allMessages,
  ModalBureau: state.prevention_messages.ModalBureau,
  GameUsers: state.GameUsers,
  Room: state.Room,
  Office: state.Office.ObjectStatus,
});

Bureau.propTypes = {
  dispatch: PropTypes.func.isRequired,
  ModalBureau: PropTypes.shape({
    ModalPessimisme: PropTypes.bool.isRequired,
  }).isRequired,
  GameUsers: PropTypes.shape({
    modalCardFound: PropTypes.bool.isRequired,
    gameStarted: PropTypes.bool.isRequired,
    timerPause: PropTypes.bool.isRequired,
    soundMuted: PropTypes.bool.isRequired,
    gameFinished: PropTypes.bool.isRequired,
    scoreboard: PropTypes.bool.isRequired,
    wannaLeave: PropTypes.bool.isRequired,
  }).isRequired,
  Office: PropTypes.shape({
    hatClicked: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(Bureau);
