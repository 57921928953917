import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Congratulation.css';
import axios from 'axios';
import { connect } from 'react-redux';
import arcade from '../../../styles/img/Game/Tricky.global-scoreboard.svg';

const Congratulation = ({ GameUsers, dispatch }) => {
  const [showModal, setShowModal] = useState(true);

  const [inputs, setInputs] = useState({
    count: GameUsers.count,
    pseudo: '',
    firstname: GameUsers.firstname,
    lastname: GameUsers.lastname,
    email: '',
    time: GameUsers.time,
    game_id: 16,
    session_id: 0,
  });

  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (inputs.pseudo.length > 0 && inputs.email.length > 0) {
      const url = `${process.env.REACT_APP_BASE_URL}/api/scores`;
      axios
        .post(url, inputs)
        .then(setShowModal(false))
        .then((res) =>
          axios
            .get(
              `${process.env.REACT_APP_BASE_URL}/api/scores/groups/16/${res.data.id}`
            )
            .then((result) =>
              axios.post(
                `${process.env.REACT_APP_BASE_URL}/api/scores/prospectranking/`,
                result.data[0]
              )
            )
            .then(
              axios
                .get(`${process.env.REACT_APP_BASE_URL}/api/scores/groups/16`)
                .then((results) =>
                  dispatch({
                    type: 'GAME_ACTION',
                    payload: {
                      typeOfAction: 'rankingDatas',
                      action: results.data,
                    },
                  })
                )
            )
        );
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'email',
          action: inputs.email,
        },
      });
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'scoreboard',
          action: true,
        },
      });
    }
  };

  return (
    <>
      <div className={showModal ? 'ModalTricky-Background' : 'Hide-Modal'}>
        <div className="ModalTricky-Congrat-Container-DemoMulti">
          <img src={arcade} alt="Arcade" />
          <div className="ModalTricky-Form-DemoMulti">
            <div className="ModalTricky-congrat-Title-DemoMulti">
              <p>Scoreboard</p>
            </div>
            <div className="ModalTricky-congrat-p-DemoMulti">
              <p>Renseignez votre pseudo pour decouvrir votre score</p>
            </div>
            <form onSubmit={onSubmit} method="post">
              <div className="Congrat-Input-Large-DemoMulti">
                <input
                  type="text"
                  name="pseudo"
                  id="pseudo"
                  className="Congrat-Input-DemoMulti"
                  placeholder="Pseudo"
                  onChange={onChange}
                  autoComplete="off"
                  required
                />
              </div>
              <div className="Congrat-Input-Large-DemoMulti">
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="Congrat-Input-DemoMulti"
                  placeholder="Email"
                  onChange={onChange}
                  autoComplete="off"
                  required
                />
              </div>
              <div>
                <input
                  className="ButtonAction ActionScoreboard"
                  type="submit"
                  value="OK"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  GameUsers: state.GameUsers,
});

Congratulation.propTypes = {
  GameUsers: PropTypes.shape({
    count: PropTypes.number.isRequired,
    time: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(Congratulation);
