import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import './Tunnel.css';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import TunnelFooter from './TunnelFooter';
import TunnelForm from './TunnelForm';
import TunnelHeader from './TunnelHeader';
import TunnelAriane from './TunnelAriane';

const Tunnel = ({ initialChoice }) => {
  const history = useHistory();

  useEffect(() => {
    if (!initialChoice) {
      history.push('/');
    }
  }, []);

  return (
    <section className="Tunnel-Container">
      <TunnelHeader />
      <TunnelAriane />
      <TunnelForm />
      <TunnelFooter />
    </section>
  );
};

Tunnel.propTypes = {
  initialChoice: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  initialChoice: state.Tunnel.UserChoice.Univers,
});

export default connect(mapStateToProps)(Tunnel);
