// CombineReducers goes here
import { combineReducers } from 'redux';
import Tunnel from './Tunnel';
import Themes from './Themes';
import Company from './Company';
import Actions from './Actions';
import methodoStep from './methodoStep';
import fetchMessage from './fetchMessage';
import fetchObject from './fetchObjects';
import computerStatus from './computerStatus';
import havingKnife from './KnifeFound';
import drawerCounter from './DrawerCounter';
import boardCounter from './BoardCounter';
import phoneStatus from './phoneStatus';
import Modal from './Modal';
import Room from './Room';
import GameUsers from './GameUsers';
import Office from './Office';

export default combineReducers({
  Tunnel,
  Themes,
  Company,
  Objects: fetchObject,
  prevention_messages: fetchMessage,
  Actions,
  methodoStep,
  GameUsers,
  fetchMessage,
  fetchObject,
  computerStatus,
  havingKnife,
  drawerCounter,
  boardCounter,
  phoneStatus,
  Modal,
  Room,
  Office,
});
