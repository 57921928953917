import PropTypes, { object } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TunnelChoiceUnivers from '../TunnelChoice/TunnelChoiceUnivers';

const TunnelSecondQuestion = ({ Univers, initialChoice }) => {
  return (
    <>
      <h3>Quel sujet de prévention souhaitez-vous aborder ?</h3>
      <div className="Tunnel-Form-Choice">
        {Univers[initialChoice].map((element) => (
          <TunnelChoiceUnivers
            name={element.name}
            description={element.description}
            imageColor={element.imageColor}
            imageGrey={element.imageGrey}
            idReponse={element.idReponse}
            objectif={element.objectif}
            preRequisite={element.préRequis}
            dureeImmersion={element.duréeImmersion}
            dureeDebrief={element.duréeDebrief}
            index={element.id}
          />
        ))}
      </div>
    </>
  );
};

TunnelSecondQuestion.propTypes = {
  Univers: PropTypes.arrayOf(object).isRequired,
  initialChoice: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  Univers: state.Themes,
  initialChoice: state.Tunnel.UserChoice.Univers,
});

export default connect(mapStateToProps)(TunnelSecondQuestion);
