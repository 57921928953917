/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Cameras from './Cameras';
import './UserBoard.css';
import CameraBlack from '../../../img/volume-up-solid-white.svg';
import MicroBlack from '../../../img/microphone-solid_white.svg';
import FoxHead1 from '../../../styles/img/Tricky_fox_1.svg';
import FoxHead2 from '../../../styles/img/Tricky_fox_2.svg';
import FoxHead3 from '../../../styles/img/Tricky_fox_3.svg';
import FoxHead4 from '../../../styles/img/Tricky_fox_4.svg';
import coralie from '../../../styles/img/Game/players/coralie.png';
import jerome from '../../../styles/img/Game/players/jerome.png';
import daniela from '../../../styles/img/Game/players/daniela.png';
import framboise from '../../../styles/video/video3.webm';
import david from '../../../styles/video/video2.webm';
import thomasb from '../../../styles/video/video1.webm';

const UserBoard = ({ GameUsers }) => {
  const [foxHead, setFoxHead] = useState(FoxHead1);
  const [haveSafari, setHaveSafari] = useState(false);

  useEffect(() => {
    if (
      navigator.userAgentData &&
      navigator.userAgentData.brands
        .map((nav) => nav.brand.includes('Safari'))
        .includes(true)
    ) {
      setHaveSafari(true);
    }
  }, []);

  useEffect(() => {
    setInterval(() => {
      setTimeout(() => {
        setFoxHead(FoxHead2);
      }, 2000);
      setTimeout(() => {
        setFoxHead(FoxHead1);
      }, 2200);
      setTimeout(() => {
        setFoxHead(FoxHead3);
      }, 4500);
      setTimeout(() => {
        setFoxHead(FoxHead1);
      }, 4600);
      setTimeout(() => {
        setFoxHead(FoxHead4);
      }, 7000);
      setTimeout(() => {
        setFoxHead(FoxHead1);
      }, 7600);
    }, 8000);
  }, []);

  const participants = [
    {
      id: 1,
      video: framboise,
      image: coralie,
    },
    {
      id: 2,
      video: david,
      image: jerome,
    },
    {
      id: 3,
      video: thomasb,
      image: daniela,
    },
  ];

  return (
    <div className="UserBoard-container">
      <div className="YouAreHere">
        <h4>Vous êtes ici !</h4>
      </div>
      {GameUsers.bubbleDisplayed && (
        <div className={`bubble pos${GameUsers.bubble.position}`}>
          <p>{GameUsers.bubble.advice}</p>
        </div>
      )}
      <div className="UserBoard-Localparticipant">
        <div className="FoxHead-Congrat-DemoMulti">
          <img src={foxHead} alt="Tête du petit renard" draggable="false" />
        </div>
        <div className="UserBoard-RightPanel">
          <div id="video-onboard" role="presentation">
            <img
              src={CameraBlack}
              alt="Activer la caméra"
              title="Activer la caméra"
              aria-label="Mute video"
            />
          </div>
          <div
            id="microphone-onboard"
            className="microphone-onboard"
            type="button"
            role="presentation"
          >
            <img
              src={MicroBlack}
              title="Activer le micro"
              aria-label="Mute audio"
              alt="Activer le micro"
            />
          </div>
        </div>
      </div>
      <div className="Participants">
        {/* {participants.map((participant) => (
          <Cameras key={participant.id} video={participant.video} />
        ))} */}
        {!haveSafari ? (
          participants.map((participant) => (
            <Cameras key={participant.id} video={participant.video} />
          ))
        ) : (
          <>
            <div className="UserBoard-participant">
              <div className="UserBoard-RightPanel">
                <img src={coralie} alt="Coralie" />
              </div>
            </div>
            <div className="UserBoard-participant">
              <div className="UserBoard-RightPanel">
                <img src={jerome} alt="Jerome" />
              </div>
            </div>
            <div className="UserBoard-participant">
              <div className="UserBoard-RightPanel">
                <img src={daniela} alt="Daniela" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  GameUsers: state.GameUsers,
});

UserBoard.propTypes = {
  GameUsers: PropTypes.shape({
    bubbleDisplayed: PropTypes.bool.isRequired,
    bubble: PropTypes.shape({
      position: PropTypes.number.isRequired,
      advice: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(UserBoard);
