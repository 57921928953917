/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import confetti from 'canvas-confetti';
import '../../Game/Modals.css';
import './ModalEmotionCard.css';
import { connect } from 'react-redux';
import success from '../../../styles/sound/success-card.mp3';

const ModalFoundCard = ({
  typeEmotion,
  sound,
  allMessages,
  language,
  endTimer,
}) => {
  const [soundOn, setSoundOn] = useState(false);
  const [cardAway, setCardAway] = useState(false);
  const [petardSound, setPetardSound] = useState(false);
  const count = 200;
  const defaults = {
    origin: { y: 0.7 },
  };
  const emotion = Number(typeEmotion);

  useEffect(() => {
    function fire(particleRatio, opts) {
      confetti(
        Object.assign(defaults, opts, {
          particleCount: Math.floor(count * particleRatio),
        })
      );
    }
    setPetardSound(true);
    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 60,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
    setTimeout(() => {
      setPetardSound(false);
    }, 1000);
  }, []);

  setTimeout(() => {
    setSoundOn(true);
  }, 1000);

  setTimeout(() => {
    setCardAway(true);
  }, endTimer);

  const handleCard = (card) => {
    const TextSize =
      card.title.length > 12 ||
      (card.title_es && card.title_es.length > 12) ||
      (card.title_en && card.title_en.length > 12);
    return (
      <>
        <div className={`${cardAway ? 'cardAway' : 'cardSideFront'}`}>
          <p className={TextSize ? 'cardFoundTitleSmall' : 'cardFoundTitle'}>
            {language === 'FR'
              ? card.title
              : language === 'ES'
              ? card.title_es
              : card.title_en}
          </p>
          <p className="cardFoundText">
            {language === 'FR'
              ? card.message
              : language === 'ES'
              ? card.message_es
              : card.message_en}
          </p>
        </div>
        <div className="cardSideBack">
          <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 99.21 109.93"
          >
            <title>tricky_head</title>
            <path
              d="M952.84,533.4c4,.57,7.67,1.11,11.64,1.68a1,1,0,0,0,.83-.3c.32-.36.73-.83,1.13-1.32,3.69-4.49,8.41-7.62,13.47-10.26q12.72-6.63,25.48-13.11a15.28,15.28,0,0,1,2.08-.82.93.93,0,0,1,1.2.62,9.73,9.73,0,0,1-.67,7.47c-2.55,5.09-5.17,10.11-7.85,15.13-2.88,5.37-5.47,10.77-6.78,16.82-.58,2.67-2.49,5.05-3.78,7.56a26.35,26.35,0,0,0-2.14,4.53c-.82,2.83,0,4.1,2.91,4.7a26.33,26.33,0,0,0,3.46.27c1,.07,2,.11,3.22.18a.88.88,0,0,1,.6,1.48l-.84.88c-7.55,7.89-15.79,14.89-25.68,19.65-3.48,1.68-7.37,2.45-10.82,4.15-4.27,2.1-7.79,2.4-12.43,0-5.7-3-11.65-5.4-16.76-9.46a36.75,36.75,0,0,1-10.36-12.38c-.62-1.27-1.07-2.6-2.75-3-.49-.11-.94-.86-1.22-1.42-2-4-3.91-7.94-5.95-12.1a.93.93,0,0,1,.19-1.07l1-1a.9.9,0,0,0,.2-1c-.71-1.57-1.41-3.14-2.25-5a.93.93,0,0,1,.89-1.3c1,0,1.72.09,2.45.15a60,60,0,0,0,6.13.49c3,0,4.08-1,4.85-4a27,27,0,0,0,.8-4.18c.36-4.12.6-8.24.92-12.36.77-10.05,1.44-20.09,2.4-30.09a40.82,40.82,0,0,1,2.26-8.61c.25-.81,1.28-1.91,1.95-1.91a3.33,3.33,0,0,1,2.34,1.68,64.74,64.74,0,0,1,3,7.14q6.79,19,13.52,38c.2.52.42,1.05.64,1.57A.91.91,0,0,0,952.84,533.4Z"
              transform="translate(-909.91 -484.46)"
              style={{ fill: '#fff' }}
            />
          </svg>
        </div>
        <audio src={soundOn && sound} autoPlay>
          <track kind="captions" />
        </audio>
      </>
    );
  };
  return (
    <>
      <audio src={petardSound && success} autoPlay>
        <track kind="captions" />
      </audio>
      {allMessages
        .filter((card) => card.id === emotion)
        .map((card) => handleCard(card, 'face'))}
    </>
  );
};

const mapStateToProps = (state) => ({
  sessionName: state.GameUsers.sessionName,
  allMessages: state.prevention_messages.allMessages,
  language: state.GameUsers.language,
});

ModalFoundCard.propTypes = {
  allMessages: PropTypes.arrayOf({}).isRequired,
  language: PropTypes.string.isRequired,
  typeEmotion: PropTypes.string.isRequired,
  sound: PropTypes.func.isRequired,
  endTimer: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(ModalFoundCard);
