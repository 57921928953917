import React from 'react';
import { Link } from 'react-router-dom';
import './LPDigitalRoom.css';

const About = () => {
  return (
    <div className="DigitalRoomVideo-Grey">
      <div className="DigitalRoomVideo">
        <div className="DigitalRoomVideo-Title">
          <h2>A propos de Tricky</h2>
        </div>
        <div className="DigitalRoomVideo-Description">
          <p>
            Tricky est une entreprise experte dans la gamification en santé.
            Notre entreprise crée et exploite des Escapes Game autour de 6
            scénarios de prévention : stress au travail, santé mentale,
            mobilisation de personnes, obésité, addictions…
          </p>
        </div>
        <div className="DigitalRoomVideo-AllArguments">
          <div className="DigitalRoomVideo-Argument">
            <div className="DigitalRoomVideo-Spans">
              <span className="fas fa-key" title="Clé" />
            </div>
            <h3>Service clé en main</h3>
            <p>
              Nous installons nos Escape Room Santé chez vous et assurons les
              formations de A à Z
            </p>
          </div>
          <div className="DigitalRoomVideo-Argument">
            <div className="DigitalRoomVideo-Spans">
              <span className="fas fa-medal" title="Expertise" />
            </div>
            <h3>Expertise reconnue</h3>
            <p>
              Tricky est un acteur de la santé travail identifié et reconnu
              <br />
              Rapport El Khomri, 2020 (p.67)
            </p>
          </div>
          <div className="DigitalRoomVideo-Argument">
            <div className="DigitalRoomVideo-Spans">
              <span className="fas fa-thumbs-up" title="Des résultats" />
            </div>
            <h3>Résultats</h3>
            <p>
              Score de satisfaction de 8,3/10
              <br />
              Intention changement de comportement 9/10
              <br />
              Sentiment d’immersion 8/10
            </p>
          </div>
        </div>
        <div className="DigitalRoomVideo-Button">
          <Link to="/digital-room-game" target="_blank">
            <button type="button">
              Accéder à la démo{' '}
              <span
                className="fas fa-chevron-right"
                title="Accéder à la démo"
              />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default About;
