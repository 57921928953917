import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { flip, tada } from 'react-animations';
import '../Modals.css';

const FlipAnimation = keyframes`${flip}`;
const TadaAnimation = keyframes`${tada}`;
const FlipDiv = styled.img`
  animation: 3s ${FlipAnimation};
`;

const TadaImg = styled.div`
  animation: infinite 3s ${TadaAnimation};
`;

const ObjectItem = ({
  name,
  image,
  Objects,
  Knife,
  dispatch,
  ObjectStatus,
}) => {
  const [phoneNumber, setPhoneNumber] = useState(false);

  const handleClick = () => {
    if (name === 'Mug') {
      dispatch({
        type: 'SHOW_MODAL',
        payload: {
          whichRoom: 'Desktop',
          typeOfModal: 'ObjectModal',
          whichModal: 'ModalHasMug',
          show: true,
        },
      });
    }
    if (name === 'Lettre') {
      dispatch({
        type: 'SHOW_MODAL_LETTER',
        payload: true,
      });
    }
    if (name === 'Couteau à papier') {
      if (!ObjectStatus.PhoneNumber) {
        dispatch({
          type: 'SHOW_MODAL_KNIFE',
          payload: true,
        });
      }
    }
    if (name === 'Lettre Ouverte') {
      if (ObjectStatus.LetterOpen) {
        dispatch({
          type: 'SHOW_MODAL_OPEN_LETTER',
          payload: true,
        });
      }
    }

    return null;
  };

  const useKnife = () => {
    setPhoneNumber(!phoneNumber);
    dispatch({
      type: 'PHONE_NUMBER',
      payload: !phoneNumber,
    });
    dispatch({
      type: 'LETTER_OPEN',
      payload: true,
    });
    dispatch({
      type: 'REMOVE_OBJECT',
      payload: {
        nameObject: 'Lettre',
      },
    });
    dispatch({
      type: 'REMOVE_OBJECT',
      payload: {
        nameObject: 'Couteau à papier',
      },
    });
    dispatch({
      type: 'ADD_OBJECT',
      payload: {
        name: Objects.AllObjects.filter(
          (item) => item.name === 'Lettre Ouverte'
        ).map((item) => item.name)[0],
        image: Objects.AllObjects.filter(
          (item) => item.name === 'Lettre Ouverte'
        ).map((item) => item.image)[0],
      },
    });
    const timerId = setTimeout(
      () =>
        dispatch({
          type: 'SHOW_MODAL_ANIM_LETTER',
          payload: true,
        }),
      0
    );
    setTimeout(() => {
      clearInterval(timerId);
      dispatch({
        type: 'SHOW_MODAL_ANIM_LETTER',
        payload: false,
      });
    }, 1800);
    setTimeout(
      () =>
        dispatch({
          type: 'SHOW_MODAL_OPEN_LETTER',
          payload: true,
        }),
      1800
    );
  };

  const condition =
    Knife &&
    ObjectStatus.LetterClicked &&
    name === 'Couteau à papier' &&
    !ObjectStatus.PhoneNumber;

  return (
    <>
      <div className="ObjectList">
        <div className="Object-square">
          {image && (
            <FlipDiv
              src={image}
              alt="mug test"
              onClick={() => handleClick()}
              className="Object-Image"
            />
          )}
        </div>
        <div className="Object-name">
          <p>{name}</p>
          {condition && (
            <TadaImg>
              <button
                className="ButtonAction Action"
                type="button"
                onClick={useKnife}
              >
                Utiliser
              </button>
            </TadaImg>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  Objects: state.Objects,
  ObjectStatus: state.Office.ObjectStatus,
  Knife: state.havingKnife.hasKnife,
});

ObjectItem.propTypes = {
  dispatch: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  ObjectStatus: PropTypes.shape({
    PhoneNumber: PropTypes.bool.isRequired,
    LetterOpen: PropTypes.bool.isRequired,
    LetterTear: PropTypes.bool.isRequired,
    LetterClicked: PropTypes.bool.isRequired,
  }).isRequired,
  Objects: PropTypes.shape({
    AllObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
    object: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  Modal: PropTypes.shape({
    ModalMug: PropTypes.bool.isRequired,
  }).isRequired,
  Knife: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(ObjectItem);
