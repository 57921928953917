const initialState = {
  count1: 0,
  count2: 0,
  count3: 0,
  drawerSucceed: false,
  addedKnife: false,
};

const drawerCounter = (state = initialState, action) => {
  switch (action.type) {
    case 'INCREMENT_COUNTER1':
      return {
        ...state,
        count1: state.count1 + 1,
      };
    case 'INCREMENT_COUNTER2':
      return {
        ...state,
        count2: state.count2 + 1,
      };
    case 'INCREMENT_COUNTER3':
      return {
        ...state,
        count3: state.count3 + 1,
      };
    case 'RESET_COUNTER1':
      return {
        ...state,
        count1: 0,
      };
    case 'RESET_COUNTER2':
      return {
        ...state,
        count2: 0,
      };
    case 'RESET_COUNTER3':
      return {
        ...state,
        count3: 0,
      };
    case 'DRAWER_SUCCESS':
      return {
        ...state,
        drawerSucceed: action.payload,
      };
    case 'ADDING_KNIFE_DESKTOP':
      return {
        ...state,
        addedKnife: action.payload,
      };
    default:
      return state;
  }
};

export default drawerCounter;
