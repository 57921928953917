import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './Bureau.css';
import './Modals.css';
import imageMug from '../../styles/img/Game/BUREAU-3_mugZ-2_CROPPED.svg';
import pop from '../../styles/sound/pop.mp3';
import woosh from '../../styles/sound/woosh.mp3';
import { showMessage, addObject, counterClick } from '../../actions/index';

const Mug = ({ HaveMug, Objects, ModalMug, ModalHasMug, dispatch }) => {
  const [showAnimation, setShowAnimation] = useState(false);
  const [clickedMug, setClickedMug] = useState(false);

  const handleClick = () => {
    setClickedMug(!clickedMug);
    dispatch({
      type: 'SHOW_MODAL',
      payload: {
        whichRoom: 'Desktop',
        typeOfModal: 'ObjectModal',
        whichModal: 'ModalMug',
        show: false,
      },
    });
    const timerId = setTimeout(() => setShowAnimation(true), 0);
    setTimeout(() => {
      clearInterval(timerId);
      setShowAnimation(false);
    }, 500);
    dispatch({ type: 'MUG', payload: !clickedMug });
    dispatch(
      showMessage({
        title: 'Informations',
        message: "L'objet Mug a été ajouté à votre inventaire",
        show: true,
      })
    );
    dispatch(
      addObject({
        name: Objects.AllObjects.filter((item) => item.name === 'Mug').map(
          (item) => item.name
        )[0],
        image: Objects.AllObjects.filter((item) => item.name === 'Mug').map(
          (item) => item.image
        )[0],
      })
    );
    dispatch(counterClick());
  };

  const handleCloseMug = () => {
    dispatch({
      type: 'SHOW_MODAL',
      payload: {
        whichRoom: 'Desktop',
        typeOfModal: 'ObjectModal',
        whichModal: 'ModalHasMug',
        show: false,
      },
    });
  };

  return (
    <>
      {ModalMug && (
        <audio src={pop} autoPlay>
          <track kind="captions" />
        </audio>
      )}
      {HaveMug && (
        <audio src={woosh} autoPlay>
          <track kind="captions" />
        </audio>
      )}
      {!HaveMug && (
        <div
          className="mug"
          onClick={() =>
            dispatch({
              type: 'SHOW_MODAL',
              payload: {
                whichRoom: 'Desktop',
                typeOfModal: 'ObjectModal',
                whichModal: 'ModalMug',
                show: true,
              },
            })
          }
          onKeyDown={() =>
            dispatch({
              type: 'SHOW_MODAL',
              payload: {
                whichRoom: 'Desktop',
                typeOfModal: 'ObjectModal',
                whichModal: 'ModalMug',
                show: true,
              },
            })
          }
          tabIndex={0}
          role="button"
        >
          <div id="smoke" />
          <div id="smoke2" />
          <img src={imageMug} alt="Mug" draggable="false" />
        </div>
      )}
      <div
        className={
          ModalMug
            ? 'ModalTricky-Background'
            : 'ModalTricky-Background Hide-Modal'
        }
      >
        <div className="ModalTricky-Container">
          <div className="ItemToShow">
            <img src={imageMug} alt="Mug" id="mug" draggable="false" />
          </div>
          <div>
            <button
              className="ButtonAction Action"
              type="button"
              onClick={handleClick}
            >
              Ajouter à l&apos;inventaire
            </button>
          </div>
        </div>
      </div>
      <div
        className={
          showAnimation
            ? 'ModalTricky-Background2'
            : 'ModalTricky-Background Hide-Modal'
        }
      >
        <div className="ModalTricky-Animation">
          <div className="zoomleft">
            <img src={imageMug} alt="Mug" draggable="false" />
          </div>
        </div>
      </div>
      <div
        className={
          ModalHasMug
            ? 'ModalTricky-Background'
            : 'ModalTricky-Background Hide-Modal'
        }
      >
        <div className="ModalTricky-Container">
          <div className="ModalObjectTricky-p">
            <p>Ce mug pourrait bien me servir...</p>
          </div>
          <div className="ItemToShow">
            <img src={imageMug} alt="Mug" id="mug" draggable="false" />
          </div>
          <div>
            <button
              className="ButtonAction Action"
              type="button"
              onClick={handleCloseMug}
            >
              Bien compris !
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  Objects: state.Objects,
  HaveMug: state.Office.ObjectStatus.MugClicked,
  preventionMessages: state.prevention_messages.allMessages,
  ModalMug: state.Modal.Desktop.ObjectModal.ModalMug,
  ModalHasMug: state.Modal.Desktop.ObjectModal.ModalHasMug,
});

Mug.propTypes = {
  dispatch: PropTypes.func.isRequired,
  Objects: PropTypes.shape({
    MugClicked: PropTypes.bool.isRequired,
    AllObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
    object: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  HaveMug: PropTypes.bool.isRequired,
  ModalMug: PropTypes.bool.isRequired,
  ModalHasMug: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Mug);
