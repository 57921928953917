import imageTest from '../styles/img/Tunnel/antevasin-nguyen-ccaQyDwMCZ0-unsplash.jpg';

const listOfSteps = [
  {
    name: 'Univers',
    step: 1,
    description: 'Vivez une expérience intéractive unique',
    responses: [
      {
        name: 'hospital',
        title: 'Patient',
        description: 'Vivez une expérience intéractive unique',
        image: imageTest,
      },
      {
        name: 'entreprise',
        title: 'Salarié',
        description: 'Vivez une expérience intéractive unique',
        image: imageTest,
      },
      {
        name: 'university',
        title: 'Jeune',
        description: 'Vivez une expérience intéractive unique',
        image: imageTest,
      },
    ],
  },
  {
    name: 'Type',
    description: 'Choissisez votre type de structure ',
    step: 2,
    responses: [
      {
        name: 'tpe',
        title: 'TPE',
        description: 'Très Petite Entreprise',
        subDescription:
          'En se référant à la définition de l’Union Européenne, les principaux critères d’une TPE sont un effectif de <strong>moins de 10 personnes</strong> ainsi qu’un chiffre d’affaires en dessous de <strong>2 millions d’euros</strong>. Les critères énoncés par le décret n°2008-1354 du 18 décembre 2008 de la France ne diffèrent pas trop de ceux édictés par l’Union Européenne.',
        image: imageTest,
        type: 'entreprise',
      },
      {
        name: 'pme',
        title: 'PME',
        description: 'Petite et Moyenne Entreprise',
        subDescription:
          'Selon l’Union Européenne, une PME est un établissement constitué d’un effectif de salariés allant de <strong>50 à 250 personnes</strong>. Quant aux chiffres d’affaires, ils varient d’une somme qui ne va pas au-delà de <strong>50 millions d’euros</strong>.',
        image: imageTest,
        type: 'entreprise',
      },
      {
        name: 'eti',
        title: 'ETI',
        description: 'Entreprise de Taille Intermédiaire',
        subDescription:
          'Pour être classée dans la catégorie des ETI ou Entreprise de Taille Intermédiaire, l’effectif des salariés ne doit pas aller au-delà de <strong>5 000 personnes</strong>. Il faut par ailleurs que l’entreprise ait un chiffre d’affaires maximum de <strong>1,5 milliard d’euros</strong> ainsi qu’un bilan annuel ne dépassant pas les 2 milliards d’euros. En tout cas, une ETI n’appartient pas à la catégorie des Petites et Moyennes Entreprises.',
        image: imageTest,
        type: 'entreprise',
      },
      {
        name: 'GE',
        title: 'GE',
        description: 'Grande Entreprise',
        subDescription:
          'Une entreprise est dite GE quand elle dispose de plus de <strong>5000 salariés</strong>. La présence de cette catégorie permet aux PME d’avoir lieu d’être. En effet, une grande entreprise est souvent source du système de sous-traitance, alimentant souvent les PME.',
        image: imageTest,
        type: 'entreprise',
      },
      {
        name: 'liberal',
        title: 'Structure libéral',
        description: 'Centre Hospitalier ou Clinique',
        subDescription:
          "Les établissements publics de santé qui ne figurent ni sur la liste des centres hospitalier régionaux, ni sur les listes d'hôpitaux locaux sont des centres hospitaliers. Une clinique est un établissement ou une section d'établissement hospitalier public ou privé généralement spécialisé.",
        type: 'hospital',
        image: imageTest,
      },
      {
        name: 'esms',
        title: 'ESMS',
        description: 'Etablissement & Service Medico-Sociaux',
        type: 'hospital',
        image: imageTest,
      },
      {
        name: 'sanitaire',
        title: 'Structure sanitaire',
        description: 'MSP, CPTS...',
        subDescription:
          "Une Mais de Santé Pluridisciplinaire(MSP), ou PluriProfessionnelle (MSPP), est, en France, un établissement regroupant des professionnels de la santé exerçant plusieurs disciplines différentes (médecins, infirmiers, masseur-kinésithérapeutes, pédicures-podologues...). Les communautés des professionnelles territoriales de santé (CPTS) associent des professionnels de santé et des acteurs sociaux et médico-sociaux d'un même territoire, sur la base d'un projet de santé, pour une meilleur organisation des parcours des patients et un meilleur exercice des professionnels de santé de ville",
        type: 'hospital',
        image: imageTest,
      },
      {
        name: 'others',
        title: 'Autres',
        description: '(...)',
        type: 'hospital',
        image: imageTest,
      },
      {
        name: 'scolaire',
        title: 'Lycée et collège',
        description: 'Vivez une expérience intéractive unique',
        subDescription:
          "Dans le système éducatif en France, le <strong>collège</strong> est l’appellation courante du premier cycle des études du second degré. C’est un enseignement de quatre ans, qui fait suite à l’école élémentaire. <br /><br />Le <strong>lycée</strong> correspond au second cycle des études secondaires. Ce second cycle peut être général et technologique ou professionnel1. Il correspond principalement aux trois dernières années de l'enseignement secondaire (seconde, première et terminale).",
        image: imageTest,
        type: 'university',
      },
      {
        name: 'superieur',
        title: 'Université & Grandes écoles',
        description: 'Vivez une expérience intéractive unique',
        subDescription:
          "Le terme d'études supérieures ou <strong>d'enseignement supérieur</strong>, désigne généralement l'instruction dispensée par les universités, les collèges anglo-saxons, avec en France un système plus dual de grandes écoles, d’institut et d'autres institutions comme les grands établissements décernant des grades universitaires ou autres diplômes de l’enseignement supérieur.",
        image: imageTest,
        type: 'university',
      },
      {
        name: 'other',
        title: 'Autre',
        description: 'Vivez une expérience intéractive unique',
        subDescription: '',
        image: imageTest,
        type: 'university',
      },
    ],
  },
  {
    name: 'Thématiques',
    description: 'Quel sujet de prévention souhaitez-vous aborder ?',
    step: 3,
    responses: [
      {
        name: 'obesite',
        title: 'Obésité',
        description: 'Prévention des risques',
        subDescription:
          " L'obésité est une maladie complexe. Elle resulte de multiples intéractions entre notre environnement et notre ADM! Dans une immersion futuriste, les usagers devront résoudre six enigmes associées à des facteurs de risques liés à l'apparition de l'obésité. Ensuite ils seront conviés à échanger sur cette maladie afin de la définir et de partager leur quotidion et question sur cette thématique.",
        goals: [
          "Connaître la définition de l'obésité.",
          "Savoir repérer les facteurs de risques et son contexte d'apparition.",
          'Savoir orienter vers les personnes ressources.',
        ],
        immersion: '60min',
        prerequisite: 'aucun',
        session: '45min',
        image: imageTest,
      },
      {
        name: 'mental',
        title: 'Santé Mental',
        image: imageTest,
      },
    ],
  },
  {
    name: 'Déploiement',
    description: 'Quelle solution pour votre personnel de santé :',
    step: 4,
    responses: [
      {
        name: 'distance',
        title: 'A distance',
        image: imageTest,
        description: 'Vivez une expérience intéractive unique',
      },
      {
        name: 'us',
        title: 'Chez nous',
        image: imageTest,
        description: 'Vivez une expérience intéractive unique',
      },
      {
        name: 'your',
        title: 'Vos locaux',
        image: imageTest,
        description: 'Vivez une expérience intéractive unique',
      },
      {
        name: 'container',
        title: 'Container',
        image: imageTest,
        description: 'Vivez une expérience intéractive unique',
      },
    ],
  },
  {
    name: 'final',
    title: 'Résumé',
  },
];

export default listOfSteps;
