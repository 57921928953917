import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TimeFormat from 'hh-mm-ss';
import './Dashboard.css';
import '../Modals.css';
import { connect } from 'react-redux';

const TimeUp = ({ paused, dispatch, game }) => {
  const [showTime, setShowTime] = useState(false);

  let mainTime;
  const [seconds, setSeconds] = useState(0);
  const stopTimer = () => {
    clearInterval(mainTime);
  };

  const tick = () => {
    // eslint-disable-next-line no-shadow
    setSeconds((seconds) => {
      const updatedSeconds = seconds + 1;
      if (updatedSeconds < 1) {
        stopTimer();
      }
      return updatedSeconds;
    });
  };

  const startTime = () => {
    if (seconds >= 0) {
      mainTime = setInterval(tick, 1000);
    }
  };

  useEffect(() => {
    if (!paused) {
      startTime();
    }
    return () => {
      stopTimer();
    };
  }, [paused]);

  const display = TimeFormat.fromS(seconds, 'hh:mm:ss');
  const [h, m, s] = display.split(':');
  const tiret = ':';
  const time = h + tiret + m + tiret + s;

  useEffect(() => {
    if (game.gameOver) {
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'time',
          action: time,
        },
      });
    }
  }, [!game.gameOver && seconds, game.gameOver]);

  return (
    <>
      <p className={m > 55 ? 'TimerText10' : 'TimerText'}>
        {h} : {m} : {s}
      </p>
      <div className={showTime ? 'ModalTricky-Background' : 'Hide-Modal'}>
        <div className="ModalTricky-TimeEnd">
          <div className="ModalTricky-p">
            <p>Le temps est écoulé !</p>
          </div>
          <div>
            <button
              className="ButtonAction Action"
              type="button"
              onClick={() => setShowTime(false)}
            >
              Bien compris !
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  gameStarted: state.GameUsers.gameStarted,
  timerPause: state.GameUsers.timerPause,
  game: state.GameUsers,
});

TimeUp.propTypes = {
  paused: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  game: PropTypes.shape({
    gameStarted: PropTypes.bool.isRequired,
    gameOver: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(TimeUp);
