import React from 'react';
import PropTypes, { object } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import TunnelFirstQuestion from './TunnelQuestion/TunnelFirstQuestion';
import TunnelThirdQuestion from './TunnelQuestion/TunnelThirdQuestion';
import TunnelSecondQuestion from './TunnelQuestion/TunnelSecondQuestion';
import TunnelFourthQuestion from './TunnelQuestion/TunnelFourthQuestion';
import Animation from '../Animation';

const TunnelForm = ({ TunnelNavigation, BackHome, dispatch }) => {
  const history = useHistory();
  const LeaveTunnel = () => {
    dispatch({ type: 'RESET_NAVIGATION' });
    dispatch({ type: 'RESET_ACTIONS_GLOBAL' });
    dispatch({ type: 'RESET_COMPANY_GLOBAL' });
    dispatch({ type: 'RESET_UNIVERS_GLOBAL' });
    history.push('/');
  };

  return (
    <section className="Tunnel-Form-Container">
      <Animation
        className="Tunnel-Form"
        wrapperElement="div"
        direction="right"
        distance={1000}
      >
        {/* <div className="Tunnel-Form"> */}
        {TunnelNavigation[1].isActive && <TunnelFirstQuestion />}
        {TunnelNavigation[2].isActive && <TunnelSecondQuestion />}
        {TunnelNavigation[3].isActive && <TunnelThirdQuestion />}
        {TunnelNavigation[4].isActive && <TunnelFourthQuestion />}
        {/* </div> */}
      </Animation>
      {BackHome && (
        <div className="Tunnel-Leave">
          <div className="Tunnel-Leave-Container">
            <div>
              <h3>
                Vous êtes sur le point de quitter l&apos;espace pour
                personnaliser votre offre tricky, êtes vous sur ?
              </h3>
            </div>
            <div className="Tunnel-Leave-Button">
              <button
                className="Back-Button"
                type="button"
                onClick={() =>
                  dispatch({ type: 'BACK_HOME', payload: { home: false } })
                }
              >
                Annuler
              </button>
              <button
                className="Next-Button"
                type="button"
                onClick={LeaveTunnel}
              >
                Je suis sur
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

const mapStateToProps = (state) => ({
  CompanyChoice: state.Tunnel.TunnelCompany,
  TunnelNavigation: state.Tunnel.TunnelNavigation,
  BackHome: state.Tunnel.HomeClicked,
});

TunnelForm.propTypes = {
  TunnelNavigation: PropTypes.arrayOf(object).isRequired,
  BackHome: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(TunnelForm);
