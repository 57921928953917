const initialState = {
  Desktop: {
    ObjectModal: {
      ModalPowerchord: false,
      ModalMug: false,
      ModalHasMug: false,
      ModalLetter: false,
      ModalKnife: false,
      ModalOpenLetter: false,
      ModalLetterAnim: false,
    },
    WrongObjectsModal: {
      OfficeClock: false,
      OfficeHat: false,
      OfficeTrash: false,
      modalDesktoplamp: false,
      modalDesktopToplamp: false,
      modalDesktopDoor: false,
      modalDesktopCupboard: false,
      modalDesktopChair: false,
      modalDesktopLockers: false,
    },
    PreventionModal: {
      ModalAnxiete: false,
      ModalAgressivite: false,
      ModalFatalite: false,
      ModalPessimisme: false,
    },
    ComputerModal: {
      turnOffModal: false,
      passwordModal: false,
      passwordErrorModal: false,
      passwordGoodModal: false,
      emailModal: false,
      emailErrorModal: false,
      emailGoodModal: false,
      sleepModal: false,
      visioModal: false,
    },
    PhoneModal: {
      modalNoPower: false,
      modalPower: false,
      modalWrongNumber: false,
      modalGoodNumber: false,
    },
  },
  Bathroom: {
    Modal: false,
  },
  Kitchen: {
    Modal: false,
  },
  Bedroom: {
    Modal: false,
  },
};

const Modal = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_MODAL': {
      const RoomChoosen = action.payload.whichRoom;
      const CategoryModal = action.payload.typeOfModal;
      const ModalChoosen = action.payload.whichModal;
      return {
        ...state,
        [RoomChoosen]: {
          ...state[RoomChoosen],
          [CategoryModal]: {
            ...state[RoomChoosen][CategoryModal],
            [ModalChoosen]: action.payload.show,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default Modal;
