import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Congratulation.css';
import axios from 'axios';
import { connect } from 'react-redux';
import FoxHead1 from '../../../styles/img/Tricky_fox_1.svg';
import FoxHead2 from '../../../styles/img/Tricky_fox_2.svg';
import FoxHead3 from '../../../styles/img/Tricky_fox_3.svg';
import FoxHead4 from '../../../styles/img/Tricky_fox_4.svg';
import FoxFur from '../../../styles/img/Tricky_fox_body_5.svg';
import FoxBody from '../../../styles/img/Tricky_fox_full_3.svg';

const Congratulation = ({ GameUsers, dispatch }) => {
  const [showModal, setShowModal] = useState(true);
  const [foxHead, setFoxHead] = useState(FoxHead1);

  useEffect(() => {
    setInterval(() => {
      setTimeout(() => {
        setFoxHead(FoxHead2);
      }, 2000);
      setTimeout(() => {
        setFoxHead(FoxHead1);
      }, 2200);
      setTimeout(() => {
        setFoxHead(FoxHead3);
      }, 4500);
      setTimeout(() => {
        setFoxHead(FoxHead1);
      }, 4600);
      setTimeout(() => {
        setFoxHead(FoxHead4);
      }, 7000);
      setTimeout(() => {
        setFoxHead(FoxHead1);
      }, 7600);
    }, 8000);
  }, []);

  const [inputs, setInputs] = useState({
    count: GameUsers.count,
    pseudo: '',
    firstname: '',
    lastname: '',
    email: '',
    time: GameUsers.time,
  });

  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const url = `${process.env.REACT_APP_BASE_URL}/api/scores`;
    axios
      .post(url, inputs)
      .then(setShowModal(false))
      .then((res) =>
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/api/scores/groups/16/${res.data.id}`
          )
          .then((result) =>
            axios.post(
              `${process.env.REACT_APP_BASE_URL}/api/scores/prospectranking/`,
              result.data
            )
          )
      );
    // .then((res) => res.data);
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'user',
        action: inputs,
      },
    });
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'scoreboard',
        action: true,
      },
    });
  };

  return (
    <>
      <div className={showModal ? 'ModalTricky-Background' : 'Hide-Modal'}>
        <div className="ModalTricky-Container">
          <div className="ModalTricky-Bravo">
            <h2>Bravo !</h2>
          </div>
          <div className="ModalTricky-Congrat-Container">
            <div className="ModalTricky-congrat-p">
              <p>
                Vous êtes arrivé au bout de cette première salle de la Digital
                Room Tricky
              </p>
            </div>
            <div className="Congrat-Bottom">
              <div className="ModalTricky-Form">
                <div className="ModalTricky-congrat-p">
                  <p>Affichez votre score et recevez votre fiche émotion</p>
                </div>
                <form onSubmit={onSubmit} method="post">
                  <div className="Congrat-Input-Large">
                    <input
                      type="text"
                      name="pseudo"
                      id="pseudo"
                      placeholder="Pseudo"
                      onChange={onChange}
                      required
                    />
                  </div>
                  <div className="Congrat-Input-Medium">
                    <input
                      type="text"
                      name="firstname"
                      id="firstname"
                      placeholder="Prénom"
                      onChange={onChange}
                      required
                    />
                    <input
                      type="text"
                      name="lastname"
                      id="lastname"
                      placeholder="Nom"
                      onChange={onChange}
                      required
                    />
                  </div>
                  <div className="Congrat-Input-Large">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email"
                      onChange={onChange}
                      required
                    />
                  </div>
                  <div>
                    <input
                      className="ButtonAction Action"
                      type="submit"
                      value="Soumettre"
                    />
                  </div>
                </form>
              </div>
              <div className="ModalTricky-Fox">
                <div className="FoxBody-Congrat">
                  <img src={FoxBody} alt="Corps du petit Renard" />
                  <div className="FoxFur-Congrat">
                    <img
                      src={FoxFur}
                      alt="Fourure du petit renard"
                      draggable="false"
                    />
                  </div>
                  <div className="FoxHead-Congrat">
                    <img
                      src={foxHead}
                      alt="Tête du petit renard"
                      draggable="false"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  GameUsers: state.GameUsers,
});

Congratulation.propTypes = {
  GameUsers: PropTypes.shape({
    count: PropTypes.number.isRequired,
    time: PropTypes.string.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(Congratulation);
