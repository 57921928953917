const fetchMessage = (
  state = {
    allMessages: [],
    message: { title: '', message: '', show: false },
    PessimismeMessage: false,
    ModalBureau: {
      ModalAnxiete: false,
      ModalAgressivite: false,
      ModalFatalite: false,
      ModalPessimisme: false,
    },
  },
  action
) => {
  switch (action.type) {
    case 'MESSAGE_NOTIF':
      return {
        ...state,
        message: {
          ...state.message,
          show: action.payload,
        },
      };
    case 'FETCH_MESSAGE':
      return {
        ...state,
        allMessages: action.messages,
      };
    case 'SHOW_MESSAGE':
      return {
        ...state,
        message: {
          title: action.payload.title,
          message: action.payload.message,
          show: action.payload.show,
        },
      };
    case 'SHOW_MODAL_PREVENTION': {
      const typeOfRoom = action.payload.roomType;
      const typeOfModal = action.payload.modalType;
      return {
        ...state,
        [typeOfRoom]: {
          ...state[typeOfRoom],
          [typeOfModal]: action.payload.showModal,
        },
      };
    }
    case 'PESSIMISME_MESSAGE':
      return {
        ...state,
        PessimismeMessage: action.payload,
      };
    default:
      return state;
  }
};
export default fetchMessage;
