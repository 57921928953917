/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './GameBoard.css';

const GameBoardHeader = ({
  cards,
  cardsFound,
  primaryCardsFound,
  dispatch,
  finalEmotions,
}) => {
  const handleEmotionCardModal = () => {
    dispatch({
      type: 'SHOW_MODAL_PREVENTION_GLOBAL',
      payload: true,
    });
  };
  return (
    <div className="Gameboard-Header">
      <div
        className="GameBoardHeader_cards"
        aria-hidden="true"
        onClick={() => handleEmotionCardModal()}
        role="button"
        tabIndex="0"
      >
        {cards.map((card, index) => {
          const cardNumber = index + 1;
          const cardType = finalEmotions
            .map((cardFinal) => Number(cardFinal))
            .includes(card.id);
          return (
            <div
              className={
                cardType
                  ? `GameBoardHeader_card card_primary`
                  : `GameBoardHeader_card card_secondary_${cardNumber} ${
                      cardsFound.length < cardNumber && 'card_disabled'
                    }`
              }
            >
              {!cardType ? (
                <p>{cardNumber}</p>
              ) : (
                <div className="GameBoardHeader_card_found">
                  {[...new Set(primaryCardsFound)].length}/
                  {finalEmotions.length}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  cards: state.prevention_messages.allMessages,
  cardsFound: state.GameUsers.cardsFound,
  primaryCardsFound: state.GameUsers.primaryCardsFound,
  sessionName: state.GameUsers.sessionName,
  finalEmotions: state.GameUsers.finalEmotions,
});
GameBoardHeader.propTypes = {
  dispatch: PropTypes.func.isRequired,
  game: PropTypes.shape({
    count: PropTypes.number,
    currentPlayer: PropTypes.arrayOf(PropTypes.object),
    players: PropTypes.arrayOf(PropTypes.object),
    NotYourTurn: PropTypes.bool,
  }).isRequired,
  cards: PropTypes.arrayOf({}).isRequired,
  cardsFound: PropTypes.arrayOf().isRequired,
  primaryCardsFound: PropTypes.arrayOf().isRequired,
  finalEmotions: PropTypes.arrayOf().isRequired,
};
export default connect(mapStateToProps)(GameBoardHeader);
