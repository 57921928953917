import Screen from '../../styles/img/Game/BUREAU-2_ordiZ-6_CROPPED.svg';

const computerStatus = (
  state = {
    image: Screen,
    status: 'turn_off',
    password: '',
    emailWord: {
      word1: '',
      word2: '',
      word3: '',
      word4: '',
    },
    Modal: {
      turnOffModal: false,
      passwordModal: false,
      passwordErrorModal: false,
      passwordGoodModal: false,
      emailModal: false,
      emailErrorModal: false,
      emailGoodModal: false,
      sleepModal: false,
      visioModal: false,
    },
  },
  action
) => {
  switch (action.type) {
    // Computer Status change case
    case 'COMPUTER_STATUS':
      return {
        ...state,
        image: action.payload.image,
        status: action.payload.status,
      };
    // Input password & words
    case 'PASSWORD':
      return {
        ...state,
        password: action.payload,
      };
    case 'HANDLE_EMAIL_WORD': {
      const whichWord = action.payload.word;
      return {
        ...state,
        emailWord: {
          ...state.emailWord,
          [whichWord]: action.payload.input,
        },
      };
    }
    // Modal management case
    case 'MODAL_COMPUTER': {
      const whichModal = action.payload.modalType;
      return {
        ...state,
        Modal: {
          ...state.Modal,
          [whichModal]: action.payload.modal,
        },
      };
    }
    default:
      return state;
  }
};

export default computerStatus;
