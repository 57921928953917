import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Modals.css';
import { connect } from 'react-redux';
import ModalFoundCard from '../Reusable/Modals/ModalFoundCard';

const PreventionMessages = ({ typeEmotion, sound, endTimer }) => {
  const [soundOn, setSoundOn] = useState(false);

  setTimeout(() => {
    setSoundOn(true);
  }, 1000);

  return (
    <div className="ModalTricky-Background">
      <ModalFoundCard typeEmotion={typeEmotion} endTimer={endTimer} />
      <audio src={soundOn && sound} autoPlay>
        <track kind="captions" />
      </audio>
    </div>
  );
};

PreventionMessages.propTypes = {
  typeEmotion: PropTypes.string.isRequired,
  sound: PropTypes.string.isRequired,
  endTimer: PropTypes.number.isRequired,
};

export default connect()(PreventionMessages);
