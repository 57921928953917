const initialState = {
  ObjectStatus: {
    ComputerUnlocked: false,
    EmailFilled: false,
    FinalSound: false,
    MugClicked: false,
    KnifeClicked: false,
    PhoneNumber: false,
    LetterClicked: false,
    LetterOpen: false,
    hasElectricity: false,
    hatClicked: false,
  },
  Modal: {
    ModalPowerchord: false,
    ModalLetter: false,
    ModalKnife: false,
    ModalOpenLetter: false,
    ModalClock: false,
    ModalLetterAnim: false,
  },
  WrongObjectsModal: {
    OfficeClock: false,
    OfficeHat: false,
    OfficeTrash: false,
  },
};

const Office = (state = initialState, action) => {
  switch (action.type) {
    case 'OFFICE_OBJECT': {
      const whichObject = action.payload.typeOfObject;
      return {
        ...state,
        ObjectStatus: {
          ...state.ObjectStatus,
          [whichObject]: action.payload.object,
        },
      };
    }
    case 'PLUG-IN':
      return {
        ...state,
        ObjectStatus: {
          ...state.ObjectStatus,
          hasElectricity: action.payload,
        },
      };
    case 'MUG':
      return {
        ...state,
        ObjectStatus: {
          ...state.ObjectStatus,
          MugClicked: action.payload,
        },
      };
    case 'KNIFE':
      return {
        ...state,
        ObjectStatus: {
          ...state.ObjectStatus,
          KnifeClicked: action.payload,
        },
      };
    case 'PHONE_NUMBER':
      return {
        ...state,
        ObjectStatus: {
          ...state.ObjectStatus,
          PhoneNumber: action.payload,
        },
      };
    case 'LETTER':
      return {
        ...state,
        ObjectStatus: {
          ...state.ObjectStatus,
          LetterClicked: action.payload,
        },
      };
    case 'LETTER_OPEN':
      return {
        ...state,
        ObjectStatus: {
          ...state.ObjectStatus,
          LetterOpen: action.payload,
        },
      };
    case 'SHOW_MODAL_LETTER':
      return {
        ...state,
        Modal: {
          ...state.Modal,
          ModalLetter: action.payload,
        },
      };
    case 'SHOW_MODAL_KNIFE':
      return {
        ...state,
        Modal: {
          ...state.Modal,
          ModalKnife: action.payload,
        },
      };
    case 'SHOW_MODAL_POWERCHORD':
      return {
        ...state,
        Modal: {
          ...state.Modal,
          ModalPowerchord: action.payload,
        },
      };
    case 'SHOW_MODAL_OPEN_LETTER':
      return {
        ...state,
        Modal: {
          ...state.Modal,
          ModalOpenLetter: action.payload,
        },
      };
    case 'SHOW_MODAL_ANIM_LETTER':
      return {
        ...state,
        Modal: {
          ...state.Modal,
          ModalLetterAnim: action.payload,
        },
      };
    case 'SHOW_MODAL_OFFICECLOCK':
      return {
        ...state,
        WrongObjectsModal: {
          ...state.WrongObjectsModal,
          OfficeClock: action.payload,
        },
      };
    case 'SHOW_MODAL_OFFICEHAT':
      return {
        ...state,
        WrongObjectsModal: {
          ...state.WrongObjectsModal,
          OfficeHat: action.payload,
        },
      };
    case 'SHOW_MODAL_OFFICETRASH':
      return {
        ...state,
        WrongObjectsModal: {
          ...state.WrongObjectsModal,
          OfficeTrash: action.payload,
        },
      };
    default:
      return state;
  }
};

export default Office;
