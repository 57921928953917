import PropTypes, { object } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TunnelArianeItem from './TunnelArianeItem';
import './TunnelHeader.css';

const TunnelAriane = ({ TunnelNavigation, TunnelWidth }) => {
  return (
    <div className="Tunnel-Header-Container-Navbar">
      {TunnelNavigation.map((element, index) => (
        <TunnelArianeItem index={index} title={element.title} />
      ))}
      <div className="Tunnel-Header-Line-Grey" />
      <div
        className="Tunnel-Header-Line-Purple"
        style={{ width: `${TunnelWidth}%` }}
      />
    </div>
  );
};

TunnelAriane.propTypes = {
  TunnelNavigation: PropTypes.arrayOf(object).isRequired,
  TunnelWidth: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  TunnelNavigation: state.Tunnel.TunnelNavigation,
  TunnelWidth: state.Tunnel.Width,
});

export default connect(mapStateToProps)(TunnelAriane);
