import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useSound from 'use-sound';
import './Bureau.css';
import './Modals.css';
import classNames from 'classnames';
import styled, { keyframes } from 'styled-components';
import { zoomIn, shake, tada } from 'react-animations';
import ScreenEmail from '../../styles/img/Game/BUREAU-2_ordiZ-9_email.svg';
import ScreenSleep from '../../styles/img/Game/ordi-sleep.svg';
import ZoomVisio from '../../styles/img/Game/Fenetre_visio.svg';
import fataliteAudio from '../../styles/sound/board_fatalisme_fr.mp3';
import PreventionMessages from './PreventionMessages';
import { showMessage, counterClick } from '../../actions/index';
import keyboardTyping from '../../sound/keyboard-typing.mp3';
import success from '../../sound/Success.mp3';
import error from '../../sound/Error.mp3';

const computerPassword1 = 'amavon';
const computerPassword2 = 'AMAVON';
const goodWord1 = 'crise';
const goodWord2 = 'organisation';
const goodWord3 = 'vendredi';
const goodWord4 = 'visioconférence';
const goodWord5 = 'visioconference';
const goodWord6 = 'videoconference';
const goodWord7 = 'vidéoconférence';

const ZoomAnimation = keyframes`${zoomIn}`;
const HeadAnimation = keyframes`${shake}`;
const FlashAnimation = keyframes`${tada}`;
const ZoomDiv = styled.div`
  animation: 1s ${ZoomAnimation};
`;

const HeadDiv = styled.div`
  animation: 1s ${HeadAnimation};
`;

const TadaDiv = styled.div`
  animation: 1s ${FlashAnimation};
`;

const Computer = ({
  computerStatus,
  Messages,
  Modal,
  Password,
  EmailWord,
  ModalBureau,
  dispatch,
}) => {
  const emotionFatalismeId = '23';
  const FataliteTitle = Messages.filter(
    (element) => element.title === 'Fatalité'
  ).map((message) => message.title)[0];
  const FataliteMessage = Messages.filter(
    (element) => element.title === 'Fatalité'
  ).map((message) => message.message)[0];
  const [playSuccess] = useSound(success);
  const [playError] = useSound(error);
  const [playType] = useSound(keyboardTyping);
  const handleChange = (e) => {
    const { value } = e.target;
    dispatch({
      type: 'PASSWORD',
      payload: value,
    });
    playType();
  };

  const inputEmailChange = (e, whichWord) => {
    const { value } = e.target;
    if (whichWord === 'word1') {
      dispatch({
        type: 'HANDLE_EMAIL_WORD',
        payload: { word: whichWord, input: value },
      });
    }
    if (whichWord === 'word2') {
      dispatch({
        type: 'HANDLE_EMAIL_WORD',
        payload: { word: whichWord, input: value },
      });
    }
    if (whichWord === 'word3') {
      dispatch({
        type: 'HANDLE_EMAIL_WORD',
        payload: { word: whichWord, input: value },
      });
    }
    if (whichWord === 'word4') {
      dispatch({
        type: 'HANDLE_EMAIL_WORD',
        payload: { word: whichWord, input: value },
      });
    }
  };

  const handleModal = () => {
    if (computerStatus.status === 'turn_off') {
      dispatch({
        type: 'MODAL_COMPUTER',
        payload: {
          modalType: 'turnOffModal',
          modal: true,
        },
      });
    }
    if (computerStatus.status === 'turn_on') {
      dispatch({
        type: 'MODAL_COMPUTER',
        payload: {
          modalType: 'passwordModal',
          modal: true,
        },
      });
    }
    if (computerStatus.status === 'unlocked') {
      dispatch({
        type: 'MODAL_COMPUTER',
        payload: {
          modalType: 'emailModal',
          modal: true,
        },
      });
    }
    if (computerStatus.status === 'sleeping') {
      dispatch({
        type: 'MODAL_COMPUTER',
        payload: {
          modalType: 'sleepModal',
          modal: true,
        },
      });
    }
    if (computerStatus.status === 'visio') {
      dispatch({
        type: 'MODAL_COMPUTER',
        payload: {
          modalType: 'visioModal',
          modal: true,
        },
      });
    }
  };

  const wordSubmit = (e) => {
    e.preventDefault();
    if (
      EmailWord.word1 === goodWord1 &&
      EmailWord.word2 === goodWord2 &&
      EmailWord.word3 === goodWord3 &&
      (EmailWord.word4 === goodWord4 ||
        EmailWord.word4 === goodWord5 ||
        EmailWord.word4 === goodWord6 ||
        EmailWord.word4 === goodWord7)
    ) {
      dispatch({
        type: 'MODAL_COMPUTER',
        payload: {
          modalType: 'emailModal',
          modal: false,
        },
      });
      dispatch({
        type: 'MODAL_COMPUTER',
        payload: {
          modalType: 'emailGoodModal',
          modal: true,
        },
      });
      dispatch(counterClick());
      playSuccess();
    } else {
      dispatch({
        type: 'MODAL_COMPUTER',
        payload: {
          modalType: 'emailModal',
          modal: false,
        },
      });
      dispatch({
        type: 'MODAL_COMPUTER',
        payload: {
          modalType: 'emailErrorModal',
          modal: true,
        },
      });
      dispatch(counterClick());
      playError();
    }
  };

  const submitPassword = (e) => {
    e.preventDefault();
    if (Password === computerPassword1 || Password === computerPassword2) {
      dispatch({
        type: 'MODAL_COMPUTER',
        payload: {
          modalType: 'passwordModal',
          modal: false,
        },
      });
      dispatch({
        type: 'MODAL_COMPUTER',
        payload: {
          modalType: 'passwordGoodModal',
          modal: true,
        },
      });
      dispatch(counterClick());
      playSuccess();
    } else {
      dispatch(counterClick());
      dispatch({
        type: 'MODAL_COMPUTER',
        payload: {
          modalType: 'passwordModal',
          modal: false,
        },
      });
      dispatch({
        type: 'MODAL_COMPUTER',
        payload: {
          modalType: 'passwordErrorModal',
          modal: true,
        },
      });
      dispatch({
        type: 'PASSWORD',
        payload: '',
      });
      playError();
    }
  };

  const ComputerUnlock = () => {
    dispatch(
      showMessage({
        title: 'Informations',
        message: "L'ordinateur est maintenant déverrouillé",
        show: true,
      })
    );
    dispatch({
      type: 'COMPUTER_STATUS',
      payload: {
        image: ScreenEmail,
        status: 'unlocked',
      },
    });
    dispatch({
      type: 'MODAL_COMPUTER',
      payload: {
        modalType: 'passwordGoodModal',
        modal: false,
      },
    });
    dispatch({
      type: 'REMOVE_OBJECT',
      payload: { nameObject: 'Mug' },
    });
  };

  const ComputerSleep = () => {
    dispatch(
      showMessage({
        title: FataliteTitle,
        message: FataliteMessage,
      })
    );
    dispatch({
      type: 'COMPUTER_STATUS',
      payload: {
        image: ScreenSleep,
        status: 'sleeping',
      },
    });
    dispatch({
      type: 'MODAL_COMPUTER',
      payload: {
        modalType: 'emailGoodModal',
        modal: false,
      },
    });
    dispatch({
      type: 'SHOW_MODAL_PREVENTION',
      payload: {
        roomType: 'ModalBureau',
        modalType: 'ModalFatalite',
        showModal: true,
      },
    });
    dispatch({
      type: 'FOUND_EMOTION',
      payload: emotionFatalismeId,
    });
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'timerPause',
        action: true,
      },
    });
    setTimeout(() => {
      dispatch({
        type: 'SHOW_MODAL_PREVENTION',
        payload: {
          roomType: 'ModalBureau',
          modalType: 'ModalFatalite',
          showModal: false,
        },
      });
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'timerPause',
          action: false,
        },
      });
    }, 30000);
  };

  return (
    <>
      <div
        className={classNames('screen', {
          screenPassword: computerStatus.status === 'turn_on',
          screenEmail: computerStatus.status === 'unlocked',
          screenSleep: computerStatus.status === 'sleeping',
        })}
      >
        <div
          onClick={handleModal}
          onKeyDown={handleModal}
          tabIndex={0}
          role="button"
        >
          <img src={computerStatus.image} alt="screen" draggable="false" />
        </div>
      </div>
      <div
        className={
          Modal.turnOffModal
            ? 'ModalTricky-Background'
            : 'ModalTricky-Background Hide-Modal'
        }
      >
        <ZoomDiv className="ModalTricky-Container">
          <div className="ModalTricky-p2">
            <p>L&apos;ordinateur est éteint...</p>
            <p>
              Il semblerait qu&apos;il y ait un problème d&apos;accès à
              l&apos;élécricité
            </p>
            <div className="ModalTricky-Computer" />
          </div>
          <div>
            <button
              className="ButtonAction Action"
              type="button"
              onClick={() =>
                dispatch({
                  type: 'MODAL_COMPUTER',
                  payload: {
                    modalType: 'turnOffModal',
                    modal: false,
                  },
                })
              }
            >
              Bien compris !
            </button>
          </div>
        </ZoomDiv>
      </div>
      <div
        className={
          Modal.passwordModal
            ? 'ModalTricky-Background'
            : 'ModalTricky-Background Hide-Modal'
        }
      >
        <ZoomDiv className="ModalTricky-Container">
          <div className="ModalTricky-p2">
            <div className="ModalTricky-Computer-Password">
              <form autoComplete="off" onSubmit={submitPassword}>
                <label htmlFor="password" id="password">
                  {' '}
                  Mot de passe
                </label>
                <input
                  type="text"
                  name="password"
                  value={Password}
                  onChange={handleChange}
                />
                <br />
                <br />
                <div className="Buttons">
                  <button type="submit" className="ButtonAction Action">
                    Déverrouiller
                  </button>
                  <button
                    className="ButtonAction Cancel"
                    type="button"
                    onClick={() =>
                      dispatch({
                        type: 'MODAL_COMPUTER',
                        payload: {
                          modalType: 'passwordModal',
                          modal: false,
                        },
                      })
                    }
                  >
                    Annuler
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ZoomDiv>
      </div>
      <div
        className={
          Modal.passwordErrorModal
            ? 'ModalTricky-Background'
            : 'ModalTricky-Background Hide-Modal'
        }
      >
        <HeadDiv className="ModalTricky-Container">
          <div className="ModalTricky-p2">
            <div className="ModalTricky-Computer-Password-Error">
              <p className="Error">Le mot de passe est incorrect</p>
              <br />
              <br />
              <button
                className="ButtonAction Cancel Action"
                type="button"
                onClick={() =>
                  dispatch({
                    type: 'MODAL_COMPUTER',
                    payload: {
                      modalType: 'passwordErrorModal',
                      modal: false,
                    },
                  })
                }
              >
                Retour
              </button>
            </div>
          </div>
        </HeadDiv>
      </div>
      <div
        className={
          Modal.passwordGoodModal
            ? 'ModalTricky-Background'
            : 'ModalTricky-Background Hide-Modal'
        }
      >
        <TadaDiv className="ModalTricky-Container">
          <div className="ModalTricky-p2">
            <div className="ModalTricky-Computer-Password-Success">
              <p className="Success">
                Bravo ! L&apos;ordinateur est déverrouillé
              </p>
              <br />
              <br />
              <button
                className="ButtonAction Action"
                type="button"
                onClick={ComputerUnlock}
              >
                Bien compris !
              </button>
            </div>
          </div>
        </TadaDiv>
      </div>
      <div
        className={
          Modal.emailModal
            ? 'ModalTricky-Background'
            : 'ModalTricky-Background Hide-Modal'
        }
      >
        <ZoomDiv className="ModalTricky-Container">
          <div className="ModalTricky-p2">
            <div className="ModalTricky-Computer-Email">
              <div className="ModalTricky-Computer-Email-p">
                <p>
                  Vous avez reçu un e-mail de manageur 033 :
                  <br />
                  <br />
                  En raison de la{' '}
                  <input
                    name="word1"
                    autoComplete="off"
                    className="word1"
                    type="text"
                    maxLength="5"
                    value={EmailWord.word1}
                    onChange={(e) => inputEmailChange(e, 'word1')}
                  />
                  sanitaire que nous vivons, <br />
                  l’entreprise AMAVON vous informe des nouvelles <br />
                  modalités d’{' '}
                  <input
                    name="word2"
                    autoComplete="off"
                    className="word2"
                    type="text"
                    maxLength="12"
                    value={EmailWord.word2}
                    onChange={(e) => inputEmailChange(e, 'word2')}
                  />{' '}
                  du travail.
                  <br />
                  Du lundi au{' '}
                  <input
                    name="word3"
                    autoComplete="off"
                    className="word3"
                    type="text"
                    maxLength="8"
                    value={EmailWord.word3}
                    onChange={(e) => inputEmailChange(e, 'word3')}
                  />{' '}
                  à 9h, la journée débute avec une
                  <br />
                  <input
                    name="word4"
                    autoComplete="off"
                    className="word4"
                    type="text"
                    maxLength="15"
                    value={EmailWord.word4}
                    onChange={(e) => inputEmailChange(e, 'word4')}
                  />{' '}
                  , et un listing-récap est à envoyer <br />
                  tous les jours à 17h à votre manageur.
                  <br />
                  <br />
                  Prenez soin de vous.
                </p>
                <div className="Buttons">
                  <button
                    type="submit"
                    className="ButtonAction Action"
                    onClick={wordSubmit}
                  >
                    Valider
                  </button>
                  <button
                    className="ButtonAction Cancel CancelButton"
                    type="button"
                    onClick={() =>
                      dispatch({
                        type: 'MODAL_COMPUTER',
                        payload: {
                          modalType: 'emailModal',
                          modal: false,
                        },
                      })
                    }
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ZoomDiv>
      </div>
      <div
        className={
          Modal.emailErrorModal
            ? 'ModalTricky-Background'
            : 'ModalTricky-Background Hide-Modal'
        }
      >
        <HeadDiv className="ModalTricky-Container">
          <div className="ModalTricky-p2">
            <div className="ModalTricky-Computer-Password-Error">
              <p className="Error">
                Un ou plusieurs mots ne sont pas bons, <br /> veuillez réessayer
              </p>
              <br />
              <br />
              <button
                className="ButtonAction Cancel Action"
                type="button"
                onClick={() =>
                  dispatch({
                    type: 'MODAL_COMPUTER',
                    payload: {
                      modalType: 'emailErrorModal',
                      modal: false,
                    },
                  })
                }
              >
                Retour
              </button>
            </div>
          </div>
        </HeadDiv>
      </div>
      <div
        className={
          Modal.emailGoodModal
            ? 'ModalTricky-Background'
            : 'ModalTricky-Background Hide-Modal'
        }
      >
        <TadaDiv className="ModalTricky-Container">
          <div className="ModalTricky-p2">
            <div className="ModalTricky-Computer-Password-Success">
              <p className="Success">
                Bravo ! Vous avez rempli correctement l&apos;email !
              </p>
              <br />
              <br />
              <button
                className="ButtonAction Action"
                type="button"
                onClick={ComputerSleep}
              >
                Bien compris !
              </button>
            </div>
          </div>
        </TadaDiv>
      </div>
      <div
        className={
          Modal.sleepModal
            ? 'ModalTricky-Background'
            : 'ModalTricky-Background Hide-Modal'
        }
      >
        <ZoomDiv className="ModalTricky-Container">
          <div className="ModalTricky-p2">
            <div className="ModalTricky-Computer-Password">
              <p>L&apos;ordinateur est en veille...</p>
              <br />
              <br />
              <button
                className="ButtonAction Action"
                type="button"
                onClick={() =>
                  dispatch({
                    type: 'MODAL_COMPUTER',
                    payload: {
                      modalType: 'sleepModal',
                      modal: false,
                    },
                  })
                }
              >
                Bien compris !
              </button>
            </div>
          </div>
        </ZoomDiv>
      </div>
      <div
        className={
          Modal.visioModal
            ? 'ModalTricky-Background'
            : 'ModalTricky-Background Hide-Modal'
        }
      >
        <ZoomDiv className="ModalTricky-Container">
          <div className="ModalTricky-p2">
            <div className="ModalTricky-Computer-Email">
              <p>Vous êtes en visioconférence</p>
              <br />
              <img
                src={ZoomVisio}
                alt="visio computer"
                className="Img-vision"
                draggable="false"
              />
              <button
                className="ButtonAction Action"
                type="button"
                onClick={() =>
                  dispatch({
                    type: 'MODAL_COMPUTER',
                    payload: {
                      modalType: 'visioModal',
                      modal: false,
                    },
                  })
                }
              >
                Bien compris !
              </button>
            </div>
          </div>
        </ZoomDiv>
      </div>
      {ModalBureau.ModalFatalite && (
        <PreventionMessages
          typeEmotion={emotionFatalismeId}
          endTimer={29000}
          sound={fataliteAudio}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  Objects: state.Objects,
  computerStatus: state.computerStatus,
  Messages: state.prevention_messages.allMessages,
  Modal: state.computerStatus.Modal,
  Password: state.computerStatus.password,
  EmailWord: state.computerStatus.emailWord,
  ModalBureau: state.prevention_messages.ModalBureau,
});

Computer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  computerStatus: PropTypes.shape({
    image: PropTypes.string,
    status: PropTypes.string,
    Modal: PropTypes.shape({
      turnOffModal: PropTypes.bool,
      passwordModal: PropTypes.bool,
      passwordErrorModal: PropTypes.bool,
      passwordGoodModal: PropTypes.bool,
      emailModal: PropTypes.bool,
      emailErrorModal: PropTypes.bool,
      emailGoodModal: PropTypes.bool,
      sleepModal: PropTypes.bool,
      visioModal: PropTypes.bool,
    }),
  }).isRequired,
  Modal: PropTypes.shape({
    turnOffModal: PropTypes.bool,
    passwordModal: PropTypes.bool,
    passwordErrorModal: PropTypes.bool,
    passwordGoodModal: PropTypes.bool,
    emailModal: PropTypes.bool,
    emailErrorModal: PropTypes.bool,
    emailGoodModal: PropTypes.bool,
    sleepModal: PropTypes.bool,
    visioModal: PropTypes.bool,
  }).isRequired,
  Password: PropTypes.string.isRequired,
  EmailWord: PropTypes.shape({
    word1: PropTypes.string,
    word2: PropTypes.string,
    word3: PropTypes.string,
    word4: PropTypes.string,
  }).isRequired,
  ModalBureau: PropTypes.shape({
    ModalAnxiete: PropTypes.bool,
    ModalAgressivite: PropTypes.bool,
    ModalFatalite: PropTypes.bool,
    ModalPessimisme: PropTypes.bool,
  }).isRequired,
  Messages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(mapStateToProps)(Computer);
