import React from 'react';
import PropTypes from 'prop-types';
import './Dashboard.css';
import '../Modals.css';
import { connect } from 'react-redux';
import TimeUp from './TimeUp';

const Timer = ({ gameStarted, timerPause }) => {
  return (
    <>
      <div className="Time-container">
        <h2>Temps</h2>
        {gameStarted ? <TimeUp paused={timerPause} /> : <div>00:00:00</div>}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  gameStarted: state.GameUsers.gameStarted,
  timerPause: state.GameUsers.timerPause,
});

Timer.propTypes = {
  gameStarted: PropTypes.bool.isRequired,
  timerPause: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Timer);
