import React from 'react';
import PropTypes from 'prop-types';
import './Scoreboard.css';

const ScoreboardItem = (props) => {
  const { rank, pseudo, count, time, style } = props;

  return (
    <>
      <div
        className={
          style
            ? 'ModalTricky-Scoreboard-User'
            : 'ModalTricky-Scoreboard-players'
        }
      >
        <div
          className={`col-10 ${style ? 'scoreboard-user' : 'scoreboard-rank'}`}
        >
          {rank}
        </div>
        <div
          className={`col-50 ${
            style ? 'scoreboard-user' : 'scoreboard-pseudo'
          }`}
        >
          {pseudo}{' '}
        </div>
        <div className="col-20">{count}</div>
        <div className="col-20">{time}</div>
      </div>
    </>
  );
};

ScoreboardItem.propTypes = {
  pseudo: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  time: PropTypes.string.isRequired,
  rank: PropTypes.number.isRequired,
  style: PropTypes.string.isRequired,
};

export default ScoreboardItem;
