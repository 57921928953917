import React from 'react';
import PropTypes from 'prop-types';
import './Congratulation.css';
import './Scoreboard.css';
import { connect } from 'react-redux';
import MyScoreboard from './MyScoreboard';
import arcade from '../../../styles/img/Game/Tricky.global-scoreboard.svg';

const Scoreboard = ({ GameUsers }) => {
  return (
    <div
      className={GameUsers.scoreboard ? 'ModalTricky-Background' : 'Hide-Modal'}
    >
      <div className="ModalTricky-Congrat-Container-DemoMulti">
        <img src={arcade} alt="Arcade" />
        <div className="ModalTricky-Scoreboard-DemoMulti">
          <div className="ModalTricky-Scoreboard-Title-DemoMulti">
            <p>Scoreboard</p>
          </div>
          <div className="ModalTricky-Scoreboard-info-DemoMulti">
            <div className="col-10">Rang</div>
            <div className="col-50">Joueurs</div>
            <>
              <div className="col-20">Coups</div>
              <div className="col-20">Temps</div>
            </>
          </div>
          <div className="ModalTricky-Scoreboard-Scores-DemoMulti">
            <MyScoreboard />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  GameUsers: state.GameUsers,
});

Scoreboard.propTypes = {
  GameUsers: PropTypes.shape({
    scoreboard: PropTypes.bool.isRequired,
    scoreboard10: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(Scoreboard);
