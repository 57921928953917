/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { createMuiTheme } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

createMuiTheme({
  palette: {
    primary: {
      main: '#7103ec',
    },
  },
});

const TunnelContact = ({ UserContact, UserChoice, InfoSent, dispatch }) => {
  const history = useHistory();
  const [checkbox, setCheckbox] = useState(false);
  const [inputError, setInputError] = useState(null);
  const [userInput, setUserInput] = useState({
    nom: '',
    email: '',
    entreprise: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInput({ ...userInput, [name]: value });
    dispatch({
      type: 'USER_CONTACT',
      payload: { ...userInput, [name]: value },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      userInput.name === '' ||
      userInput.email === '' ||
      userInput.entreprise === ''
    ) {
      setInputError('Veuillez remplir au moins un des 3 champs');
    } else if (!checkbox) {
      setInputError("Veuillez accepter les conditions d'utilisation");
    } else {
      dispatch({
        type: 'INFO_SENT',
        payload: {
          send: true,
        },
      });
      window.localStorage.setItem('User Choices', JSON.stringify(UserChoice));
      window.localStorage.setItem('User Contact', JSON.stringify(UserContact));
    }
  };

  const Generate = () => {
    dispatch({
      type: 'INFO_SENT',
      payload: {
        send: false,
      },
    });
    window.localStorage.removeItem('User Choices');
    window.localStorage.removeItem('User Contact');
    dispatch({ type: 'RESET_NAVIGATION' });
    dispatch({ type: 'RESET_ACTIONS_GLOBAL' });
    dispatch({ type: 'RESET_COMPANY_GLOBAL' });
    dispatch({ type: 'RESET_UNIVERS_GLOBAL' });
    history.push('/');
  };

  return (
    <>
      <div className="Tunnel-Contact-Global">
        <div className="Tunnel-Contact-Infos-Text">
          <h3>Données de contact</h3>
          <p>Dites nous comment nous pouvons vous contacter</p>
        </div>
        <div className="Tunnel-Contact-Container">
          {InfoSent ? (
            <div className="Tunnel-Contact-InfoSent">
              <h1>Merci!</h1>
              <p>Cliquez sur le bouton ci-dessous pour générer un devis </p>
              <button
                onClick={Generate}
                type="button"
                className="Generate-Button"
              >
                Générer
              </button>
            </div>
          ) : (
            <>
              <form onSubmit={handleSubmit} className="Tunnel-Contact-Form">
                <h3>Vos informations</h3>
                <div className="Tunnel-Contact-Input">
                  <label htmlFor="name">Nom :</label>
                  <input
                    onChange={handleChange}
                    value={userInput.nom}
                    type="text"
                    name="nom"
                  />
                </div>
                <div className="Tunnel-Contact-Input">
                  <label htmlFor="email">Email :</label>
                  <input
                    onChange={handleChange}
                    value={userInput.email}
                    type="mail"
                    name="email"
                    id="email"
                  />
                </div>
                <div className="Tunnel-Contact-Input">
                  <label htmlFor="entreprise">Entreprise :</label>
                  <input
                    onChange={handleChange}
                    value={userInput.entreprise}
                    type="text"
                    name="entreprise"
                  />
                </div>
                {inputError && (
                  <span className="Tunnel-Contact-Error">{inputError}</span>
                )}
                <div>
                  <button type="submit" className="Send-Button">
                    Envoyer
                  </button>
                </div>
              </form>
              <div className="Tunnel-Contact-Credential">
                <Checkbox
                  style={{ width: 20, height: 20 }}
                  checked={checkbox}
                  color="primary"
                  size="small"
                  onClick={() => setCheckbox(!checkbox)}
                />
                <span>
                  En cochant la case, vous acceptez les conditions
                  d&apos;utilisation et de recevoir des e-mails de la part de
                  l&apos;équipe tricky
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

TunnelContact.propTypes = {
  InfoSent: PropTypes.bool.isRequired,
  UserChoice: PropTypes.shape({
    Univers: PropTypes.string,
    Type: PropTypes.string,
    Thématiques: PropTypes.string,
    Déploiement: PropTypes.string,
  }).isRequired,
  UserContact: PropTypes.shape({
    Nom: PropTypes.string,
    Email: PropTypes.string,
    Entreprise: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sChoice: state.Actions.Actions,
  UserContact: state.Tunnel.UserContact,
  UserChoice: state.Tunnel.UserChoice,
  InfoSent: state.Tunnel.InfoSent,
});

export default connect(mapStateToProps)(TunnelContact);
