const initialState = {
  Modal: {
    ModalIntro: false,
    ModalTutorial: true,
  },
  AllObjects: [],
  object: [],
};

const fetchObject = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_OBJECT':
      return {
        ...state,
        AllObjects: action.objects,
      };
    case 'REMOVE_OBJECT':
      return {
        ...state,
        object: state.object.filter(
          (item) => item.name !== action.payload.nameObject
        ),
      };
    case 'REMOVE_LETTER':
      return {
        ...state,
        object: state.object.map((item) => {
          if (item.name === 'Lettre') {
            return {
              ...item,
              name: action.payload.name,
              image: action.payload.image,
            };
          }
          return item;
        }),
      };
    case 'UPDATE_PAPER':
      return {
        ...state,
        object: state.object.map((item) => {
          if (item.name === 'papier bas') {
            return {
              ...item,
              name: action.payload.name,
              image: action.payload.image,
            };
          }
          return item;
        }),
      };
    case 'ADD_OBJECT':
      return {
        ...state,
        object: [...state.object, action.payload],
      };
    case 'RESET_OBJECT_BUREAU':
      return {
        ...state,
        object: [],
      };
    case 'CLOCK_CLICKED':
      return {
        ...state,
        ClockClicked: action.payload,
      };
    case 'SHOW_MODAL_CLOCK':
      return {
        ...state,
        Modal: {
          ...state.Modal,
          ModalClock: action.payload,
        },
      };
    case 'SHOW_MODAL_INTRO':
      return {
        ...state,
        Modal: {
          ...state.Modal,
          ModalIntro: action.payload,
        },
      };
    case 'SHOW_MODAL_TUTO':
      return {
        ...state,
        Modal: {
          ...state.Modal,
          ModalTutorial: action.payload,
        },
      };
    default:
      return state;
  }
};

export default fetchObject;
