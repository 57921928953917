import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ObjectItem from './ObjectItem';

const ObjectList = ({ Objects }) => {
  return (
    <section className="Object-Item-Container">
      {Objects.map((item) => (
        <ObjectItem name={item.name} image={item.image} />
      ))}
    </section>
  );
};

const mapStateToProps = (state) => ({
  Objects: state.Objects.object,
});

ObjectList.propTypes = {
  Objects: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(mapStateToProps, null)(ObjectList);
