import React, { useState } from 'react';

import Input from '../Reusable/Input';

import '../../styles/css/components/ConvertionTunnel/FinalStep.css';

const FinalStep = () => {
  const userAnswers = JSON.parse(localStorage.getItem('responses'));
  const [inputs, setInputs] = useState({
    nom: '',
    email: '',
    entreprise: '',
  });

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  return (
    <div className="FinalStep">
      <div className="FinalStep_left">
        <p>Voici votre solution personnalisée</p>
        <div className="FinalStep_container">
          {userAnswers.map((answer, index) => (
            <div className="FinalStep_resume">
              <div className="FinalStep_resume_number">{index + 1}</div>
              <div className="FinalStep_resume_title">{answer.title}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="FinalStep_right">
        <p>En savoir plus sur votre solution</p>
        <div className="FinalStep_container">
          <Input
            name="firstname"
            type="text"
            value={inputs.firstname}
            label="Prénom"
            htmlFor="firstname"
            onChange={handleChange}
          />
          <Input
            name="lastname"
            type="text"
            value={inputs.lastname}
            label="Nom"
            htmlFor="lastname"
            onChange={handleChange}
          />
          <Input
            name="email"
            type="email"
            value={inputs.email}
            label="Email"
            htmlFor="email"
            onChange={handleChange}
          />
          <Input
            name="entreprise"
            type="text"
            value={inputs.entreprise}
            label="Entreprise"
            htmlFor="entreprise"
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default FinalStep;
