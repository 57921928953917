import React, { useState } from 'react';
import PropTypes, { object } from 'prop-types';
import { connect } from 'react-redux';
import Animation from '../../Animation';

const TunnelChoiceUnivers = ({
  name,
  description,
  imageColor,
  imageGrey,
  objectif,
  preRequisite,
  dureeImmersion,
  dureeDebrief,
  index,
  Univers,
  dispatch,
  initialChoice,
}) => {
  const [reduxChoice, setReduxChoice] = useState(true);
  const handleClick = (choiceId) => {
    const choosenCompany = Univers[initialChoice].filter(
      (element) => element.choosen
    ).length;
    if (choosenCompany > 0) {
      dispatch({
        type: 'RESET_UNIVERS_CLICKED',
        payload: { typeOfTheme: initialChoice },
      });
    }
    setReduxChoice(!reduxChoice);
    dispatch({
      type: 'UNIVERS_CLICKED',
      payload: {
        idChoice: choiceId,
        choosen: reduxChoice,
        typeOfTheme: initialChoice,
      },
    });
  };

  const handleCloseModal = () => {
    const choosenModal = Univers[initialChoice].filter(
      (element) => element.choosen
    ).length;
    if (choosenModal > 0) {
      dispatch({
        type: 'RESET_UNIVERS_CLICKED',
        payload: { typeOfTheme: initialChoice },
      });
    }
  };

  const isChoosen = Univers[initialChoice].filter(
    (element) => element.id === index
  )[0].choosen;

  return (
    <>
      <div
        onClick={() => handleClick(index)}
        onKeyDown={() => handleClick(index)}
        role="button"
        tabIndex={0}
        className={
          isChoosen
            ? `Tunnel-Choice-Container-${index}-choosen`
            : `Tunnel-Choice-Container-${index}`
        }
      >
        <div className="Tunnel-Choice-Univers-Img">
          <img src={isChoosen ? imageColor : imageGrey} alt={name} />
        </div>
        <p className="Tunnel-Choice-Univers-Title"> {name} </p>
        {isChoosen ? (
          <div className="Tunnel-Choice-Check-Good">
            <span className="fas fa-check-circle good" />
          </div>
        ) : (
          <div className="Tunnel-Choice-Check" />
        )}
      </div>
      {isChoosen && (
        <Animation
          className="Tunnel-Choice-Infos"
          wrapperElement="div"
          direction="up"
          distance={400}
        >
          <div className="Tunnel-Choice-Infos-Left">
            <div className="Tunnel-Choice-Infos-Left-Title">
              <h1>{name}</h1>
              <span>{description}</span>
            </div>
            <div className="Tunnel-Choice-Infos-Left-Objectif-Container">
              <h3>Objectifs:</h3>
              <div className="Tunnel-Choice-Infos-Left-Objectif">
                {objectif.map((element) => (
                  <p>{element}</p>
                ))}
              </div>
            </div>
            <div className="Tunnel-Choice-Infos-Left-Duration-Container">
              <div>
                <p>
                  Immersion: <span>{dureeImmersion} min</span>
                </p>
                <p>
                  Session: <span>{dureeDebrief} min</span>
                </p>
              </div>
              <div>
                <p>
                  Pré-Requis: <span>{preRequisite}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="Tunnel-Choice-Infos-Right">
            <div className="Tunnel-Choice-Infos-Right-Big-Image" />
            <div className="Tunnel-Choice-Infos-Right-Small-Image">
              <div className="Tunnel-Choice-Infos-Right-Small-Image-1" />
              <div className="Tunnel-Choice-Infos-Right-Small-Image-2" />
            </div>
            <div
              type="button"
              role="button"
              tabIndex={0}
              onClick={handleCloseModal}
              onKeyDown={handleCloseModal}
              className="Tunnel-Choice-Infos-Button"
            >
              <p>X</p>
            </div>
          </div>
        </Animation>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  Univers: state.Themes,
  initialChoice: state.Tunnel.UserChoice.Univers,
});

TunnelChoiceUnivers.propTypes = {
  initialChoice: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageColor: PropTypes.string.isRequired,
  imageGrey: PropTypes.string.isRequired,
  objectif: PropTypes.string.isRequired,
  preRequisite: PropTypes.string.isRequired,
  dureeImmersion: PropTypes.number.isRequired,
  dureeDebrief: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  Univers: PropTypes.arrayOf(object).isRequired,
};

export default connect(mapStateToProps)(TunnelChoiceUnivers);
