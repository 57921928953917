import React from 'react';
import { connect } from 'react-redux';
import TunnelContact from '../TunnelContact';
import TunnelRecap from '../TunnelRecap';

const TunnelFourthQuestion = () => {
  return (
    <>
      <div className="Tunnel-Form-Recap">
        <TunnelRecap />
        <TunnelContact />
      </div>
    </>
  );
};

export default connect()(TunnelFourthQuestion);
