import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Radium, { StyleRoot } from 'radium';
import useSound from 'use-sound';
import { bounce, wobble, zoomIn } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import './Bureau.css';
import './Modals.css';
import imageBoard from '../../styles/img/Game/BUREAU-9_tableauZ-4_sans-Post-it_CROPPED-01-01_lcgzev.svg';
import melanieIdle from '../../styles/img/Game/post-it/postit_1_Melanie_Idle.svg';
import melanieYellow from '../../styles/img/Game/post-it/postit_2_Melanie_Yellow.svg';
import melanieGreen from '../../styles/img/Game/post-it/postit_3_Melanie_Green.svg';
import melanieBlue from '../../styles/img/Game/post-it/postit_4_Melanie_Blue.svg';
import melaniePink from '../../styles/img/Game/post-it/postit_5_Melanie_Prink.svg';
import chloeIdle from '../../styles/img/Game/post-it/postit_6_Chloe_Idle.svg';
import chloeYellow from '../../styles/img/Game/post-it/postit_7_Chloe_Yellow.svg';
import chloeGreen from '../../styles/img/Game/post-it/postit_8_Chloe_Green.svg';
import chloeBlue from '../../styles/img/Game/post-it/postit_9_Chloe_Blue.svg';
import chloePink from '../../styles/img/Game/post-it/postit_10_Chloe_Pink.svg';
import marionIdle from '../../styles/img/Game/post-it/postit_11_Marion_Idle.svg';
import marionYellow from '../../styles/img/Game/post-it/postit_12_Marion_Yellow.svg';
import marionGreen from '../../styles/img/Game/post-it/postit_13_Marion_Green.svg';
import marionBlue from '../../styles/img/Game/post-it/postit_14_Marion_Blue.svg';
import marionPink from '../../styles/img/Game/post-it/postit_15_Marion_Pink.svg';
import pierreIdle from '../../styles/img/Game/post-it/postit_16_Pierre_Idle.svg';
import pierreYellow from '../../styles/img/Game/post-it/postit_17_Pierre_Yellow.svg';
import pierreGreen from '../../styles/img/Game/post-it/postit_18_Pierre_Green.svg';
import pierreBlue from '../../styles/img/Game/post-it/postit_19_Pierre_Blue.svg';
import pierrePink from '../../styles/img/Game/post-it/postit_20_Pierre_Pink.svg';
import {
  counterClick,
  boardPhoto1Click,
  boardPhoto2Click,
  boardPhoto3Click,
  boardPhoto4Click,
  boardPhoto1Reset,
  boardPhoto2Reset,
  boardPhoto3Reset,
  boardPhoto4Reset,
} from '../../actions/index';
import AnticipationAudio from '../../styles/sound/BureauÉmotionGénéraleAnticipation.mp3';
import ModalPrimaryCard from '../Reusable/Modals/ModalPrimaryCard';
import next from '../../img/next-ingame.svg';
import error from '../../sound/Error.mp3';
import BubblesContent from './UserBoard/BubblesContent.json';

const FlipAnimation = keyframes`${zoomIn}`;
const FlipDiv = styled.div`
  animation: 0.5s ${FlipAnimation};
`;

const Board = ({ boardCounter, dispatch, GameUsers, ObjectStatus }) => {
  const [showZoom, setShowZoom] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [playError] = useSound(error);
  const emotionAnticipationId = '25';
  const handleClick = () => {
    setShowZoom(false);
  };

  const styles = {
    bounce: {
      ':hover': {
        animation: 'x .7s',
        animationName: Radium.keyframes(bounce, 'bounce'),
      },
    },
    wobble: {
      animation: 'infinite 5s',
      animationName: Radium.keyframes(wobble, 'wobble'),
    },
  };

  const handlePhotoMelanie = () => {
    dispatch(boardPhoto1Click());
  };

  const handlePhotoChloe = () => {
    dispatch(boardPhoto2Click());
  };

  const handlePhotoMarion = () => {
    dispatch(boardPhoto3Click());
  };

  const handlePhotoPierre = () => {
    dispatch(boardPhoto4Click());
  };

  const handleTryBoard = () => {
    if (
      boardCounter.count1 === 1 &&
      boardCounter.count2 === 4 &&
      boardCounter.count3 === 2 &&
      boardCounter.count4 === 3
    ) {
      const { position, advice } = BubblesContent.bravo3;
      dispatch({
        type: 'SET_BUBBLE',
        payload: {
          position,
          advice,
        },
      });
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'bubbleDisplayed',
          action: true,
        },
      });
      setTimeout(() => {
        dispatch({
          type: 'GAME_ACTION',
          payload: {
            typeOfAction: 'bubbleDisplayed',
            action: false,
          },
        });
      }, 5000);
      dispatch({ type: 'BOARD_SUCCESS', payload: true });
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'gameOver',
          action: true,
        },
      });
      setTimeout(() => {
        dispatch({
          type: 'GAME_ACTION',
          payload: {
            typeOfAction: 'buttonNext',
            action: true,
          },
        });
      }, 129000);
      dispatch(counterClick());
      setShowZoom(false);
      setShowSuccess(true);
      dispatch({
        type: 'FOUND_EMOTION_PRIMARY',
        payload: emotionAnticipationId,
      });
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'timerPause',
          action: true,
        },
      });
      setTimeout(() => {
        dispatch({
          type: 'OFFICE_OBJECT',
          payload: {
            typeOfObject: 'FinalSound',
            object: true,
          },
        });
      }, 1000);
    } else {
      setShowZoom(false);
      dispatch({
        type: 'SHOW_MESSAGE',
        payload: {
          message: 'La combinaison ne semble pas fonctionner',
          show: true,
        },
      });
      playError();
      dispatch(counterClick());
    }
  };

  const handleEndGame = () => {
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'gameFinished',
        action: true,
      },
    });
    setShowSuccess(false);
  };

  if (boardCounter.count1 === 5) {
    dispatch(boardPhoto1Reset());
  } else if (boardCounter.count2 === 5) {
    dispatch(boardPhoto2Reset());
  } else if (boardCounter.count3 === 5) {
    dispatch(boardPhoto3Reset());
  } else if (boardCounter.count4 === 5) {
    dispatch(boardPhoto4Reset());
  }

  let melanieImg = melanieIdle;

  if (boardCounter.count1 === 0) {
    melanieImg = melanieIdle;
  } else if (boardCounter.count1 === 1) {
    melanieImg = melanieYellow;
  } else if (boardCounter.count1 === 2) {
    melanieImg = melanieGreen;
  } else if (boardCounter.count1 === 3) {
    melanieImg = melanieBlue;
  } else if (boardCounter.count1 === 4) {
    melanieImg = melaniePink;
  }

  let chloeImg = chloeIdle;

  if (boardCounter.count2 === 0) {
    chloeImg = chloeIdle;
  } else if (boardCounter.count2 === 1) {
    chloeImg = chloeYellow;
  } else if (boardCounter.count2 === 2) {
    chloeImg = chloeGreen;
  } else if (boardCounter.count2 === 3) {
    chloeImg = chloeBlue;
  } else if (boardCounter.count2 === 4) {
    chloeImg = chloePink;
  }

  let marionImg = marionIdle;

  if (boardCounter.count3 === 0) {
    marionImg = marionIdle;
  } else if (boardCounter.count3 === 1) {
    marionImg = marionYellow;
  } else if (boardCounter.count3 === 2) {
    marionImg = marionGreen;
  } else if (boardCounter.count3 === 3) {
    marionImg = marionBlue;
  } else if (boardCounter.count3 === 4) {
    marionImg = marionPink;
  }

  let pierreImg = pierreIdle;

  if (boardCounter.count4 === 0) {
    pierreImg = pierreIdle;
  } else if (boardCounter.count4 === 1) {
    pierreImg = pierreYellow;
  } else if (boardCounter.count4 === 2) {
    pierreImg = pierreGreen;
  } else if (boardCounter.count4 === 3) {
    pierreImg = pierreBlue;
  } else if (boardCounter.count4 === 4) {
    pierreImg = pierrePink;
  }

  return (
    <>
      {boardCounter.boardSucceed ? (
        <div className="board">
          <img src={imageBoard} alt="Tableau" draggable="false" />
          <div id="PostitMelanie">
            <img src={melanieImg} alt="Mélanie" draggable="false" />
          </div>
          <div id="PostitChloe">
            <img src={chloeImg} alt="Chloé" draggable="false" />
          </div>
          <div id="PostitMarion">
            <img src={marionImg} alt="Marion" draggable="false" />
          </div>
          <div id="PostitPierre">
            <img src={pierreImg} alt="Pierre" draggable="false" />
          </div>
        </div>
      ) : (
        <div
          className="board"
          role="button"
          tabIndex={0}
          onClick={() => setShowZoom(true)}
          onKeyDown={() => setShowZoom(true)}
        >
          <img src={imageBoard} alt="Tableau" draggable="false" />
          <div id="PostitMelanie">
            <img src={melanieImg} alt="Mélanie" draggable="false" />
          </div>
          <div id="PostitChloe">
            <img src={chloeImg} alt="Chloé" draggable="false" />
          </div>
          <div id="PostitMarion">
            <img src={marionImg} alt="Marion" draggable="false" />
          </div>
          <div id="PostitPierre">
            <img src={pierreImg} alt="Pierre" draggable="false" />
          </div>
        </div>
      )}
      {boardCounter.boardSucceed && (
        <div className={showSuccess ? 'ModalTricky-Background' : 'Hide-Modal'}>
          <div className="ModalTricky-Emotion-Card">
            <div className="Emotion-Card-Container">
              <div className="ModalTricky-Emotion-Card">
                <div className="Emotion-Card-Container">
                  <ModalPrimaryCard typeEmotion="25" />
                </div>
              </div>
            </div>
          </div>
          {GameUsers.buttonNext && (
            <div className="button-continue">
              <StyleRoot>
                <button
                  className="ButtonAction Action"
                  type="button"
                  style={styles.wobble}
                  onClick={handleEndGame}
                >
                  Afficher le score{' '}
                  <img className="next-ingame" src={next} alt="Commencer" />
                </button>
              </StyleRoot>
            </div>
          )}
        </div>
      )}
      <FlipDiv
        className={
          showZoom
            ? 'ModalTricky-Background'
            : 'ModalTricky-Background Hide-Modal'
        }
      >
        <div className="ModalTricky-Board-Container">
          <StyleRoot>
            <div className="Board-Melanie">
              <div
                role="button"
                onClick={handlePhotoMelanie}
                onKeyDown={handlePhotoMelanie}
                tabIndex={0}
              >
                <img
                  src={melanieImg}
                  alt="Mélanie-idle"
                  style={styles.bounce}
                  draggable="false"
                />
              </div>
            </div>
          </StyleRoot>
          <StyleRoot>
            <div className="Board-Chloe">
              <div
                role="button"
                onClick={handlePhotoChloe}
                onKeyDown={handlePhotoChloe}
                tabIndex={0}
              >
                <img
                  src={chloeImg}
                  alt="Chloé-idle"
                  style={styles.bounce}
                  draggable="false"
                />
              </div>
            </div>
          </StyleRoot>
          <StyleRoot>
            <div className="Board-Marion">
              <div
                role="button"
                onClick={handlePhotoMarion}
                onKeyDown={handlePhotoMarion}
                tabIndex={0}
              >
                <img
                  src={marionImg}
                  alt="Marion-idle"
                  style={styles.bounce}
                  draggable="false"
                />
              </div>
            </div>
          </StyleRoot>
          <StyleRoot>
            <div className="Board-Pierre">
              <div
                role="button"
                onClick={handlePhotoPierre}
                onKeyDown={handlePhotoPierre}
                tabIndex={0}
              >
                <img
                  src={pierreImg}
                  alt="Pierre-idle"
                  style={styles.bounce}
                  draggable="false"
                />
              </div>
            </div>
          </StyleRoot>
          <div className="Board-Buttons">
            <div className="Buttons">
              <button
                className="ButtonAction Cancel"
                type="button"
                onClick={handleClick}
              >
                Fermer
              </button>
              <button
                className="ButtonAction Action"
                type="button"
                onClick={handleTryBoard}
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      </FlipDiv>
      <audio src={ObjectStatus.FinalSound && AnticipationAudio} autoPlay>
        <track kind="captions" />
      </audio>
    </>
  );
};

const mapStateToProps = (state) => ({
  boardCounter: state.boardCounter,
  preventionMessages: state.prevention_messages.allMessages,
  GameUsers: state.GameUsers,
  ObjectStatus: state.Office.ObjectStatus,
});

Board.propTypes = {
  dispatch: PropTypes.func.isRequired,
  ObjectStatus: PropTypes.shape({
    FinalSound: PropTypes.bool.isRequired,
  }).isRequired,
  boardCounter: PropTypes.shape({
    count1: PropTypes.number.isRequired,
    count2: PropTypes.number.isRequired,
    count3: PropTypes.number.isRequired,
    count4: PropTypes.number.isRequired,
    boardSucceed: PropTypes.bool.isRequired,
  }).isRequired,
  GameUsers: PropTypes.shape({
    buttonNext: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(Board);
