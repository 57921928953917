import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

const TunnelRecapItem = ({ Question, Answer, Index }) => {
  const [transition, setTransition] = useState(false);
  const [transition2, setTransition2] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
    setTimeout(() => {
      setTransition2(true);
    }, 100);
  }, []);

  return (
    <div className="Tunnel-Recap-Pastille-Container">
      <div
        className={
          !transition
            ? `Tunnel-Recap-Pastille-${Index + 1}`
            : `Tunnel-Recap-Pastille-${Index + 1}-transition`
        }
      >
        <p>{Index + 1}</p>
      </div>
      <div className={`Tunnel-Recap-Hide-${Index + 1}`} />
      <div
        className={
          !transition2
            ? `Tunnel-Recap-Choice-${Index + 1}`
            : `Tunnel-Recap-Choice-${Index + 1}-transition`
        }
      >
        <span>{Question}</span>
        <p>{Answer}</p>
      </div>
    </div>
  );
};

TunnelRecapItem.propTypes = {
  Answer: PropTypes.string.isRequired,
  Index: PropTypes.number.isRequired,
  Question: PropTypes.string.isRequired,
};

export default connect()(TunnelRecapItem);
