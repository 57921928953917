import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { fadeOutUp } from 'react-animations';
import './Bureau.css';
import './Modals.css';
import FolderImg from '../../styles/img/Game/folder.svg';
import LetterImg from '../../styles/img/Game/BUREAU-6_courrierZ-2_CROPPED-01.svg';
import letterText from '../../styles/img/Game/courrier_TEXT-01.svg';
import letterOpen from '../../styles/img/Game/letter-open.svg';
import pop from '../../styles/sound/pop.mp3';
import woosh from '../../styles/sound/woosh.mp3';
import { showMessage, counterClick } from '../../actions/index';

const FadeAnimation = keyframes`${fadeOutUp}`;

const BounceImg = styled.img`
  animation: 2s ${FadeAnimation};
`;

const Folder = ({
  Objects,
  ObjectStatus,
  PessimismeMessage,
  Modal,
  dispatch,
}) => {
  const [showFolder, setShowFolder] = useState(false);
  const [showAnimation, setShowAnimation] = useState(true);
  const [clickedLetter, setClickedLetter] = useState(false);
  const [showFolderImg, setShowFolderImg] = useState(true);
  const emotionPessimismeId = '24';
  const HandleClick = () => {
    setClickedLetter(!clickedLetter);
    dispatch({ type: 'LETTER', payload: !clickedLetter });
    dispatch(
      showMessage({
        title: 'Informations',
        message: 'La lettre a été ajoutée à votre inventaire',
        show: true,
      })
    );
    dispatch({
      type: 'ADD_OBJECT',
      payload: {
        name: Objects.AllObjects.filter((item) => item.name === 'Lettre').map(
          (item) => item.name
        )[0],
        image: Objects.AllObjects.filter((item) => item.name === 'Lettre').map(
          (item) => item.image
        )[0],
      },
    });
    setTimeout(() => {
      setShowAnimation(false);
    }, 500);
    setShowFolder(false);
    setShowFolderImg(false);
    dispatch(counterClick());
  };

  const handleCloseLetter = () => {
    dispatch({
      type: 'SHOW_MODAL_LETTER',
      payload: false,
    });
  };

  const handleClosePhoneLetter = () => {
    dispatch({
      type: 'SHOW_MODAL_OPEN_LETTER',
      payload: false,
    });
    if (!PessimismeMessage) {
      dispatch({
        type: 'PESSIMISME_MESSAGE',
        payload: true,
      });
      dispatch({
        type: 'SHOW_MODAL_PREVENTION',
        payload: {
          roomType: 'ModalBureau',
          modalType: 'ModalPessimisme',
          showModal: true,
        },
      });
      dispatch({
        type: 'FOUND_EMOTION',
        payload: emotionPessimismeId,
      });
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'timerPause',
          action: true,
        },
      });
      setTimeout(() => {
        dispatch({
          type: 'SHOW_MODAL_PREVENTION',
          payload: {
            roomType: 'ModalBureau',
            modalType: 'ModalPessimisme',
            showModal: false,
          },
        });
        dispatch({
          type: 'GAME_ACTION',
          payload: {
            typeOfAction: 'timerPause',
            action: false,
          },
        });
      }, 26000);
    }
  };

  return (
    <>
      {showFolder && !ObjectStatus.LetterClicked && (
        <audio src={pop} autoPlay>
          <track kind="captions" />
        </audio>
      )}
      {ObjectStatus.LetterClicked && showAnimation && (
        <>
          <audio src={woosh} autoPlay>
            <track kind="captions" />
          </audio>
          <div className="hide-folder">
            <img src={FolderImg} alt="Dossier" draggable="false" />
          </div>
          <div className="letterroom">
            <img src={LetterImg} alt="Lettre" draggable="false" />
          </div>
        </>
      )}
      {!ObjectStatus.LetterClicked && (
        <div
          className={showFolderImg ? 'folder' : 'hide-folder'}
          role="button"
          onClick={() => setShowFolder(true)}
          onKeyDown={() => setShowFolder(true)}
          tabIndex={0}
        >
          <img src={FolderImg} alt="Dossier" draggable="false" />
        </div>
      )}
      <div
        className={
          showFolder
            ? 'ModalTricky-Background'
            : 'ModalTricky-Background Hide-Modal'
        }
      >
        <div className="ModalTricky-Container">
          <div className="ModalTricky-p">
            <p>
              Vous avez besoin d&apos;un objet tranchant pour ouvrir votre
              lettre
            </p>
          </div>
          <div className="ItemToShow">
            <img src={LetterImg} alt="lettre" id="letter" draggable="false" />
          </div>
          <div>
            <button
              className="ButtonAction Action"
              type="button"
              onClick={HandleClick}
            >
              Ajouter à l&apos;inventaire
            </button>
          </div>
        </div>
      </div>
      <div
        className={
          Modal.ModalLetter
            ? 'ModalTricky-Background'
            : 'ModalTricky-Background Hide-Modal'
        }
      >
        <div className="ModalTricky-Container">
          <div className="ModalObjectTricky-p">
            <p>
              Vous avez besoin d&apos;un objet tranchant pour ouvrir cette
              lettre
            </p>
          </div>
          <div className="ItemToShow">
            <img src={LetterImg} alt="lettre" id="letter" draggable="false" />
          </div>
          <div>
            <button
              className="ButtonAction Action"
              type="button"
              onClick={handleCloseLetter}
            >
              Bien compris !
            </button>
          </div>
        </div>
      </div>
      <div
        className={
          Modal.ModalOpenLetter
            ? 'ModalTricky-Background'
            : 'ModalTricky-Background Hide-Modal'
        }
      >
        <div className="ModalTricky-Container">
          <div className="ModalObjectTricky-p">
            <p>L&apos;enveloppe est ouverte</p>
          </div>
          <div className="ItemToShow opened-letter">
            <img src={letterText} alt="Letter_text" draggable="false" />
          </div>
          <div>
            <button
              className="ButtonAction Action"
              type="button"
              onClick={handleClosePhoneLetter}
            >
              Bien compris !
            </button>
          </div>
        </div>
      </div>
      <div
        className={
          Modal.ModalLetterAnim
            ? 'ModalTricky-Background'
            : 'ModalTricky-Background Hide-Modal'
        }
      >
        <div className="ModalTricky-Container">
          <BounceImg
            src={letterOpen}
            alt="Letter_Open"
            className="Letter-Open"
            draggable="false"
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  Objects: state.Objects,
  ObjectStatus: state.Office.ObjectStatus,
  Modal: state.Office.Modal,
  PessimismeMessage: state.prevention_messages.PessimismeMessage,
});

Folder.propTypes = {
  dispatch: PropTypes.func.isRequired,
  Objects: PropTypes.shape({
    AllObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
    object: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  ObjectStatus: PropTypes.shape({
    LetterClicked: PropTypes.bool.isRequired,
  }).isRequired,
  Modal: PropTypes.shape({
    ModalMug: PropTypes.bool.isRequired,
    ModalLetter: PropTypes.bool.isRequired,
    ModalOpenLetter: PropTypes.bool.isRequired,
    ModalLetterAnim: PropTypes.bool.isRequired,
  }).isRequired,
  PessimismeMessage: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Folder);
