const fetchMessage = (messages) => {
  return {
    type: 'FETCH_MESSAGE',
    messages,
  };
};

export const notifMessage = (payload) => {
  return {
    type: 'MESSAGE_NOTIF',
    payload,
  };
};

export const fetchObject = (objects) => {
  return {
    type: 'FETCH_OBJECT',
    objects,
  };
};

export const addObject = (payload) => {
  return {
    type: 'ADD_OBJECT',
    payload,
  };
};

export const showMessage = (payload) => {
  return {
    type: 'SHOW_MESSAGE',
    payload,
  };
};

export const counterClick = () => {
  return {
    type: 'INCREMENT_COUNTER',
  };
};

export const resetUserAction = () => {
  return {
    type: 'RESET_CLICK_ACTION',
  };
};

export const clickedUsed = () => {
  return {
    type: 'CLICK_USED',
  };
};

export const drawerButton1Click = () => {
  return {
    type: 'INCREMENT_COUNTER1',
  };
};

export const drawerButton2Click = () => {
  return {
    type: 'INCREMENT_COUNTER2',
  };
};

export const drawerButton3Click = () => {
  return {
    type: 'INCREMENT_COUNTER3',
  };
};
export const drawerButton4Click = () => {
  return {
    type: 'INCREMENT_COUNTER4',
  };
};

export const drawerButton1Reset = () => {
  return {
    type: 'RESET_COUNTER1',
  };
};

export const drawerButton2Reset = () => {
  return {
    type: 'RESET_COUNTER2',
  };
};

export const drawerButton3Reset = () => {
  return {
    type: 'RESET_COUNTER3',
  };
};

export const drawerButton4Reset = () => {
  return {
    type: 'RESET_COUNTER4',
  };
};

export const boardPhoto1Click = () => {
  return {
    type: 'INCREMENT_BOARD_COUNTER1',
  };
};

export const boardPhoto2Click = () => {
  return {
    type: 'INCREMENT_BOARD_COUNTER2',
  };
};

export const boardPhoto3Click = () => {
  return {
    type: 'INCREMENT_BOARD_COUNTER3',
  };
};

export const boardPhoto4Click = () => {
  return {
    type: 'INCREMENT_BOARD_COUNTER4',
  };
};

export const boardPhoto1Reset = () => {
  return {
    type: 'RESET_BOARD_COUNTER1',
  };
};

export const boardPhoto2Reset = () => {
  return {
    type: 'RESET_BOARD_COUNTER2',
  };
};

export const boardPhoto3Reset = () => {
  return {
    type: 'RESET_BOARD_COUNTER3',
  };
};

export const boardPhoto4Reset = () => {
  return {
    type: 'RESET_BOARD_COUNTER4',
  };
};

export const phoneOn = (payload) => {
  return {
    type: 'TURN_ON_PHONE',
    payload,
  };
};

export const phoneGoodNumber = (payload) => {
  return {
    type: 'ENTER_GOOD_NUMBER',
    payload,
  };
};

export const addNumberPhone = (payload) => {
  return {
    type: 'ADD_PHONE_DIGIT',
    payload,
  };
};

export const removeLastNumberOfPhone = () => {
  return {
    type: 'REMOVE_PHONE_DIGIT',
  };
};

export const resetPhoneNumber = () => {
  return {
    type: 'RESET_PHONE_NUMBER',
  };
};

export default fetchMessage;
