/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './Bureau.css';
import './Modals.css';
import popwrong from '../../styles/sound/wrong.mp3';
import { counterClick } from '../../actions/index';

const ModalWrongObject = ({ title, img, message, closemodal, dispatch }) => {
  return (
    <>
      <audio src={popwrong} autoPlay>
        <track kind="captions" />
      </audio>
      <div className="ModalTricky-Background">
        <div className="ModalTricky-Container">
          <div className="ModalTricky-p">
            <p dangerouslySetInnerHTML={{ __html: message }} />
          </div>
          <div className="ModalTricky-WrongObject">
            <img src={img} alt={title} draggable="false" />
          </div>
          <div>
            <button
              className="ButtonAction Action"
              type="button"
              onClick={() => {
                dispatch({
                  type: 'SHOW_MODAL',
                  payload: {
                    whichRoom: 'Desktop',
                    typeOfModal: 'WrongObjectsModal',
                    whichModal: closemodal,
                    show: false,
                  },
                });
                dispatch(counterClick());
              }}
            >
              Bien compris
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

ModalWrongObject.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  closemodal: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(ModalWrongObject);
