import React from 'react';
import tricky from '../../../styles/img/Game/Tutorial/Tricky_fox_1.svg';
import emotionCard from '../../../styles/img/Game/Tutorial/Fichier 22.svg';
import imageClock from '../../../styles/img/Game/SALON-2_horloge_1_C-01.svg';
import imageTrash from '../../../styles/img/Game/trash.svg';
import coups from '../../../styles/img/Game/Tutorial/coups.png';

const Tutorial = [
  {
    target: '.GameWindow',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
        className="FoxHead-Tutorial"
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Bienvenue
        </h2>
        <p>dans l&apos;expérience Tricky</p>
        <img style={{ margin: 20, width: 200 }} src={tricky} alt="Tricky" />
        <p>Faisons un tour pour vous familiariser avec le jeu</p>
      </div>
    ),
    placement: 'center',
  },
  {
    target: '.Time-container',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Le temps est compté !
        </h2>
        <img
          style={{ margin: 20, width: 100 }}
          src={imageClock}
          alt="turnbased"
        />
        <p>Tentez de venir à bout des énigmes le plus rapidement possible !</p>
      </div>
    ),
    placement: 'left-end',
  },
  {
    target: '.Gameboard-Header',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Cartes Émotions
        </h2>
        <img style={{ margin: 20, width: 80 }} src={emotionCard} alt="Tricky" />
        <p>
          Vous allez devoir collecter <strong>4</strong> cartes émotions liées à
          la pièce, pour débloquer <strong>l&apos;émotion principale</strong>
        </p>
      </div>
    ),
    placement: 'bottom-end',
  },
  {
    target: '.hat-office img',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Objets
        </h2>
        <img style={{ margin: 20, width: 100 }} src={coups} alt="Tricky" />
        <p>
          Vous pouvez cliquer sur des <strong>objets</strong> pour les ajouter à
          votre inventaire.
        </p>
      </div>
    ),
    placement: 'right',
  },
  {
    target: '.Object-title',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Inventaire
        </h2>
        <img src={imageTrash} alt="inventaire" />
        <p>
          Votre <strong>inventaire</strong> avec vos objets apparaitra ici
        </p>
      </div>
    ),
    placement: 'left',
  },
];

export default Tutorial;
