import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/css/components/Reusable/Input.css';

const Input = ({ htmlFor, label, onChange, value, type, name }) => {
  return (
    <div className="Input">
      <label className="Input_label" htmlFor={htmlFor}>
        <input
          className="Input_input"
          onChange={(e) => onChange(e)}
          value={value}
          type={type}
          name={name}
          placeholder={label}
        />
      </label>
    </div>
  );
};

Input.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Input;
