import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ScoreboardItem from './ScoreboardItem';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ScoreboardTop10 = ({ GameUsers, status }) => {
  const [players, setPlayers] = useState([]);

  // useEffect(() => {
  //   axios
  //     .get(`${BASE_URL}/api/scores/top10`)
  //     .then((response) => response.data)
  //     .then((data) => setPlayers(data));
  // }, []);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/scores/allscores`)
      .then((response) => response.data)
      .then((data) => setPlayers(data));
  }, [players]);

  return (
    <div className={status ? 'Show' : 'Hide'}>
      {players.length !== 0 &&
        players
          .slice(0, 10)
          .map((item, index) => (
            <ScoreboardItem
              key={item.id}
              id={index}
              style={item.email === GameUsers.user.email}
              rank={item.rank}
              pseudo={item.pseudo}
              count={item.count}
              time={item.time}
            />
          ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  GameUsers: state.GameUsers,
});

ScoreboardTop10.propTypes = {
  GameUsers: PropTypes.shape({
    user: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }),
  }).isRequired,
  status: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(ScoreboardTop10);
