import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TunnelRecapItem from './TunnelRecapItem';

const TunnelRecap = ({ UserChoice }) => {
  const UserChoiceArray = Object.entries(UserChoice);

  return (
    <div className="Tunnel-Recap-Global">
      <h3>Récapitulatif</h3>
      <p>Voici votre solution personnalisée</p>
      <div className="Tunnel-Recap-Container">
        {UserChoiceArray.map((element, index) => (
          <TunnelRecapItem
            Question={element[0]}
            Answer={element[1]}
            Index={index}
          />
        ))}
      </div>
    </div>
  );
};

TunnelRecap.propTypes = {
  UserChoice: PropTypes.shape({
    Univers: PropTypes.string,
    Type: PropTypes.string,
    Thématiques: PropTypes.string,
    Déploiement: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  UserChoice: state.Tunnel.UserChoice,
});

export default connect(mapStateToProps)(TunnelRecap);
