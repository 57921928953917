import vousColor from '../../styles/img/company_color.svg';
import vousGrey from '../../styles/img/company_grey.svg';
import nousColor from '../../styles/img/tricky_house_color.svg';
import nousGrey from '../../styles/img/tricky_house_grey.svg';
import distanceColor from '../../styles/img/visio_color.svg';
import distanceGrey from '../../styles/img/visio_grey.svg';
import containerColor from '../../styles/img/container_color.svg';
import containerGrey from '../../styles/img/container_grey.svg';

const initialState = {
  Actions: [
    {
      idReponse: 1,
      title: 'distance',
      name: 'À distance',
      description:
        "All right, kid. But you'd better be right about this. All right. What's your plan? Uh...Threepio, hand me those binders there will you? Okay. Now, I'm going to put these on you. Okay. Han, you put these on. Don't worry, Chewie. I think I know what he has in mind. Master Luke, sir! Pardon me for asking...but, ah...what should Artoo and I do if we're discovered here? Lock the door! And hope they don't have blasters. That isn't very reassuring.",
      imageColor: distanceColor,
      imageGrey: distanceGrey,
      choosen: false,
      modal: false,
    },
    {
      idReponse: 2,
      title: 'nous',
      name: 'Chez nous',
      description:
        "All right, kid. But you'd better be right about this. All right. What's your plan? Uh...Threepio, hand me those binders there will you? Okay. Now, I'm going to put these on you. Okay. Han, you put these on. Don't worry, Chewie. I think I know what he has in mind. Master Luke, sir! Pardon me for asking...but, ah...what should Artoo and I do if we're discovered here? Lock the door! And hope they don't have blasters. That isn't very reassuring.",
      imageColor: nousColor,
      imageGrey: nousGrey,
      choosen: false,
      modal: false,
    },
    {
      idReponse: 3,
      title: 'vous',
      name: 'Vos locaux',
      description:
        "All right, kid. But you'd better be right about this. All right. What's your plan? Uh...Threepio, hand me those binders there will you? Okay. Now, I'm going to put these on you. Okay. Han, you put these on. Don't worry, Chewie. I think I know what he has in mind. Master Luke, sir! Pardon me for asking...but, ah...what should Artoo and I do if we're discovered here? Lock the door! And hope they don't have blasters. That isn't very reassuring.",
      imageColor: vousColor,
      imageGrey: vousGrey,
      choosen: false,
      modal: false,
    },
    {
      idReponse: 4,
      title: 'container',
      name: 'Container',
      description:
        "All right, kid. But you'd better be right about this. All right. What's your plan? Uh...Threepio, hand me those binders there will you? Okay. Now, I'm going to put these on you. Okay. Han, you put these on. Don't worry, Chewie. I think I know what he has in mind. Master Luke, sir! Pardon me for asking...but, ah...what should Artoo and I do if we're discovered here? Lock the door! And hope they don't have blasters. That isn't very reassuring.",
      imageColor: containerColor,
      imageGrey: containerGrey,
      choosen: false,
      modal: false,
    },
  ],
};

const Actions = (state = initialState, action) => {
  const resetActions = () => {
    const lenghtArray = state.Actions.filter((element) => element.choosen)
      .length;
    if (lenghtArray > 0) {
      const actualChoiceId = state.Actions.filter(
        (element) => element.choosen
      )[0].idReponse;
      return state.Actions.map((element) => {
        if (element.idReponse === actualChoiceId) {
          return {
            ...element,
            choosen: false,
            modal: false,
          };
        }
        return element;
      });
    }
    return state.Actions;
  };
  switch (action.type) {
    case 'RESET_ACTIONS_CLICKED': {
      return {
        ...state,
        Actions: resetActions(),
      };
    }
    case 'ACTIONS_CLICKED': {
      return {
        ...state,
        Actions: state.Actions.map((element) => {
          if (element.idReponse === action.payload.idChoice) {
            return {
              ...element,
              choosen: action.payload.choosen,
              modal: action.payload.choosen,
            };
          }
          return element;
        }),
      };
    }
    case 'RESET_ACTIONS_GLOBAL': {
      return initialState;
    }
    default:
      return state;
  }
};

export default Actions;
