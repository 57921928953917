import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '../Reusable/Buttons/Button';

import '../../styles/css/components/Home/Header.css';

import head from '../../styles/img/head.png';

const headLogo = head;

const Header = ({ isTunnel }) => {
  return (
    <header className="Header">
      {!isTunnel && (
        <div className="Header-left">
          <Button title="Digital Room" url="/digital-room" type="header" />
        </div>
      )}
      <Link to="/" className="Header_logo">
        <img src={headLogo} alt="Tricky Logo Home" />
      </Link>
      {!isTunnel && (
        <div className="Header-right">
          <Button title="Contact" url="/contact" type="header" />
        </div>
      )}
    </header>
  );
};

Header.defaultProps = {
  isTunnel: false,
};

Header.propTypes = {
  isTunnel: PropTypes.bool,
};

export default Header;
