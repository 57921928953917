import React from 'react';
import PropTypes, { string } from 'prop-types';
import { connect } from 'react-redux';
import TunnelChoiceCompany from '../TunnelChoice/TunnelChoiceCompany';

const TunnelFirstQuestion = ({ Company, initialChoice }) => {
  let typeOfCompany;
  switch (initialChoice) {
    case 'Entreprise':
      typeOfCompany = "taille d'entreprise";
      break;
    case 'Hospitalier':
      typeOfCompany = 'type de structure hospitalière';
      break;
    case 'Université':
      typeOfCompany = 'type de structure écolière';
      break;
    default:
      return typeOfCompany;
  }

  return (
    <>
      <h3>Choisissez votre {typeOfCompany}: </h3>
      <div className="Tunnel-Form-Choice">
        {Company[initialChoice].map((element) => (
          <TunnelChoiceCompany
            name={element.name}
            smallDesc={element.smalldesc}
            description={element.desc}
            imageColor={element.imageColor}
            imageGrey={element.imageGrey}
            idReponse={element.idReponse}
          />
        ))}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  Company: state.Company,
  initialChoice: state.Tunnel.UserChoice.Univers,
});

TunnelFirstQuestion.propTypes = {
  Company: PropTypes.arrayOf(string).isRequired,
  initialChoice: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(TunnelFirstQuestion);
