import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useSound from 'use-sound';
import styled, { keyframes } from 'styled-components';
import { zoomIn } from 'react-animations';
import './Bureau.css';
import './Modals.css';
import powerChordImg from '../../styles/img/Game/BUREAU-1_chargeur_CROPPED-01.svg';
import powerChordPlugged from '../../styles/img/Game/BUREAU-1_chargeurZ-7.svg';
import zoomCharger from '../../styles/img/Game/BUREAU-ZOOM-CHARGEUR-UNPLUGGED.svg';
import anxiete from '../../styles/sound/board_anxieux_1.mp3';
import ScreenPassword from '../../styles/img/Game/BUREAU-2_ordi-password.svg';
import PhoneOn from '../../styles/img/Game/phone_ON.svg';
import { phoneOn, counterClick, showMessage } from '../../actions/index';
import PreventionMessages from './PreventionMessages';
import plug from '../../sound/Click-Switch.mp3';

const FlipAnimation = keyframes`${zoomIn}`;
const FlipImg = styled.img`
  animation: 1s ${FlipAnimation};
`;

const FlipDiv = styled.div`
  animation: 1s ${FlipAnimation};
`;

const PowerChord = ({ ObjectStatus, Modal, ModalBureau, dispatch }) => {
  const emotionAnxieteId = '1';
  const [isPluggedIn, setIspluggedIn] = useState(false);
  const [playPlug] = useSound(plug);

  const PluggedIn = () => {
    setIspluggedIn(!isPluggedIn);
    dispatch({ type: 'PLUG-IN', payload: !isPluggedIn });
    dispatch({
      type: 'COMPUTER_STATUS',
      payload: {
        image: ScreenPassword,
        status: 'turn_on',
      },
    });
    dispatch(phoneOn({ image: PhoneOn }));
    dispatch(
      showMessage({
        title: 'Message de prévention',
      })
    );
    dispatch(counterClick());
    playPlug();
    dispatch({
      type: 'SHOW_MODAL_POWERCHORD',
      payload: false,
    });
    dispatch({
      type: 'SHOW_MODAL_PREVENTION',
      payload: {
        roomType: 'ModalBureau',
        modalType: 'ModalAnxiete',
        showModal: true,
        timer: true,
      },
    });
    dispatch({
      type: 'FOUND_EMOTION',
      payload: emotionAnxieteId,
    });
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'timerPause',
        action: true,
      },
    });
    setTimeout(() => {
      dispatch({
        type: 'SHOW_MODAL_PREVENTION',
        payload: {
          roomType: 'ModalBureau',
          modalType: 'ModalAnxiete',
          showModal: false,
          timer: false,
        },
      });
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'timerPause',
          action: false,
        },
      });
    }, 24000);
  };

  return (
    <>
      {!ObjectStatus.hasElectricity ? (
        <div
          className="power-chord"
          onClick={() =>
            dispatch({
              type: 'SHOW_MODAL_POWERCHORD',
              payload: true,
            })
          }
          onKeyDown={() =>
            dispatch({
              type: 'SHOW_MODAL_POWERCHORD',
              payload: true,
            })
          }
          tabIndex={0}
          role="button"
        >
          <img src={powerChordImg} alt="bureau" draggable="false" />
        </div>
      ) : (
        <div className="power-chord-plugged">
          <img src={powerChordPlugged} alt="bureau" draggable="false" />
        </div>
      )}
      <div
        className={
          Modal.ModalPowerchord
            ? 'ModalTricky-Background'
            : 'ModalTricky-Background Hide-Modal'
        }
      >
        <FlipImg
          src={zoomCharger}
          alt="bureau"
          className="PowerChord"
          draggable="false"
        />
        <FlipDiv className="textPowerChord">
          <p>Le cable électrique semble débranché...</p>
          <p>Voulez-vous le brancher ?</p>
        </FlipDiv>
        <FlipDiv className="btnPowerChord">
          <button
            className="ButtonAction Action"
            type="button"
            onClick={PluggedIn}
          >
            Brancher
          </button>
        </FlipDiv>
      </div>
      {ModalBureau.ModalAnxiete && (
        <PreventionMessages
          typeEmotion={emotionAnxieteId}
          sound={anxiete}
          endTimer={23000}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  ObjectStatus: state.Office.ObjectStatus,
  preventionMessages: state.prevention_messages.allMessages,
  Modal: state.Office.Modal,
  ModalBureau: state.prevention_messages.ModalBureau,
});

PowerChord.propTypes = {
  dispatch: PropTypes.func.isRequired,
  ObjectStatus: PropTypes.shape({
    hasElectricity: PropTypes.bool,
  }).isRequired,
  Modal: PropTypes.shape({
    ModalPowerchord: PropTypes.bool.isRequired,
    ModalMug: PropTypes.bool.isRequired,
  }).isRequired,
  ModalBureau: PropTypes.shape({
    ModalAnxiete: PropTypes.bool,
    ModalAgressivite: PropTypes.bool,
    ModalFatalite: PropTypes.bool,
    ModalPessimisme: PropTypes.bool,
  }).isRequired,
};

export default connect(mapStateToProps)(PowerChord);
