import PropTypes from 'prop-types';
import React from 'react';
import './TunnelHeader.css';
import { connect } from 'react-redux';
import trickyLogo from '../../styles/img/Logotype_cpt_couleur.svg';

const TunnelHeader = ({ dispatch }) => {
  return (
    <section className="Tunnel-Header-Container">
      <div
        onClick={() => dispatch({ type: 'BACK_HOME', payload: { home: true } })}
        onKeyDown={() =>
          dispatch({ type: 'BACK_HOME', payload: { home: true } })
        }
        role="button"
        tabIndex={0}
        className="Tunnel-Header-Container-Logo"
      >
        <img src={trickyLogo} alt="Tricky Logo" />
      </div>
    </section>
  );
};

TunnelHeader.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  TunnelHeader: state.Tunnel.TunnelHeader,
});

export default connect(mapStateToProps)(TunnelHeader);
