import React from 'react';
import tricky from '../../../styles/img/Game/Tutorial/Tricky_fox_1.svg';
import emotionCard from '../../../styles/img/Game/Tutorial/Fichier 22.svg';
import imageClock from '../../../styles/img/Game/SALON-2_horloge_1_C-01.svg';
import imageTrash from '../../../styles/img/Game/trash.svg';
import coups from '../../../styles/img/Game/Tutorial/coups.png';

const Tutorial = [
  {
    target: '.GameWindow-DemoMulti',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
        className="FoxHead-Tutorial"
      >
        <h2 className="ModalTricky-Intro-Title2">Bienvenue</h2>
        <img style={{ margin: 20, width: 200 }} src={tricky} alt="Tricky" />
        <p>Laissez-moi vous présenter comment fonctionne le jeu !</p>
      </div>
    ),
    placement: 'center',
  },
  {
    target: '.Time-container',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Le temps est compté !
        </h2>
        <img
          style={{ margin: 20, width: 100 }}
          src={imageClock}
          alt="turnbased"
        />
        <p>Tentez de venir à bout des énigmes le plus rapidement possible !</p>
      </div>
    ),
    placement: 'right-end',
  },
  {
    target: '.hat-office img',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Objets
        </h2>
        <img style={{ margin: 20, width: 100 }} src={coups} alt="Tricky" />
        <p>
          Vous pouvez cliquer sur des <strong>objets</strong> pour les ajouter à
          votre inventaire.
        </p>
      </div>
    ),
    placement: 'right',
  },
  {
    target: '.Object-title',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Inventaire
        </h2>
        <img src={imageTrash} alt="inventaire" />
        <p>
          Votre <strong>inventaire</strong> avec vos objets apparaîtra ici.
        </p>
      </div>
    ),
    placement: 'right-start',
  },
  {
    target: '.Gameboard-Header',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Cartes Émotions
        </h2>
        <img style={{ margin: 20, width: 80 }} src={emotionCard} alt="Tricky" />
        <p>
          Vous allez devoir collecter <strong>4 cartes</strong> émotions liées à
          la pièce, pour débloquer <strong>l&apos;émotion principale</strong>.
        </p>
      </div>
    ),
    placement: 'bottom-end',
  },
  {
    target: '.UserBoard-Localparticipant',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Votre webcam
        </h2>
        <p>
          <strong>Cette démo n&apos;active aucune webcam.</strong>
          <br />
          Mais lors de l&apos;expérience complète, votre webcam sera toujours
          placée ici.
        </p>
      </div>
    ),
    placement: 'left-start',
  },
  {
    target: '.Participants',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Les webcams de vos collègues
        </h2>
        <p>
          L&apos;expérience Tricky vous permettra de jouer avec{' '}
          <strong>3 collègues en simultané.</strong>
          <br />
          <br />
          <i>
            Ici, ces personnes ne sont pas réelles et servent juste
            d&apos;illustration.
          </i>
        </p>
      </div>
    ),
    placement: 'left-end',
  },
];

export default Tutorial;
