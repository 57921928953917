import smallCompanyImgGrey from '../../styles/img/small_company_grey_150.svg';
import smallCompanyImgColor from '../../styles/img/small_company_color_150.svg';
import mediumCompanyImgGrey from '../../styles/img/medium_company_grey_150.svg';
import mediumCompanyImgColor from '../../styles/img/medium_company_color_150.svg';
import bigCompanyImgGrey from '../../styles/img/big_company_grey_150.svg';
import bigCompanyImgColor from '../../styles/img/big_company_color_150.svg';
import veryBigCompanyImgGrey from '../../styles/img/very_big_company_grey_150.svg';
import veryBigCompanyImgColor from '../../styles/img/very_big_company_color_150.svg';

const initialState = {
  Entreprise: [
    {
      idReponse: 1,
      name: 'TPE',
      smalldesc: 'Très Petite Entreprise',
      desc:
        'En se référant à la définition de l’Union Européenne, les principaux critères d’une TPE sont un effectif de <strong>moins de 10 personnes</strong> ainsi qu’un chiffre d’affaires en dessous de <strong>2 millions d’euros</strong>. Les critères énoncés par le décret n°2008-1354 du 18 décembre 2008 de la France ne diffèrent pas trop de ceux édictés par l’Union Européenne.',
      imageColor: smallCompanyImgColor,
      imageGrey: smallCompanyImgGrey,
      className: 'petite',
      choosen: false,
    },
    {
      idReponse: 2,
      name: 'PME',
      smalldesc: 'Petite et Moyenne Entreprise',
      desc:
        'Selon l’Union Européenne, une PME est un établissement constitué d’un effectif de salariés allant de <strong>50 à 250 personnes</strong>. Quant aux chiffres d’affaires, ils varient d’une somme qui ne va pas au-delà de <strong>50 millions d’euros</strong>.',
      imageColor: mediumCompanyImgColor,
      imageGrey: mediumCompanyImgGrey,
      className: 'moyenne',
      choosen: false,
    },
    {
      idReponse: 3,
      name: 'ETI',
      smalldesc: 'Entreprise de Taille Intermédiaire',
      desc:
        'Pour être classée dans la catégorie des ETI ou Entreprise de Taille Intermédiaire, l’effectif des salariés ne doit pas aller au-delà de <strong>5 000 personnes</strong>. Il faut par ailleurs que l’entreprise ait un chiffre d’affaires maximum de <strong>1,5 milliard d’euros</strong> ainsi qu’un bilan annuel ne dépassant pas les 2 milliards d’euros. En tout cas, une ETI n’appartient pas à la catégorie des Petites et Moyennes Entreprises.',
      imageColor: bigCompanyImgColor,
      imageGrey: bigCompanyImgGrey,
      className: 'grande',
      choosen: false,
    },
    {
      idReponse: 4,
      name: 'GE',
      smalldesc: 'Grande Entreprise',
      desc:
        'Une entreprise est dite GE quand elle dispose de plus de <strong>5000 salariés</strong>. La présence de cette catégorie permet aux PME d’avoir lieu d’être. En effet, une grande entreprise est souvent source du système de sous-traitance, alimentant souvent les PME.',
      imageColor: veryBigCompanyImgColor,
      imageGrey: veryBigCompanyImgGrey,
      className: 'huge',
      choosen: false,
    },
  ],
  Hospitalier: [
    {
      idReponse: 1,
      name: 'Structure libérale',
      smalldesc: 'Centre Hospitalier ou Clinique',
      desc:
        "Les établissements publics de santé qui ne figurent ni sur la liste des centres hospitaliers régionaux, ni sur les listes d’hôpitaux locaux sont des <strong>centres hospitaliers</strong>.<br /><br /><strong>Une clinique</strong> est un établissement ou une section d'établissement hospitalier public ou privé généralement spécialisé.",
      imageColor: mediumCompanyImgColor,
      imageGrey: mediumCompanyImgGrey,
      choosen: false,
    },
    {
      idReponse: 2,
      name: 'ESMS',
      desc:
        "Un <strong>établissement ou service social ou médico-social</strong> (ESMS) est une structure dont la vocation est d'accueillir et d'accompagner, dans leur enceinte ou de manière ambulatoire, pour une brève durée ou au long cours, des personnes handicapées, dépendantes ou en situation d'exclusion sociale.",
      smalldesc: 'Établissement & Service Médico-Sociaux',
      imageColor: bigCompanyImgColor,
      imageGrey: bigCompanyImgGrey,
      className: 'moyenne',
      choosen: false,
    },
    {
      idReponse: 3,
      name: 'Structure sanitaire',
      desc:
        'Une <strong>Maison de Santé Pluridisciplinaire</strong> (MSP), ou PluriProfessionnelle (MSPP), est, en France, un établissement regroupant des professionnels de la santé exerçant plusieurs disciplines différentes (médecins, infirmiers, masseurs-kinésithérapeutes, pédicures-podologues...).<br />Les <strong>communautés professionnelles territoriales de santé</strong> (CPTS) associent des professionnels de santé et des acteurs sociaux et médico-sociaux d’un même territoire, sur la base d’un projet de santé, pour une meilleure organisation des parcours des patients et un meilleur exercice des professionnels de santé de ville',
      smalldesc: 'MSP, CPTS, ...',
      imageColor: veryBigCompanyImgColor,
      imageGrey: veryBigCompanyImgGrey,
      className: 'grande',
      choosen: false,
    },
    {
      idReponse: 4,
      name: 'Autre',
      smalldesc: '...',
      desc: '',
      imageColor: veryBigCompanyImgColor,
      imageGrey: veryBigCompanyImgGrey,
      className: 'grande',
      choosen: false,
    },
  ],
  Université: [
    {
      idReponse: 1,
      name: 'Scolaire',
      smalldesc: 'Lycée et collège',
      desc:
        "Dans le système éducatif en France, le <strong>collège</strong> est l’appellation courante du premier cycle des études du second degré. C’est un enseignement de quatre ans, qui fait suite à l’école élémentaire. <br /><br />Le <strong>lycée</strong> correspond au second cycle des études secondaires. Ce second cycle peut être général et technologique ou professionnel1. Il correspond principalement aux trois dernières années de l'enseignement secondaire (seconde, première et terminale).",
      imageColor: mediumCompanyImgColor,
      imageGrey: mediumCompanyImgGrey,
      choosen: false,
    },
    {
      idReponse: 2,
      name: 'Supérieure',
      smalldesc: 'Université & Grandes écoles',
      desc:
        "Le terme d'études supérieures ou <strong>d'enseignement supérieur</strong>, désigne généralement l'instruction dispensée par les universités, les collèges anglo-saxons, avec en France un système plus dual de grandes écoles, d’institut et d'autres institutions comme les grands établissements décernant des grades universitaires ou autres diplômes de l’enseignement supérieur.",
      imageColor: bigCompanyImgColor,
      imageGrey: bigCompanyImgGrey,
      className: 'moyenne',
      choosen: false,
    },
    {
      idReponse: 3,
      name: 'Autre',
      smalldesc: '...',
      desc: '',
      imageColor: bigCompanyImgColor,
      imageGrey: bigCompanyImgGrey,
      className: 'grande',
      choosen: false,
    },
  ],
};

const Company = (state = initialState, action) => {
  const resetCompany = (companyType) => {
    const lenghtArray = state[companyType].filter((element) => element.choosen)
      .length;
    if (lenghtArray > 0) {
      const actualChoiceId = state[companyType].filter(
        (element) => element.choosen
      )[0].idReponse;
      return state[companyType].map((element) => {
        if (element.idReponse === actualChoiceId) {
          return {
            ...element,
            choosen: false,
          };
        }
        return element;
      });
    }
    return state[companyType];
  };
  switch (action.type) {
    case 'RESET_COMPANY_CLICKED': {
      const companyType = action.payload.typeOfCompany;
      return {
        ...state,
        [companyType]: resetCompany(companyType),
      };
    }
    case 'COMPANY_CLICKED': {
      const companyType2 = action.payload.typeOfCompany;
      return {
        ...state,
        [companyType2]: state[companyType2].map((element) => {
          if (element.idReponse === action.payload.idChoice) {
            return {
              ...element,
              choosen: action.payload.choosen,
            };
          }
          return element;
        }),
      };
    }
    case 'RESET_COMPANY_GLOBAL': {
      return initialState;
    }
    default:
      return state;
  }
};

export default Company;
