import React, { useState } from 'react';
import './Dashboard.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ObjectList from './ObjectList';
import TurnCounter from './TurnCounter';
import Volume from '../../../img/volume-up-solid-white.svg';
import VolumeMute from '../../../img/volume-mute-solid-white.svg';
import logo from '../../../img/Game/Logotype_cpt_blanc.svg';
import Timer from './Timer';

const Dashboard = ({ dispatch, GameUsers }) => {
  const [muteSound, setMuteSound] = useState(true);
  const handleMuteSoundtrack = () => {
    setMuteSound(!muteSound);
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'soundMuted',
        action: muteSound,
      },
    });
  };

  const wannaLeave = () => {
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'wannaLeave',
        action: true,
      },
    });
  };

  return (
    <div className="Dashboard-container">
      <div className="Dashboard-Logo">
        <img src={logo} alt="Logo Tricky" />
        <h3>Démo</h3>
      </div>
      <a
        className="Dashboard-Preco"
        href="https://tricky.fr/contact-us"
        rel="noopener noreferrer"
        target="_blank"
      >
        <button type="button" className="ButtonAction Contact">
          Nous contacter
        </button>
      </a>
      <h2 className="Object-title">Inventaire</h2>
      <ObjectList />
      <TurnCounter />
      <Timer />
      <div
        onClick={handleMuteSoundtrack}
        onKeyDown={handleMuteSoundtrack}
        role="button"
        tabIndex={0}
        className={GameUsers.gameStarted ? 'Sound-Game' : 'Hide'}
      >
        <p>{GameUsers.soundMuted ? 'Activer le son' : 'Couper le son'}</p>
        <img
          src={GameUsers.soundMuted ? VolumeMute : Volume}
          alt={GameUsers.soundMuted ? 'Activer le son' : 'Couper le son'}
          title={GameUsers.soundMuted ? 'Activer le son' : 'Couper le son'}
          draggable="false"
        />
      </div>
      <div
        className="Dashboard-p"
        onClick={wannaLeave}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
      >
        <p>Quitter le jeu</p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  GameUsers: state.GameUsers,
});

Dashboard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  GameUsers: PropTypes.shape({
    soundMuted: PropTypes.bool.isRequired,
    gameStarted: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(Dashboard);
