const initialState = {
  count: 0,
  gameStarted: false,
  gameOver: false,
  timerPause: false,
  soundMuted: false,
  buttonNext: false,
  gameFinished: false,
  scoreboard: false,
  wannaLeave: false,
  firstname: '',
  lastname: '',
  email: '',
  cardsFound: [],
  rankingDatas: [],
  primaryCardsFound: [],
  bubble: {
    position: 0,
    advice: '',
  },
  bubbleDisplayed: false,
  finalEmotions: ['25'],
  modalCardFound: false,
  scoreboard10: false,
  returnSound: false,
  clicked: false,
  countdown: false,
  countdownReady: false,
  time: '00:00:00',
  user: [],
  language: 'FR',
  cardToReverse: {
    position: '',
    card: '',
    count: 0,
  },
};

const GameUsers = (state = initialState, action) => {
  switch (action.type) {
    case 'GAME_ACTION': {
      const whichAction = action.payload.typeOfAction;
      return {
        ...state,
        [whichAction]: action.payload.action,
      };
    }
    case 'INCREMENT_COUNTER':
      return {
        ...state,
        count: state.count + 1,
      };
    case 'CLICKED_MOUSE_UP':
      return {
        ...state,
        clicked: false,
      };
    case 'CLICKED_MOUSE_DOWN':
      return {
        ...state,
        clicked: true,
      };
    case 'SET_BUBBLE':
      return {
        ...state,
        bubble: {
          ...state.bubble,
          position: action.payload.position,
          advice: action.payload.advice,
        },
      };
    case 'FOUND_EMOTION_PRIMARY':
      return {
        ...state,
        primaryCardsFound: [...state.primaryCardsFound, action.payload],
      };
    case 'LIST_FINAL_EMOTION':
      return {
        ...state,
        finalEmotions: action.payload,
      };
    case 'FOUND_EMOTION':
      return {
        ...state,
        cardsFound: [...state.cardsFound, action.payload],
      };
    case 'SHOW_MODAL_PREVENTION_GLOBAL':
      return {
        ...state,
        modalCardFound: action.payload,
      };
    case 'REVERSE_CARDS':
      return {
        ...state,
        cardToReverse: {
          ...state.cardToReverse,
          position: action.payload.position,
          card: action.payload.card,
          count: state.cardToReverse.count + 1,
        },
      };
    default:
      return state;
  }
};

export default GameUsers;
