const initialState = {
  TunnelNavigation: [
    {
      index: 0,
      name: 'Question Univers',
      title: 'Univers',
      status: 'doing',
      checked: false,
      isActive: true,
    },
    {
      index: 1,
      name: 'Question 1 Type',
      title: 'Type',
      status: 'unDone',
      checked: false,
      isActive: false,
    },
    {
      index: 2,
      name: 'Question 2 Theme',
      title: 'Thématiques',
      status: 'unDone',
      checked: false,
      isActive: false,
    },
    {
      index: 3,
      name: 'Question 3',
      title: 'Déploiement',
      status: 'unDone',
      checked: false,
      isActive: false,
    },
    {
      index: 4,
      name: 'Question 4 Infos',
      title: 'Finalisation',
      status: 'unDone',
      checked: false,
      isActive: false,
    },
  ],
  UserChoice: {
    Univers: '',
    Type: '',
    Thématiques: '',
    Déploiement: '',
  },
  UserContact: {
    Nom: '',
    Email: '',
    Entreprise: '',
  },
  initialQuestion: [
    {
      name: 'Patients',
      universTheme: 'Hospitalier',
    },
    {
      name: 'Entreprises',
      universTheme: 'Entreprise',
    },
    {
      name: 'Jeunes',
      universTheme: 'Université',
    },
  ],
  InfoSent: false,
  NumberOfEmployee: 0,
  TotalCost: 0,
  Width: 0,
  HomeClicked: false,
};

const Tunnel = (state = initialState, action) => {
  const checkAddition = () => {
    if (action.payload.status === 'done') {
      return state.Width + 40;
    }
    if (action.payload.status === 'doing') {
      return state.Width - 20;
    }
    return state.Width;
  };
  switch (action.type) {
    case 'BACK_HOME':
      return {
        ...state,
        HomeClicked: action.payload.home,
      };
    case 'NUMBER_OF_EMPLOYEE':
      return {
        ...state,
        NumberOfEmployee: action.number,
      };
    case 'NAVIGATION_CHANGE':
      return {
        ...state,
        Width: checkAddition(),
        TunnelNavigation: state.TunnelNavigation.map((element) => {
          if (element.name === action.payload.question) {
            return {
              ...element,
              isActive: action.payload.active,
              status: action.payload.status,
            };
          }
          return element;
        }),
      };
    case 'CHOICE_CHECKED':
      return {
        ...state,
        TunnelNavigation: state.TunnelNavigation.map((element) => {
          if (element.name === action.payload.question) {
            return {
              ...element,
              checked: true,
              status: action.payload.status,
            };
          }
          return element;
        }),
      };
    case 'USER_CHOICE': {
      const whichQuestion = action.payload.choice;
      return {
        ...state,
        UserChoice: {
          ...state.UserChoice,
          [whichQuestion]: action.payload.answer,
        },
      };
    }
    case 'USER_CONTACT': {
      return {
        ...state,
        UserContact: {
          ...state.UserContact,
          Nom: action.payload.nom,
          Email: action.payload.email,
          Entreprise: action.payload.entreprise,
        },
      };
    }
    case 'INFO_SENT': {
      return {
        ...state,
        InfoSent: action.payload.send,
      };
    }
    case 'RESET_NAVIGATION': {
      return initialState;
    }
    default:
      return state;
  }
};

export default Tunnel;
