/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import confetti from 'canvas-confetti';
import { connect } from 'react-redux';
import success from '../../../sound/successhorns1.mp3';
import sound from '../../../styles/sound/returncard.mp3';
import './ModalEmotionCard.css';

const ModalPrimaryCard = ({
  typeEmotion,
  allMessages,
  language,
  primaryCardsFound,
  game,
  cardToReverse,
  dispatch,
}) => {
  const finalEmotions = ['25', '45', '46', '47', '48'];
  const reversingCards = [1, 2, 23, 24];
  const timers = [29000, 51000, 74000, 93000];
  const [soundOn, setSoundOn] = useState(false);
  const typeCardId = Number(typeEmotion);
  const enabledCards = finalEmotions.includes(typeEmotion);
  useEffect(() => {
    const duration = 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    const interval = setInterval(function () {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 300 * (timeLeft / duration);
      confetti(
        Object.assign(defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.4), y: Math.random() - 0.2 },
        })
      );
      confetti(
        Object.assign(defaults, {
          particleCount,
          origin: { x: randomInRange(0.6, 0.9), y: Math.random() - 0.2 },
        })
      );
    }, 250);
    setSoundOn(true);
    setTimeout(() => {
      setSoundOn(false);
    }, 1500);
  }, []);

  useEffect(() => {
    document
      .getElementsByClassName(`ModalEmotionCardPrimary_face_1`)[0]
      .click();
    document
      .getElementsByClassName(`ModalEmotionCardPrimary_face_2`)[0]
      .click();
    document
      .getElementsByClassName(`ModalEmotionCardPrimary_face_23`)[0]
      .click();
    document
      .getElementsByClassName(`ModalEmotionCardPrimary_face_24`)[0]
      .click();
  }, []);

  useEffect(() => {
    if (cardToReverse.count > 0) {
      const elem = document.getElementById(`card-${cardToReverse.card}`);
      const elemReversed = document.getElementById(
        `cardBack-${cardToReverse.card}`
      );
      elem.classList.add(
        `ModalEmotionCardPrimaryReversing_${cardToReverse.position}_${cardToReverse.card}`
      );
      elemReversed.classList.remove(
        `ModalEmotionCardPrimaryReversed_${cardToReverse.position}_${cardToReverse.card}`
      );
      elemReversed.classList.add(
        `ModalEmotionCardPrimaryReversedOn_${cardToReverse.position}_${cardToReverse.card}`
      );
    }
  }, [cardToReverse.count]);

  const handleCard = (card, position, reversingCard, timer) => {
    const primaryCardCondition =
      card.id !== Number(primaryCardsFound[primaryCardsFound.length - 1]);
    const reverseIt = () => {
      if (card !== null && primaryCardCondition) {
        const a = setTimeout(() => {}, 0);
        setTimeout(() => {
          clearInterval(a);
          dispatch({
            type: 'REVERSE_CARDS',
            payload: {
              position,
              card: reversingCard,
            },
          });
        }, timer);
      }
    };

    return (
      <>
        <div
          id={`card-${card.id}`}
          className={`ModalEmotionCardPrimary_${position}_${card.id}`}
          onClick={() => reverseIt()}
          role="presentation"
          onKeyDown={() => {}}
        >
          {card !== null && (
            <p
              className={
                primaryCardCondition
                  ? 'ModalPrimaryCardTitle'
                  : 'ModalPrimaryCardTitle2'
              }
            >
              {language === 'FR'
                ? card.title
                : language === 'ES'
                ? card.title_es
                : card.title_en}
            </p>
          )}
          {card !== null && primaryCardCondition && (
            <p className="ModalPrimaryCardDescription">
              {language === 'FR'
                ? card.message
                : language === 'ES'
                ? card.message_es
                : card.message_en}
            </p>
          )}
        </div>
        {card !== null && !primaryCardCondition && (
          <>
            <div className="path_container">
              <svg id="primary_derived" viewBox="0 0 786.33 91.97">
                {cardToReverse.count >= 1 && (
                  <path
                    className="path line1"
                    d="M317.56,2.42C276,44,2.42-3.89,2.42,89.46"
                  />
                )}
                {cardToReverse.count >= 2 && (
                  <path
                    className="path line2"
                    d="M368,6.76c0,63.64-104.39,27.4-104.62,82.7"
                  />
                )}
                {cardToReverse.count >= 3 && (
                  <path
                    className="path line3"
                    d="M418.36,6.76c0,63.64,104.39,27.4,104.63,82.7"
                  />
                )}
                {cardToReverse.count >= 4 && (
                  <path
                    className="path line4"
                    d="M468.76,2.42c41.59,41.59,315.15-6.31,315.15,87"
                  />
                )}
              </svg>
            </div>
            <div className="path_container path2">
              <svg viewBox="0 0 786.33 91.97">
                <path
                  className="path line1opacity"
                  d="M317.56,2.42C276,44,2.42-3.89,2.42,89.46"
                />
                <path
                  className="path line2opacity"
                  d="M368,6.76c0,63.64-104.39,27.4-104.62,82.7"
                />
                <path
                  className="path line3opacity"
                  d="M418.36,6.76c0,63.64,104.39,27.4,104.63,82.7"
                />
                <path
                  className="path line4opacity"
                  d="M468.76,2.42c41.59,41.59,315.15-6.31,315.15,87"
                />
              </svg>
            </div>
          </>
        )}
        {card !== null && primaryCardCondition && (
          <div
            id={`cardBack-${card.id}`}
            className={`ModalEmotionCardPrimaryReversed_${position}_${card.id}`}
          >
            {card !== null && (
              <>
                <p className="ModalPrimaryCardMainEmotion">
                  {language === 'FR'
                    ? card.main_emotion
                    : language === 'ES'
                    ? card.main_emotion_es
                    : card.main_emotion_en}
                </p>
                <p className="ModalPrimaryCardSecondaryEmotion">
                  {language === 'FR'
                    ? card.secondary_emotion
                    : language === 'ES'
                    ? card.secondary_emotion_es
                    : card.secondary_emotion_en}
                </p>
                <p className="ModalPrimaryCardEmotion">
                  {language === 'FR'
                    ? card.title
                    : language === 'ES'
                    ? card.title_es
                    : card.title_en}
                </p>
              </>
            )}
          </div>
        )}
      </>
    );
  };

  if (enabledCards) {
    const arrayCardFoundPrimary = [];
    [...new Set(primaryCardsFound)].map((cardFound) =>
      allMessages.map((allCard) => {
        return (
          Number(cardFound) === allCard.id &&
          arrayCardFoundPrimary.push(allCard)
        );
      })
    );
    return (
      <>
        <audio src={soundOn && success} autoPlay>
          <track kind="captions" />
        </audio>
        <audio src={game.returnSound && sound} autoPlay>
          <track kind="captions" />
        </audio>
        <div className="ModalEmotionCard_final">
          <div className="ModalEmotionCard_cards">
            <div className="ModalEmotionCard_cards_primary">
              {arrayCardFoundPrimary.map(
                (card) => card.id === typeCardId && handleCard(card, 'face')
              )}
            </div>
            <div className="ModalEmotionCard_cards_secondary">
              {allMessages.map(
                (card, index) =>
                  card.id !== typeCardId &&
                  handleCard(card, 'face', reversingCards[index], timers[index])
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="ModalEmotionCard_notfinal">
        {allMessages
          .filter((card) => card.id === typeCardId)
          .map((card) => handleCard(card, 'face'))}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  allMessages: state.prevention_messages.allMessages,
  language: state.GameUsers.language,
  primaryCardsFound: state.GameUsers.primaryCardsFound,
  cardsFound: state.prevention_messages.cardsFound,
  finalEmotions: state.prevention_messages.finalEmotions,
  modalCardFound: state.prevention_messages.modalCardFound,
  game: state.GameUsers,
  cardToReverse: state.GameUsers.cardToReverse,
});

ModalPrimaryCard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  allMessages: PropTypes.arrayOf({}).isRequired,
  language: PropTypes.string.isRequired,
  typeEmotion: PropTypes.string.isRequired,
  primaryCardsFound: PropTypes.arrayOf().isRequired,
  game: PropTypes.shape({
    sessionName: PropTypes.string.isRequired,
    returnSound: PropTypes.bool.isRequired,
  }).isRequired,
  cardToReverse: PropTypes.shape({
    position: PropTypes.string.isRequired,
    card: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(ModalPrimaryCard);
