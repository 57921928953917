import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TimeFormat from 'hh-mm-ss';
import './Dashboard.css';
import '../Modals.css';
import { connect } from 'react-redux';
import BubblesContent from '../UserBoard/BubblesContent.json';

const TimeUp = ({ paused, dispatch, game, computerStatus, ObjectStatus }) => {
  const [showTime, setShowTime] = useState(false);

  let mainTime;
  const [seconds, setSeconds] = useState(0);
  const stopTimer = () => {
    clearInterval(mainTime);
  };

  const tick = () => {
    // eslint-disable-next-line no-shadow
    setSeconds((seconds) => {
      const updatedSeconds = seconds + 1;
      if (updatedSeconds < 1) {
        stopTimer();
      }
      return updatedSeconds;
    });
  };

  const startTime = () => {
    if (seconds >= 0) {
      mainTime = setInterval(tick, 1000);
    }
  };

  useEffect(() => {
    if (!paused) {
      startTime();
    }
    return () => {
      stopTimer();
    };
  }, [paused]);

  const display = TimeFormat.fromS(seconds, 'hh:mm:ss');
  const [h, m, s] = display.split(':');
  const tiret = ':';
  const time = h + tiret + m + tiret + s;

  if (time === '00:00:01' && !game.bubbleDisplayed && !paused) {
    const { position, advice } = BubblesContent.letsgo;
    dispatch({
      type: 'SET_BUBBLE',
      payload: {
        position,
        advice,
      },
    });
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'bubbleDisplayed',
        action: true,
      },
    });
    setTimeout(() => {
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'bubbleDisplayed',
          action: false,
        },
      });
    }, 3000);
  }

  if (time === '00:00:04' && !game.bubbleDisplayed && !paused) {
    const { position, advice } = BubblesContent.confession;
    dispatch({
      type: 'SET_BUBBLE',
      payload: {
        position,
        advice,
      },
    });
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'bubbleDisplayed',
        action: true,
      },
    });
    setTimeout(() => {
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'bubbleDisplayed',
          action: false,
        },
      });
    }, 3000);
  }

  if (time === '00:00:07' && !game.bubbleDisplayed && !paused) {
    const { position, advice } = BubblesContent.weareteam;
    dispatch({
      type: 'SET_BUBBLE',
      payload: {
        position,
        advice,
      },
    });
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'bubbleDisplayed',
        action: true,
      },
    });
    setTimeout(() => {
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'bubbleDisplayed',
          action: false,
        },
      });
    }, 3000);
  }

  if (time === '00:00:15' && !game.bubbleDisplayed) {
    const { position, advice } = BubblesContent.clickObject;
    dispatch({
      type: 'SET_BUBBLE',
      payload: {
        position,
        advice,
      },
    });
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'bubbleDisplayed',
        action: true,
      },
    });
    setTimeout(() => {
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'bubbleDisplayed',
          action: false,
        },
      });
    }, 4000);
  }

  if (time === '00:00:45' && !game.bubbleDisplayed) {
    const { position, advice } = BubblesContent.clickEverywhere;
    dispatch({
      type: 'SET_BUBBLE',
      payload: {
        position,
        advice,
      },
    });
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'bubbleDisplayed',
        action: true,
      },
    });
    setTimeout(() => {
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'bubbleDisplayed',
          action: false,
        },
      });
    }, 3000);
  }

  if (
    time === '00:02:30' &&
    !game.bubbleDisplayed &&
    !ObjectStatus.hasElectricity
  ) {
    const { position, advice } = BubblesContent.plug;
    dispatch({
      type: 'SET_BUBBLE',
      payload: {
        position,
        advice,
      },
    });
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'bubbleDisplayed',
        action: true,
      },
    });
    setTimeout(() => {
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'bubbleDisplayed',
          action: false,
        },
      });
    }, 10000);
  }

  if (
    time === '00:03:00' &&
    !game.bubbleDisplayed &&
    !ObjectStatus.MugClicked
  ) {
    const { position, advice } = BubblesContent.mug;
    dispatch({
      type: 'SET_BUBBLE',
      payload: {
        position,
        advice,
      },
    });
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'bubbleDisplayed',
        action: true,
      },
    });
    setTimeout(() => {
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'bubbleDisplayed',
          action: false,
        },
      });
    }, 10000);
  }

  if (
    time === '00:03:30' &&
    !game.bubbleDisplayed &&
    !ObjectStatus.LetterClicked
  ) {
    const { position, advice } = BubblesContent.folder;
    dispatch({
      type: 'SET_BUBBLE',
      payload: {
        position,
        advice,
      },
    });
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'bubbleDisplayed',
        action: true,
      },
    });
    setTimeout(() => {
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'bubbleDisplayed',
          action: false,
        },
      });
    }, 10000);
  }

  if (
    (time === '00:04:00' || time === '00:05:00') &&
    !game.bubbleDisplayed &&
    ObjectStatus.hasElectricity &&
    !ObjectStatus.ComputerUnlocked
  ) {
    const { position, advice } = BubblesContent.password;
    dispatch({
      type: 'SET_BUBBLE',
      payload: {
        position,
        advice,
      },
    });
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'bubbleDisplayed',
        action: true,
      },
    });
    setTimeout(() => {
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'bubbleDisplayed',
          action: false,
        },
      });
    }, 10000);
  }

  if (
    (time === '00:06:30' || time === '00:07:30') &&
    !game.bubbleDisplayed &&
    !ObjectStatus.EmailFilled &&
    ObjectStatus.ComputerUnlocked
  ) {
    const { position, advice } = BubblesContent.organisation;
    dispatch({
      type: 'SET_BUBBLE',
      payload: {
        position,
        advice,
      },
    });
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'bubbleDisplayed',
        action: true,
      },
    });
    setTimeout(() => {
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'bubbleDisplayed',
          action: false,
        },
      });
    }, 10000);
  }

  if (
    (time === '00:05:30' || time === '00:07:30') &&
    !game.bubbleDisplayed &&
    !ObjectStatus.EmailFilled &&
    ObjectStatus.ComputerUnlocked
  ) {
    const { position, advice } = BubblesContent.visioconference;
    dispatch({
      type: 'SET_BUBBLE',
      payload: {
        position,
        advice,
      },
    });
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'bubbleDisplayed',
        action: true,
      },
    });
    setTimeout(() => {
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'bubbleDisplayed',
          action: false,
        },
      });
    }, 10000);
  }

  if (
    (time === '00:07:45' || time === '00:08:45') &&
    !game.bubbleDisplayed &&
    ObjectStatus.LetterOpen &&
    !computerStatus.status === 'visio'
  ) {
    const { position, advice } = BubblesContent.phoneNumber;
    dispatch({
      type: 'SET_BUBBLE',
      payload: {
        position,
        advice,
      },
    });
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'bubbleDisplayed',
        action: true,
      },
    });
    setTimeout(() => {
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'bubbleDisplayed',
          action: false,
        },
      });
    }, 10000);
  }

  if (
    (time === '00:09:00' || time === '00:10:00' || time === '00:12:00') &&
    !game.bubbleDisplayed &&
    computerStatus.status === 'visio'
  ) {
    const { position, advice } = BubblesContent.teampictures;
    dispatch({
      type: 'SET_BUBBLE',
      payload: {
        position,
        advice,
      },
    });
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'bubbleDisplayed',
        action: true,
      },
    });
    setTimeout(() => {
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'bubbleDisplayed',
          action: false,
        },
      });
    }, 10000);
  }

  useEffect(() => {
    if (game.gameOver) {
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'time',
          action: time,
        },
      });
    }
  }, [!game.gameOver && seconds, game.gameOver]);

  return (
    <>
      <p className={m > 55 ? 'TimerText10' : 'TimerText'}>
        {h} : {m} : {s}
      </p>
      <div className={showTime ? 'ModalTricky-Background' : 'Hide-Modal'}>
        <div className="ModalTricky-TimeEnd">
          <div className="ModalTricky-p">
            <p>Le temps est écoulé !</p>
          </div>
          <div>
            <button
              className="ButtonAction Action"
              type="button"
              onClick={() => setShowTime(false)}
            >
              Bien compris !
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  gameStarted: state.GameUsers.gameStarted,
  timerPause: state.GameUsers.timerPause,
  game: state.GameUsers,
  objects: state.Objects,
  computerStatus: state.computerStatus,
  ObjectStatus: state.Office.ObjectStatus,
});

TimeUp.propTypes = {
  paused: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  ObjectStatus: PropTypes.shape({
    ComputerUnlocked: PropTypes.bool.isRequired,
    EmailFilled: PropTypes.bool.isRequired,
    MugClicked: PropTypes.bool.isRequired,
    hasElectricity: PropTypes.bool.isRequired,
    LetterClicked: PropTypes.bool.isRequired,
    LetterOpen: PropTypes.bool.isRequired,
  }).isRequired,
  computerStatus: PropTypes.shape({
    status: PropTypes.string.isRequired,
    Modal: PropTypes.shape({
      emailModal: PropTypes.bool.isRequired,
      passwordModal: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  game: PropTypes.shape({
    bubbleDisplayed: PropTypes.bool.isRequired,
    gameStarted: PropTypes.bool.isRequired,
    gameOver: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(TimeUp);
