import React from 'react';
// import Header from '../Home/NavBar';
import DigitalRoomVideo from './DigitalRoomVideo';
import DigitalRoomSteps from './DigitalRoomSteps';
import About from './About';
import TheyTrustUs from './TheyTrustUs';

function LPDigitalRoom() {
  return (
    <div className="Home">
      <DigitalRoomVideo />
      <DigitalRoomSteps />
      <About />
      <TheyTrustUs />
    </div>
  );
}

export default LPDigitalRoom;
