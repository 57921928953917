import React, { useState } from 'react';
import axios from 'axios';

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleChange = (e) => {
    if (e.target.id === 'name') {
      setName(e.target.value);
    } else {
      setEmail(e.target.value);
    }
  };

  const handleSubmit = () => {
    const dataToSubmit = {
      name,
      email,
    };

    axios.post('http://localhost:3000/sendmail', dataToSubmit);
  };
  return (
    <div>
      <input
        name="email"
        id="email"
        type="email"
        placeholder="Enter email"
        value={email}
        onChange={(e) => handleChange(e)}
      />
      <p>Well never share your email with anyone else.</p>

      <input
        name="name"
        id="name"
        type="Name"
        placeholder="Name"
        value={name}
        onChange={(e) => handleChange(e)}
      />
      <button type="button" onClick={() => handleSubmit()}>
        Envoyer
      </button>
    </div>
  );
}
export default Contact;
