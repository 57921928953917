import React from 'react';
import PropTypes from 'prop-types';

import InfoResponse from './InfoResponse';
import Button from '../Reusable/Buttons/Button';

import '../../styles/css/components/ConvertionTunnel/Card.css';

const Card = ({
  response,
  step,
  handleChoice,
  choice,
  info,
  handleActiveButton,
  setInfo,
  setActive,
  isActive,
}) => {
  const handleButton = (content) => {
    setActive(!isActive);
    setTimeout(() => {
      setInfo(content);
    }, 400);
  };
  return (
    <div className="Card">
      <div className="Card_action">
        <button
          type="button"
          name={response.name}
          className={handleActiveButton(response)}
          onClick={() => handleChoice(step, response)}
        >
          <img
            className="Card_action_image"
            alt={`Response ${response.name}`}
            src={response.image}
          />
          <div className="Card_action_content">
            <div className="Card_action_title">{response.title}</div>
            <div className="Card_action_description">
              {response.description}
            </div>
            {choice === response.name && response.subDescription && (
              <div className="Card_action_plus">
                {info !== null ? (
                  <Button title="Retour" onClick={() => handleButton(null)} />
                ) : (
                  <Button
                    title="En savoir +"
                    onClick={() => handleButton(response.name)}
                  />
                )}
              </div>
            )}
          </div>
        </button>
      </div>
      {info === response.name && <InfoResponse response={response} />}
    </div>
  );
};
Card.propTypes = {
  response: PropTypes.shape({
    name: PropTypes.string.isRequired,
    subDescription: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
  handleChoice: PropTypes.func.isRequired,
  step: PropTypes.shape({}).isRequired,
  choice: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  handleActiveButton: PropTypes.func.isRequired,
  setInfo: PropTypes.func.isRequired,
  setActive: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default Card;
