import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/css/components/ConvertionTunnel/InforResponse.css';

const InfoResponse = ({ response }) => {
  return (
    <div className="InfoResponse">
      <p>{response.subDescription}</p>
      {response.goals && (
        <div className="InfoResponse_goals">
          <p className="InfoResponse_goals_title">
            <span role="img" aria-label="objectifs">
              🏆
            </span>
            Objectifs
          </p>
          {response.goals.map((goal) => (
            <p className="InfoResponse_goal">{goal}</p>
          ))}
        </div>
      )}
    </div>
  );
};

InfoResponse.propTypes = {
  response: PropTypes.shape({
    subDescription: PropTypes.string.isRequired,
    goals: PropTypes.arrayOf([]).isRequired,
  }).isRequired,
};

export default InfoResponse;
