import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import succes from '../../../sound/successhorns1.mp3';
import ScoreboardItem from './ScoreboardItem';

const MyScoreboard = ({ GameUsers }) => {
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    if (GameUsers.rankingDatas.length > 0) {
      setPlayers(GameUsers.rankingDatas);
    }
  }, [GameUsers.rankingDatas.length]);

  const rankPlayer = players
    .filter((item) => item.email === GameUsers.email)
    .map((item) => item.rank)[0];

  let rankPlayerStart = 1;
  let rankPlayerEnd = 1;

  if (rankPlayer - 3 < 0) {
    rankPlayerStart = 0;
  } else if (rankPlayer - 3 > 0 && rankPlayer + 2 > players.length) {
    rankPlayerStart = rankPlayer - (6 - (players.length - rankPlayer));
  } else {
    rankPlayerStart = rankPlayer - 3;
  }

  if (rankPlayer + 2 > players.length) {
    rankPlayerEnd = players.length;
  } else if (rankPlayer - 3 < 0 && rankPlayer + 2 < players.length) {
    rankPlayerEnd = rankPlayer + (6 - rankPlayer);
  } else {
    rankPlayerEnd = rankPlayer + 2;
  }

  return (
    <>
      <audio src={succes} autoPlay>
        <track kind="captions" />
      </audio>
      <div className="Show">
        {players.length !== 0 &&
          players
            .slice(rankPlayerStart, rankPlayerEnd)
            .map((item, index) => (
              <ScoreboardItem
                key={item.id}
                id={index}
                style={item.email === GameUsers.email}
                rank={item.rank}
                pseudo={item.pseudo}
                count={item.count}
                time={item.time}
              />
            ))}
        <Link className="Dashboard-Preco" to="/digital-room">
          <button type="button" className="ButtonAction Action">
            Quitter le jeu
          </button>
        </Link>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  GameUsers: state.GameUsers,
});

MyScoreboard.propTypes = {
  GameUsers: PropTypes.shape({
    email: PropTypes.string.isRequired,
    scoreboard: PropTypes.bool.isRequired,
    rankingDatas: PropTypes.arrayOf([]).isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(MyScoreboard);
