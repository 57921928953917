/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import trickyHead from '../../../styles/img/tricky-head-white.svg';
import './ModalEmotionCard.css';

const ModalEmotionCard = ({
  typeEmotion,
  allMessages,
  language,
  cardsFound,
  finalEmotions,
}) => {
  const typeCardId = Number(typeEmotion);
  const enabledCards = finalEmotions.includes(typeEmotion);

  const handleCard = (card, position, description, id) => {
    if (id && position === 'back') {
      return (
        <div
          className={`ModalEmotionCard_card_${
            enabledCards ? 'final' : 'notfinal'
          } ModalEmotionCard_card_${position}_${id} TrickyHead`}
        >
          <img src={trickyHead} alt="Tricky Head" />
        </div>
      );
    }

    return (
      <>
        <div
          className={`ModalEmotionCard_card_notfinal ModalEmotionCard_card_${position}_${
            card !== null ? card.id : 'not_found'
          }`}
        >
          {card !== null && (
            <p className="ModalEmotionCard_card_title_notfinal">
              {language === 'FR'
                ? card.title
                : language === 'ES'
                ? card.title_es
                : card.title_en}
            </p>
          )}
          {card !== null && (
            <p className="ModalEmotionCard_card_description">
              {language === 'FR'
                ? card.message
                : language === 'ES'
                ? card.message_es
                : card.message_en}
            </p>
          )}
        </div>
      </>
    );
  };

  if (!typeEmotion) {
    const arrayCardFound = [];
    cardsFound.map((cardFound) =>
      allMessages.map(
        (allCard) =>
          Number(cardFound) === allCard.id && arrayCardFound.push(allCard)
      )
    );
    const numberCardPrimary = 1;
    const arrayCardNotFound = [
      ...Array(
        allMessages.length - cardsFound.length - numberCardPrimary
      ).keys(),
    ];

    return (
      <div className="ModalEmotionCard_final">
        <div className="ModalEmotionCard_cards">
          <div className="ModalEmotionCard_cards_primary">
            <div className="ModalEmotionCard_card_notfinal ModalEmotionCard_card_back_not_found TrickyHeadPrimary">
              <img src={trickyHead} alt="Tricky Head" />
            </div>
          </div>
          <div className="ModalEmotionCard_cards_secondary">
            {arrayCardFound.map((card) =>
              handleCard(card, 'face', 'no-description')
            )}
            {arrayCardNotFound.map((card, index) =>
              handleCard(
                null,
                'back',
                'no-description',
                index + cardsFound.length + numberCardPrimary
              )
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="ModalEmotionCard_notfinal">
        {allMessages
          .filter((card) => card.id === typeCardId)
          .map((card) => handleCard(card, 'face'))}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  allMessages: state.prevention_messages.allMessages,
  language: state.GameUsers.language,
  primaryCardsFound: state.GameUsers.primaryCardsFound,
  cardsFound: state.GameUsers.cardsFound,
  finalEmotions: state.GameUsers.finalEmotions,
  modalCardFound: state.GameUsers.modalCardFound,
});

ModalEmotionCard.propTypes = {
  allMessages: PropTypes.arrayOf({}).isRequired,
  language: PropTypes.string.isRequired,
  typeEmotion: PropTypes.string.isRequired,
  cardsFound: PropTypes.arrayOf().isRequired,
  finalEmotions: PropTypes.arrayOf().isRequired,
};

export default connect(mapStateToProps)(ModalEmotionCard);
