import React from 'react';
import { Link } from 'react-router-dom';
import './LPDigitalRoom.css';

const DigitalRoomSteps = () => {
  return (
    <div className="DigitalRoomVideo">
      <div className="DigitalRoomVideo-Title">
        <h2>Comment ça marche ?</h2>
      </div>
      <div className="DigitalRoomVideo-Description">
        <p>
          Notre mission est de contribuer à prévenir des épuisements physiques
          et psychologiques provoqués directement ou indirectement par le
          télétravail et la situation sanitaire actuelle. L’expérience immersive
          produite par Tricky est positive, collaborative, non culpabilisante.
        </p>
      </div>
      <div className="DigitalRoomVideo-Howitworks">
        <div className="DigitalRoom-AllSteps">
          <div className="DigitalRoom-Step">
            <div className="DigitalRoomVideo-DivNumber">
              <div className="DigitalRoomVideo-Number">1</div>
            </div>
            <div className="DigitalRoom-Text">
              <h3>Réservation et connexion</h3>
              <p>
                Vous réservez une salle de jeu digitale avec un psychologue pour
                un groupe de 4 personnes.
                <br />
                Les participants remplissent un questionnaire d’évaluation des
                connaissance puis se connectent.
              </p>
            </div>
          </div>
          <div className="DigitalRoom-Step">
            <div className="DigitalRoomVideo-DivNumber">
              <div className="DigitalRoomVideo-Number">2</div>
            </div>
            <div className="DigitalRoom-Text">
              <h3>1h de serious game en équipe</h3>
              <p>
                Vous avez 1h pour résoudre l’ensemble des d’énigmes sur la
                thématique de la gestion des émotions en situation de
                télétravail.
                <br />
                Faites preuve d’esprit d’équipe pour atteindre pour trouver les
                cartes émotions cachées dans le jeu.
              </p>
            </div>
          </div>
          <div className="DigitalRoom-Step">
            <div className="DigitalRoomVideo-DivNumber">
              <div className="DigitalRoomVideo-Number">3</div>
            </div>
            <div className="DigitalRoom-Text">
              <h3>45 minutes de débriefing</h3>
              <p>
                Cette session en focus-groupe, sous format visioconférence, est
                réalisée avec un psychologue ou professionnel de santé. L’espace
                de débriefing est l’occasion de discuter sans filtre ni tabou
                des messages de prévention auxquels les participants ont été
                exposés.
              </p>
            </div>
          </div>
          <div className="DigitalRoom-Step">
            <div className="DigitalRoomVideo-DivNumber">
              <div className="DigitalRoomVideo-Number">4</div>
            </div>
            <div className="DigitalRoom-Text">
              <h3>évaluation et rapport anonymisé</h3>
              <p>
                Les progrès des joueurs et acquisitions de connaissances sont
                évalués tout au long du jeu.
                <br />
                Un rapport de synthèse d’acquisitions de connaissances et de
                suivi du changement du comportement est envoyé, conformément à
                la réglementation RGPD.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="DigitalRoomVideo-Button">
        <Link to="/digital-room-game" target="_blank">
          <button type="button">
            Accéder à la démo{' '}
            <span className="fas fa-chevron-right" title="Accéder à la démo" />
          </button>
        </Link>
      </div>
    </div>
  );
};

export default DigitalRoomSteps;
