import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import '../../../styles/css/components/Reusable/Buttons/Button.css';

const Button = ({ title, url, type, onClick }) => {
  return (
    <Link to={url} className={`Button Button-${type}`}>
      <button onClick={onClick} type="button">
        {title}
        <div className="Button_line" />
      </button>
    </Link>
  );
};

Button.defaultProps = {
  type: 'normal',
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default Button;
