import React from 'react';
import './App.css';
import { Route } from 'react-router-dom';
import Contact from './components/Contact/Contact';
// import Home from './components/Home/Home';
import LPDigitalRoom from './components/LPDigitalRoom/LPDigitalRoom';
import Tunnel from './components/Tunnel/Tunnel';
import Game from './components/Game/Game';
import GameDemoMulti from './components/GameDemoMulti/Game';
import Univers from './components/ConversionTunnel/Univers';

function App() {
  return (
    <div className="App">
      <Route exact path="/" component={GameDemoMulti} />
      <Route path="/contact" component={Contact} />
      <Route path="/digital-room" component={LPDigitalRoom} />
      <Route path="/tunnel" component={Tunnel} />
      <Route path="/digital-room-game" component={Game} />
      <Route path="/demo-digital-room-game" component={GameDemoMulti} />
      <Route path="/conversion/:numberStep" component={Univers} />
    </div>
  );
}

export default App;
