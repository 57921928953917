import React, { useState } from 'react';
import './Dashboard.css';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ObjectList from './ObjectList';
import TurnCounter from './TurnCounter';
import Timer from './Timer';
import Volume from '../../../img/volume-up-solid-white.svg';
import VolumeMute from '../../../img/volume-mute-solid-white.svg';

const Dashboard = ({ dispatch, GameUsers }) => {
  const [muteSound, setMuteSound] = useState(true);

  const handleMuteSoundtrack = () => {
    setMuteSound(!muteSound);
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'soundMuted',
        action: muteSound,
      },
    });
  };

  return (
    <div className="Dashboard-container">
      <Link className="Dashboard-Preco" to="/digital-room">
        <button type="button" className="ButtonAction Action">
          Précommander
        </button>
      </Link>
      <h2 className="Object-title">Inventaire</h2>
      <ObjectList />
      <TurnCounter />
      <Timer />
      <div
        onClick={handleMuteSoundtrack}
        onKeyDown={handleMuteSoundtrack}
        role="button"
        tabIndex={0}
        className={GameUsers.gameStarted ? 'Sound-Game' : 'Hide'}
      >
        <p>{GameUsers.soundMuted ? 'Activer le son' : 'Couper le son'}</p>
        <img
          src={GameUsers.soundMuted ? VolumeMute : Volume}
          alt={GameUsers.soundMuted ? 'Activer le son' : 'Couper le son'}
          title={GameUsers.soundMuted ? 'Activer le son' : 'Couper le son'}
          draggable="false"
        />
      </div>
      <Link className="Dashboard-p" to="/digital-room">
        <p>Quitter le jeu</p>
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => ({
  GameUsers: state.GameUsers,
});

Dashboard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  GameUsers: PropTypes.shape({
    soundMuted: PropTypes.bool.isRequired,
    gameStarted: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(Dashboard);
