const initialState = {
  count1: 0,
  count2: 0,
  count3: 0,
  count4: 0,
  boardSucceed: false,
};

const boardCounter = (state = initialState, action) => {
  switch (action.type) {
    case 'INCREMENT_BOARD_COUNTER1':
      return {
        ...state,
        count1: state.count1 + 1,
      };
    case 'INCREMENT_BOARD_COUNTER2':
      return {
        ...state,
        count2: state.count2 + 1,
      };
    case 'INCREMENT_BOARD_COUNTER3':
      return {
        ...state,
        count3: state.count3 + 1,
      };
    case 'INCREMENT_BOARD_COUNTER4':
      return {
        ...state,
        count4: state.count4 + 1,
      };
    case 'RESET_BOARD_COUNTER1':
      return {
        ...state,
        count1: 0,
      };
    case 'RESET_BOARD_COUNTER2':
      return {
        ...state,
        count2: 0,
      };
    case 'RESET_BOARD_COUNTER3':
      return {
        ...state,
        count3: 0,
      };
    case 'RESET_BOARD_COUNTER4':
      return {
        ...state,
        count4: 0,
      };
    case 'BOARD_SUCCESS':
      return {
        ...state,
        boardSucceed: action.payload,
      };
    default:
      return state;
  }
};

export default boardCounter;
