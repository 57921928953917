import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useSound from 'use-sound';
import styled, { keyframes } from 'styled-components';
import { zoomIn, tada } from 'react-animations';
import './Bureau.css';
import './Modals.css';
import phoneOn from '../../styles/img/Game/phone_noButtons_ON-01.svg';
import ComputerVisio from '../../styles/img/Game/Ordi_Visio_CROPPED.svg';
import {
  addNumberPhone,
  removeLastNumberOfPhone,
  resetPhoneNumber,
  showMessage,
  counterClick,
} from '../../actions/index';
import success from '../../sound/Success.mp3';
import PreventionMessages from './PreventionMessages';
import error from '../../sound/Error.mp3';
import agressivite from '../../styles/sound/board_agressivite_fr.mp3';
import keyboardTyping from '../../sound/keyboard-typing.mp3';
import phonetyping from '../../sound/phonetyping.mp3';
import BubblesContent from './UserBoard/BubblesContent.json';

const phoneNumber = '0557575757';
const arrayButtonsPhone = [1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0];

const zoomInAnim = keyframes`${zoomIn}`;
const tadaAnim = keyframes`${tada}`;

const ZoomInDiv = styled.div`
  animation: 0.5s ${zoomInAnim};
`;

const TadaDiv = styled.div`
  animation: 1s ${tadaAnim};
`;

const Phone = ({
  phoneStatus,
  computerStatus,
  Modal,
  dispatch,
  ModalBureau,
}) => {
  const [playSuccess] = useSound(success);
  const [playError] = useSound(error);
  const [playCall] = useSound(phonetyping);
  const [playType] = useSound(keyboardTyping);
  const emotionAgressiviteId = '2';

  const handleButtonPhone = (id) => {
    return (
      <div className="phone_button">
        <button
          className="inner-phone-button"
          type="button"
          onClick={() => {
            dispatch(addNumberPhone(id));
            playType();
          }}
        >
          {id}
        </button>
      </div>
    );
  };

  const handleModal = () => {
    if (phoneStatus.status === 'phone_off') {
      dispatch({
        type: 'SHOW_MODAL_PHONE',
        payload: {
          modalType: 'modalNoPower',
          showModal: true,
        },
      });
      dispatch(
        showMessage({
          title: 'Informations',
          message: 'le téléphone est éteint',
          show: true,
        })
      );
    }
    if (
      phoneStatus.status === 'phone_on' &&
      computerStatus.status !== 'visio'
    ) {
      dispatch({
        type: 'SHOW_MODAL_PHONE',
        payload: {
          modalType: 'modalPower',
          showModal: true,
        },
      });
    }
  };

  const submitNumber = () => {
    if (phoneStatus.phoneNumber.join('') === phoneNumber) {
      const { position, advice } = BubblesContent.bravo2;
      dispatch({
        type: 'SET_BUBBLE',
        payload: {
          position,
          advice,
        },
      });
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'bubbleDisplayed',
          action: true,
        },
      });
      setTimeout(() => {
        dispatch({
          type: 'GAME_ACTION',
          payload: {
            typeOfAction: 'bubbleDisplayed',
            action: false,
          },
        });
      }, 2000);
      dispatch({
        type: 'SHOW_MODAL_PHONE',
        payload: {
          modalType: 'modalPower',
          showModal: false,
        },
      });
      playCall();
      setTimeout(() => {
        dispatch({
          type: 'SHOW_MODAL_PHONE',
          payload: {
            modalType: 'modalGoodNumber',
            showModal: true,
          },
        });
        playSuccess();
        const { position2, advice2 } = BubblesContent.visio;
        dispatch({
          type: 'SET_BUBBLE',
          payload: {
            position: position2,
            advice: advice2,
          },
        });
        dispatch({
          type: 'GAME_ACTION',
          payload: {
            typeOfAction: 'bubbleDisplayed',
            action: true,
          },
        });
        setTimeout(() => {
          dispatch({
            type: 'GAME_ACTION',
            payload: {
              typeOfAction: 'bubbleDisplayed',
              action: false,
            },
          });
        }, 3000);
      }, 2200);
      dispatch(counterClick());
    } else {
      dispatch({
        type: 'SHOW_MODAL_PHONE',
        payload: {
          modalType: 'modalPower',
          showModal: false,
        },
      });
      dispatch({
        type: 'SHOW_MODAL_PHONE',
        payload: {
          modalType: 'modalWrongNumber',
          showModal: true,
        },
      });
      dispatch(resetPhoneNumber());
      dispatch(
        showMessage({
          title: 'Informations',
          message: "Le numéro composé n'est pas attribué.",
          show: true,
        })
      );
      dispatch(counterClick());
      playError();
    }
  };

  const enterVisio = () => {
    dispatch({
      type: 'COMPUTER_STATUS',
      payload: {
        image: ComputerVisio,
        status: 'visio',
      },
    });
    dispatch({
      type: 'SHOW_MODAL_PHONE',
      payload: {
        modalType: 'modalGoodNumber',
        showModal: false,
      },
    });
    dispatch({
      type: 'SHOW_MODAL_PREVENTION',
      payload: {
        roomType: 'ModalBureau',
        modalType: 'ModalAgressivite',
        showModal: true,
      },
    });
    dispatch({
      type: 'FOUND_EMOTION',
      payload: emotionAgressiviteId,
    });
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'timerPause',
        action: true,
      },
    });
    setTimeout(() => {
      dispatch({
        type: 'SHOW_MODAL_PREVENTION',
        payload: {
          roomType: 'ModalBureau',
          modalType: 'ModalAgressivite',
          showModal: false,
        },
      });
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'timerPause',
          action: false,
        },
      });
    }, 26000);
    dispatch(resetPhoneNumber());
  };

  return (
    <>
      <div
        role="button"
        className="phone"
        onClick={handleModal}
        onKeyDown={handleModal}
        tabIndex={0}
      >
        <img
          src={phoneStatus.image}
          className="layer"
          data-speed="6"
          alt="phone"
          draggable="false"
        />
      </div>
      <div
        className={
          Modal.modalPower
            ? 'ModalTricky-Background'
            : 'ModalTricky-Background Hide-Modal'
        }
      >
        <div className="ModalTricky-Container">
          <div className="ModalTricky-Phone-Number">
            <form onSubmit={submitNumber}>
              <ZoomInDiv>
                <img src={phoneOn} alt="telephone" draggable="false" />
                <input
                  className="phone-number-input"
                  type="text"
                  name="number"
                  value={phoneStatus.phoneNumber.join('')}
                />
                <div className="container_pad">
                  {arrayButtonsPhone.map((index) => handleButtonPhone(index))}
                  <div className="phone_button">
                    <button
                      className="inner-phone-button"
                      type="button"
                      onClick={() => {
                        dispatch(removeLastNumberOfPhone());
                      }}
                    >
                      ⬅︎
                    </button>
                  </div>
                </div>
                <div className="Buttons">
                  <button
                    className="ButtonAction Action"
                    type="button"
                    onClick={() => submitNumber()}
                  >
                    Appeler
                  </button>
                  <button
                    className="ButtonAction Cancel"
                    type="button"
                    onClick={() => {
                      dispatch({
                        type: 'SHOW_MODAL_PHONE',
                        payload: {
                          modalType: 'modalPower',
                          showModal: false,
                        },
                      });
                      dispatch(resetPhoneNumber());
                    }}
                  >
                    Annuler
                  </button>
                </div>
              </ZoomInDiv>
            </form>
          </div>
        </div>
      </div>
      <div
        className={
          Modal.modalGoodNumber
            ? 'ModalTricky-Background'
            : 'ModalTricky-Background Hide-Modal'
        }
      >
        <TadaDiv className="ModalTricky-Container">
          <div className="ModalTricky-p2">
            <div className="ModalTricky-Phone-Number-Success">
              <p className="Success">
                Il est 9h, vous entrez en visio conférence.
              </p>
              <button
                className="ButtonAction Action"
                type="button"
                onClick={enterVisio}
              >
                Entrer en Visio
              </button>
            </div>
          </div>
        </TadaDiv>
      </div>
      {ModalBureau.ModalAgressivite && (
        <PreventionMessages
          sound={agressivite}
          typeEmotion={emotionAgressiviteId}
          endTimer={25000}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  phoneStatus: state.phoneStatus,
  computerStatus: state.computerStatus,
  Messages: state.prevention_messages.allMessages,
  Modal: state.phoneStatus.Modal,
  ModalBureau: state.prevention_messages.ModalBureau,
});

Phone.propTypes = {
  phoneStatus: PropTypes.shape({
    image: PropTypes.string,
    status: PropTypes.string,
    phoneNumber: PropTypes.arrayOf([]),
    Modal: PropTypes.shape({
      modalNoPower: PropTypes.bool,
      modalClosedLetter: PropTypes.bool,
      modalPower: PropTypes.bool,
      modalWrongNumber: PropTypes.bool,
      modalGoodNumber: PropTypes.bool,
    }),
  }).isRequired,
  ModalBureau: PropTypes.shape({
    ModalAgressivite: PropTypes.bool,
  }).isRequired,
  computerStatus: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  Modal: PropTypes.shape({
    modalNoPower: PropTypes.bool,
    modalClosedLetter: PropTypes.bool,
    modalPower: PropTypes.bool,
    modalWrongNumber: PropTypes.bool,
    modalGoodNumber: PropTypes.bool,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(Phone);
