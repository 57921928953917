import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ReactJoyride from 'react-joyride';
import { connect } from 'react-redux';
import Steps from './Tutorial/Tutorial';
import fetchMessage, { fetchObject } from '../../actions';
import Bureau from './Bureau';
import Dashboard from './DashBoard/DashBoard';
import UserBoard from './UserBoard/UserBoard';
import useMousePosition from './useMousePosition';
import './GameBoard.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Game = ({ dispatch, MouseClicked, Count, Modal }) => {
  const { x, y } = useMousePosition();
  useEffect(() => {
    dispatch({ type: 'CLICKED_MOUSE_DOWN' });
    setTimeout(() => {
      dispatch({ type: 'CLICKED_MOUSE_UP' });
    }, 1000);
  }, [Count]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/rooms/1/messages`)
      .then((res) => {
        dispatch(fetchMessage(res.data));
      })
      .catch((error) => {
        throw error;
      });

    axios
      .get(`${BASE_URL}/api/objects`)
      .then((res) => {
        dispatch(fetchObject(res.data));
      })
      .catch((error) => {
        throw error;
      });
  }, []);

  const handleClose = (data) => {
    const { action } = data;
    if (action === 'reset' || action === 'close') {
      dispatch({ type: 'SHOW_MODAL_TUTO', payload: false });
      dispatch({ type: 'SHOW_MODAL_INTRO', payload: true });
    }
  };

  return (
    <>
      <div
        className={MouseClicked ? 'cursor-clicked' : 'cursor'}
        style={{ top: y, left: x }}
      >
        <p>+1</p>
      </div>
      <div className="GameWindow-DemoMulti">
        <div className="GameWindow-Boards">
          <UserBoard />
          <Bureau />
          <Dashboard />
        </div>
      </div>
      {Modal.ModalTutorial && (
        <ReactJoyride
          callback={handleClose}
          showSkipButton
          continuous
          disableCloseOnEsc
          disableOverlayClose
          disableScrolling
          hideBackButton
          steps={Steps}
          locale={{
            last: 'Terminer',
            skip: 'Ignorer',
            next: 'Suivant',
          }}
          styles={{
            options: {
              overlayColor: 'rgba(0,0,0, 0.7',
              arrowColor: '#fff',
            },
            buttonNext: {
              backgroundColor: '#7a00ff',
              border: '1px solid #270051',
              padding: '15px 25px',
            },
            buttonBack: {
              marginRight: 10,
              color: '#000',
            },
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  Room: state.Room,
  game: state.GameUsers,
  MouseClicked: state.GameUsers.clicked,
  Count: state.GameUsers.count,
  Modal: state.Objects.Modal,
});

Game.propTypes = {
  dispatch: PropTypes.func.isRequired,
  game: PropTypes.shape({
    count: PropTypes.number,
    currentPlayer: PropTypes.arrayOf(PropTypes.object),
    players: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  Count: PropTypes.number.isRequired,
  MouseClicked: PropTypes.bool.isRequired,
  Modal: PropTypes.shape({
    ModalTutorial: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(Game);
