import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Congratulation.css';
import './Scoreboard.css';
import { connect } from 'react-redux';
import ScoreboardTop10 from './ScoreboardTop10';
import ScoreboardByShots from './ScoreboardByShots';
import MyScoreboard from './MyScoreboard';

const Scoreboard = ({ GameUsers }) => {
  const [byShots, setByshots] = useState(false);
  const [byTime, setByTime] = useState(false);
  const [myScore, setmyScore] = useState(true);
  const [TopTen, setTopTen] = useState(false);

  const handleClickByShots = () => {
    setByshots(true);
    setByTime(false);
    setmyScore(false);
    setTopTen(false);
  };

  const handleClickByTime = () => {
    setByshots(false);
    setByTime(true);
    setmyScore(false);
    setTopTen(true);
  };

  const handleTop10 = () => {
    setTopTen(true);
    setByTime(true);
    setmyScore(false);
  };

  const handleMyTop = () => {
    setmyScore(true);
    setTopTen(false);
    setByTime(false);
    setByshots(false);
  };

  return (
    <>
      <div
        className={
          GameUsers.scoreboard ? 'ModalTricky-Background' : 'Hide-Modal'
        }
      >
        <div className="ModalTricky-Container">
          <div className="ModalTricky-Bravo">
            <h2>TABLEAU DES SCORES{!myScore && ' #10'}</h2>
          </div>
          <div className="ModalTricky-Scoreboard-Container">
            <div className="ModalTricky-Scoreboard-info">
              <div className="col-10">Rang</div>
              <div className="col-50">Joueurs</div>
              {!myScore ? (
                <>
                  <div
                    className={`col-20 ${byTime ? '' : 'score-selected'}`}
                    onClick={handleClickByShots}
                    onKeyDown={handleClickByShots}
                    tabIndex={0}
                    role="button"
                  >
                    Coups joués
                  </div>
                  <div
                    className={`col-20 ${byShots ? '' : 'score-selected'}`}
                    onClick={handleClickByTime}
                    onKeyDown={handleClickByTime}
                    tabIndex={0}
                    role="button"
                  >
                    Temps
                  </div>
                </>
              ) : (
                <>
                  <div className="col-20">Coups joués</div>
                  <div className="col-20">Temps</div>
                </>
              )}
            </div>
            <ScoreboardTop10 status={TopTen} />
            <ScoreboardByShots status={byShots} />
            <MyScoreboard status={myScore} />
          </div>
          {!TopTen && myScore && (
            <div>
              <button
                className="ButtonAction Action"
                type="button"
                onClick={handleTop10}
              >
                Afficher le Top 10
              </button>
            </div>
          )}
          {!myScore && (
            <div>
              <button
                className="ButtonAction Action"
                type="button"
                onClick={handleMyTop}
              >
                Revenir
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  GameUsers: state.GameUsers,
});

Scoreboard.propTypes = {
  GameUsers: PropTypes.shape({
    scoreboard: PropTypes.bool.isRequired,
    scoreboard10: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(Scoreboard);
