/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable prefer-object-spread */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import confetti from 'canvas-confetti';
import succes from '../../../sound/successhorns1.mp3';
import ScoreboardItem from './ScoreboardItem';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const MyScoreboard = ({ GameUsers, status }) => {
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/scores/allscores`)
      .then((response) => response.data)
      .then((data) => setPlayers(data));
  }, [players]);

  const rankPlayer = players
    .filter((item) => item.email === GameUsers.user.email)
    .map((item) => item.rank)[0];

  let rankPlayerStart = 1;
  let rankPlayerEnd = 1;

  if (rankPlayer - 5 < 0) {
    rankPlayerStart = 0;
  } else if (rankPlayer - 5 > 0 && rankPlayer + 4 > players.length) {
    rankPlayerStart = rankPlayer - (10 - (players.length - rankPlayer));
  } else {
    rankPlayerStart = rankPlayer - 5;
  }

  if (rankPlayer + 4 > players.length) {
    rankPlayerEnd = players.length;
  } else if (rankPlayer - 5 < 0 && rankPlayer + 4 < players.length) {
    rankPlayerEnd = rankPlayer + (10 - rankPlayer);
  } else {
    rankPlayerEnd = rankPlayer + 4;
  }

  return (
    <>
      <audio src={succes} autoPlay>
        <track kind="captions" />
      </audio>
      <div className={status ? 'Show' : 'Hide'}>
        {players.length !== 0 &&
          players
            .slice(rankPlayerStart, rankPlayerEnd)
            .map((item, index) => (
              <ScoreboardItem
                key={item.id}
                id={index}
                style={item.email === GameUsers.user.email}
                rank={item.rank}
                pseudo={item.pseudo}
                count={item.count}
                time={item.time}
              />
            ))}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  GameUsers: state.GameUsers,
});

MyScoreboard.propTypes = {
  GameUsers: PropTypes.shape({
    user: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }),
  }).isRequired,
  status: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(MyScoreboard);
