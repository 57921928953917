import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Sky from 'react-sky';

import FinalStep from './FinalStep';
import Step from './Step';
import Card from './Card';

import listOfSteps from '../../data/tunnel';

import '../../styles/css/components/ConvertionTunnel/ConversionTunnel.css';

import vousColor from '../../styles/img/company_color.svg';
import nousColor from '../../styles/img/tricky_house_color.svg';

const ConversionTunnel = ({ params, history }) => {
  const { numberStep } = params;
  const [redirectTo, setRedirectTo] = useState(false);
  const [isActive, setActive] = useState(false);
  const [isFinalPage, setIsFinalPage] = useState(false);
  const [choice, setChoice] = useState(null);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    const pageNotExist = '0';
    if (numberStep === pageNotExist) {
      setRedirectTo(!redirectTo);
    }
    if (numberStep === '5') {
      setIsFinalPage(!isFinalPage);
    } else {
      setIsFinalPage(false);
    }
  }, [numberStep]);

  useEffect(() => {
    setTimeout(() => {
      setActive(!isActive);
    }, 400);
  }, []);

  useEffect(() => {
    if (!isActive) {
      setTimeout(() => {
        setActive(!isActive);
      }, 400);
    }
  }, [isActive]);

  const handleSteps = (arrayStep) => {
    return arrayStep.map((step, index) => {
      const indexOfStep = index + 1;
      return (
        <Step
          step={step}
          indexOfStep={indexOfStep}
          numberStep={numberStep}
          history={history}
          setActive={setActive}
          isActive={isActive}
        />
      );
    });
  };

  const handleResponseType = (response) => {
    if (response.type) {
      return response.type === localStorage.getItem('typeProfil');
    }

    return !response.type;
  };

  const handleActiveButton = (response) => {
    if (choice === response.name) {
      return 'Card_action_response Card_action_response_active';
    }

    if (info !== null && info !== response.name) {
      return 'Card_action_response Card_action_response_disabled';
    }
    return 'Card_action_response Card_action_response_no-active';
  };

  const handleAnimationQuestionPrev = () => {
    setActive(!isActive);
    setInfo(null);
    setTimeout(() => {
      history.push(`/conversion/${Number(numberStep) - 1}`);
    }, 400);
  };

  const handleAnimationQuestionNext = () => {
    setActive(!isActive);
    setInfo(null);
    if (isFinalPage) {
      setTimeout(() => {
        history.push('/');
      }, 400);
    } else {
      setTimeout(() => {
        history.push(`/conversion/${Number(numberStep) + 1}`);
      }, 400);
    }
  };

  const handleChoice = (choiceItem, response) => {
    const responses = JSON.parse(localStorage.getItem('responses'))
      ? JSON.parse(localStorage.getItem('responses'))
      : [];
    setChoice(response.name);

    localStorage.setItem(
      'responses',
      JSON.stringify([
        ...responses,
        { type: response.name, title: response.title, step: choiceItem.step },
      ])
    );

    if (choiceItem.step === 1) {
      localStorage.setItem('typeProfil', response.name);
    }
  };

  if (redirectTo) {
    return <Redirect to="/" />;
  }

  return (
    <div className="ConvertionTunnel">
      <Sky
        images={{
          0: vousColor,
          1: nousColor,
        }}
        how={50}
        time={40}
        size="10px"
        background="palettedvioletred"
      />
      <div className="ConvertionTunnel_steps">
        {listOfSteps && handleSteps(listOfSteps)}
      </div>

      {listOfSteps &&
        listOfSteps.map((step, index) => {
          const indexOfStep = index + 1;

          return (
            Number(numberStep) === indexOfStep && (
              <div
                key={step.name}
                className={
                  isActive
                    ? 'ConvertionTunnel_question_active'
                    : 'ConvertionTunnel_question'
                }
              >
                {isFinalPage && <FinalStep />}
                {!isFinalPage && (
                  <div className="ConvertionTunnel_question_description">
                    {step.description}
                  </div>
                )}
                {!isFinalPage && (
                  <div className="ConvertionTunnel_responses">
                    {step.responses &&
                      step.responses
                        .filter((response) => handleResponseType(response))
                        .map((response) => {
                          return (
                            <Card
                              response={response}
                              step={step}
                              handleChoice={handleChoice}
                              choice={choice}
                              info={info}
                              setInfo={setInfo}
                              handleActiveButton={handleActiveButton}
                              setActive={setActive}
                              isActive={isActive}
                            />
                          );
                        })}
                  </div>
                )}
              </div>
            )
          );
        })}
      <div className="ConvertionTunnel_actions">
        <button
          type="button"
          className="ConvertionTunnel_action ConvertionTunnel_action_prev"
          onClick={() => handleAnimationQuestionPrev()}
        >
          Précedent
        </button>
        <button
          type="button"
          className="ConvertionTunnel_action ConvertionTunnel_action_next"
          onClick={() => handleAnimationQuestionNext()}
        >
          {isFinalPage ? 'Envoyer' : 'Suivant'}
        </button>
      </div>
    </div>
  );
};

ConversionTunnel.defaultProps = {
  params: {
    numberStep: null,
  },
};

ConversionTunnel.propTypes = {
  params: PropTypes.shape({
    numberStep: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default ConversionTunnel;
