/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable prefer-object-spread */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './Bureau.css';
import './Modals.css';
import confetti from 'canvas-confetti';
import { connect } from 'react-redux';
import bureauEmpty from '../../styles/img/Game/bureau_empty_3.svg';
import Computer from './Computer';
import Mug from './Mug';
import Drawer from './Drawer';
import PowerChord from './PowerChord';
import Folder from './Folder';
import Board from './Board';
import Phone from './Phone';
import Intro from './Intro';
import Clock from './WrongObjects/Clock';
import Hat from './WrongObjects/Hat';
import Trash from './WrongObjects/Trash';
import DesktopChair from './WrongObjects/DesktopChair';
import DesktopLamp from './WrongObjects/DesktopLamp';
import DesktopTopLamp from './WrongObjects/DesktopTopLamp';
import DesktopDoor from './WrongObjects/DesktopDoor';
import DesktopPapers from './WrongObjects/DesktopPapers';
import DesktopCupboard from './WrongObjects/DesktopCupboard';
import DesktopLockers from './WrongObjects/DesktopLockers';
import desktopofdesktop from '../../styles/img/Game/BUREAU-desktop-of-desktop.svg';
import Notifications from './Notifications';
import introtext from './ContentText.json';
import GameBoardHeader from './GameBoardHeader';
import succes from '../../sound/successhorns1.mp3';
import cross from '../../img/cross.svg';
import ModalEmotionCard from '../Reusable/Modals/ModalEmotionCard';
import PreventionMessages from './PreventionMessages';
import pessimismeAudio from '../../styles/sound/board_pessimisme_fr.mp3';
import MusicThemeOffice from '../../styles/sound/My Sunshine.mp3';
import Congratulation from './Score/Congratulation';

import Scoreboard from './Score/Scoreboard';

const Bureau = ({ ModalBureau, GameUsers, dispatch }) => {
  const emotionPessimismeId = '24';

  useEffect(() => {
    if (
      GameUsers.gameStarted &&
      !GameUsers.timerPause &&
      !GameUsers.soundMuted
    ) {
      document.getElementById('audioOffice').volume = 0.05;
    }
  });

  useEffect(() => {
    const duration = 1.5 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    if (GameUsers.scoreboard) {
      const interval = setInterval(function () {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        const particleCount = 500 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.1, 0.4), y: Math.random() - 0.2 },
          })
        );
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.6, 0.9), y: Math.random() - 0.2 },
          })
        );
      }, 250);
    }
  }, [GameUsers.scoreboard]);

  return (
    <>
      {GameUsers.gameStarted && !GameUsers.timerPause && !GameUsers.soundMuted && (
        <audio src={MusicThemeOffice} id="audioOffice" autoPlay loop>
          <track kind="captions" />
        </audio>
      )}
      {GameUsers.scoreboard && (
        <audio src={succes} autoPlay>
          <track kind="captions" />
        </audio>
      )}
      <div className="Bureau-img">
        <Notifications />
        <Intro
          title={introtext.room1.title}
          content={introtext.room1.content}
          img={introtext.room1.img}
        />
        {/* <GameBoardHeader /> */}
        <div
          onClick={() => dispatch({ type: 'INCREMENT_COUNTER' })}
          onKeyDown={() => dispatch({ type: 'INCREMENT_COUNTER' })}
          role="button"
          tabIndex={0}
          className="Bureau-img-div"
        >
          <img src={bureauEmpty} alt="bureau" draggable="false" />
        </div>
        <PowerChord />
        <Computer />
        <Mug />
        <Board />
        <Drawer />
        <Folder />
        <Phone />
        <Clock />
        <Hat />
        <Trash />
        <DesktopChair />
        <DesktopLamp />
        <DesktopPapers />
        <DesktopTopLamp />
        <DesktopDoor />
        <DesktopCupboard />
        <DesktopLockers />
        <div
          onClick={() => dispatch({ type: 'INCREMENT_COUNTER' })}
          onKeyDown={() => {}}
          role="presentation"
          className="desktopdesktop-office"
        >
          <img src={desktopofdesktop} alt="desktop" draggable="false" />
        </div>
        {ModalBureau.ModalPessimisme && (
          <PreventionMessages
            typeEmotion={emotionPessimismeId}
            endTimer={25000}
            sound={pessimismeAudio}
          />
        )}
        {GameUsers.modalCardFound && (
          <>
            <div
              className={
                GameUsers.modalCardFound
                  ? 'ModalTricky-Background'
                  : 'Hide-Modal'
              }
            >
              <div className="ModalTricky-Emotion-Card">
                <div className="Emotion-Card-Container">
                  <ModalEmotionCard />
                </div>
              </div>
              <div
                className="CloseWindow"
                role="presentation"
                onClick={() =>
                  dispatch({
                    type: 'SHOW_MODAL_PREVENTION_GLOBAL',
                    payload: false,
                  })
                }
              >
                <img src={cross} alt="Fermer" title="Fermer" />
              </div>
            </div>
          </>
        )}
        {GameUsers.gameFinished && <Congratulation />}
        {GameUsers.scoreboard && <Scoreboard />}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  preventionMessages: state.prevention_messages.allMessages,
  ModalBureau: state.prevention_messages.ModalBureau,
  GameUsers: state.GameUsers,
  Room: state.Room,
});

Bureau.propTypes = {
  dispatch: PropTypes.func.isRequired,
  ModalBureau: PropTypes.shape({
    ModalAnxiete: PropTypes.bool.isRequired,
    ModalAgressivite: PropTypes.bool.isRequired,
    ModalFatalite: PropTypes.bool.isRequired,
    ModalPessimisme: PropTypes.bool.isRequired,
  }).isRequired,
  GameUsers: PropTypes.shape({
    modalCardFound: PropTypes.bool.isRequired,
    gameStarted: PropTypes.bool.isRequired,
    timerPause: PropTypes.bool.isRequired,
    soundMuted: PropTypes.bool.isRequired,
    gameFinished: PropTypes.bool.isRequired,
    scoreboard: PropTypes.bool.isRequired,
    fireworks: PropTypes.bool.isRequired,
  }).isRequired,
  Room: PropTypes.shape({
    Desktop: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(Bureau);
