import React from 'react';
import PropTypes from 'prop-types';
import './Scoreboard.css';

const ScoreboardItem = (props) => {
  const { rank, pseudo, count, time, style } = props;

  return (
    <>
      <div
        className={
          style
            ? 'ModalTricky-Scoreboard-User-DemoMulti'
            : 'ModalTricky-Scoreboard-players-DemoMulti'
        }
      >
        <div
          className={`col-10 ${
            style ? 'scoreboard-user-DemoMulti' : 'scoreboard-rank-DemoMulti'
          }`}
        >
          {rank}
        </div>
        <div
          className={`col-50 ${
            style ? 'scoreboard-user-DemoMulti' : 'scoreboard-pseudo-DemoMulti'
          }`}
        >
          {pseudo}{' '}
        </div>
        <div className="col-20">{count}</div>
        <div className="col-20">{time}</div>
      </div>
    </>
  );
};

ScoreboardItem.propTypes = {
  pseudo: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  time: PropTypes.string.isRequired,
  rank: PropTypes.number.isRequired,
  style: PropTypes.string.isRequired,
};

export default ScoreboardItem;
