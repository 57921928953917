import companyColor from '../../styles/img/company_color.svg';
import companyGrey from '../../styles/img/company_grey.svg';

const initialState = {
  Entreprise: [
    {
      id: 1,
      name: 'Objectif: Zéro porté en EHPAD',
      description:
        'Plongés dans la chambre d’EHPAD de Madame Martin, les participants incarnent des intérimaires fraîchement recrutés pour renforcer l’équipe de l’établissement, qui souffre d’un fort absentéisme. Ces jeunes intérimaires ne connaissent pas l’EHPAD, leur mission est dévoilée par le médecin coordinateur : “Il faut mettre Madame Martin au fauteuil pour des raisons médicales”. Seulement la tâche ne s’avère pas si facile... Sauront-ils mobiliser la patiente de la bonne façon et dans les délais pour tenir le rythme de la tournée ?',
      objectif: [
        'Acquisition de savoir-être face à une situation de mobilisation d’un patient.',
        'Compréhension des facteurs de risque et mise en place de la bonne attitude à avoir.',
      ],
      imageColor: companyColor,
      imageGrey: companyGrey,
      préRequis: 'Aucun',
      duréeImmersion: 60,
      duréeDebrief: 45,
      choosen: false,
      modal: false,
    },
    {
      id: 2,
      name: 'Épuisement professionnel : des mécanismes à résoudre',
      description:
        'La phase d’immersion “Escape Room Santé”, se déroule dans un univers plus ou moins abstrait. La première phase de jeu sérieux met en scène une salle de pause avec sa machine à café... bien différentes de celle que vous rencontrez d’ordinaire... La seconde phase quant à elle vient retranscrire l’épuisement physique et émotionnel en immergeant le groupe dans une situation de surcharge mentale. Ce scénario retrace la vie d’un salarié attaché à son travail mais se retrouvant devant de grandes difficultés pour parvenir à la mission qu’on lui a confiée. Vous devrez donc l’aider à comprendre ce qu’il se passe dans son univers de travail pour l’aider à sortir de là...',
      objectif: [
        'Connaître la défnition du burn-out.',
        'Savoir repérer les symptômes du syndrôme d’épuisement professionnel, les facteurs de risques et son contexte d’appariton.',
        'Savoir orienter vers les personnes ressources.',
      ],
      imageColor: companyColor,
      imageGrey: companyGrey,
      préRequis: 'Aucun',
      duréeImmersion: 60,
      duréeDebrief: 45,
      choosen: false,
      modal: false,
    },
    {
      id: 3,
      name: 'Manager : un défi émotionnel',
      description:
        'Mandatés par les assurances, vous rentrez dans le bureau de Franck, un directeur d’entreprise, que le compagnon virtuel a décidé d’enfermer après qu’il ait montré différents symptômes de dégradation de la santé... Réunissez les 5 symptômes et établissez son diagnostic pour lui permettre de sortir de son bureau et reprendre une vie normale. Mais attention, les assurances sont exigeantes envers les experts qu’elles mandatent : vous devez réussir en moins d’une heure... et le chrono vient tout juste de se lancer!',
      objectif: [
        'Connaître la défnition du trouble dépressif.',
        'Savoir repérer les symptômes du trouble dépressif et son contexte d’appariton.',
        'Savoir orienter vers les personnes ressources.',
      ],
      imageColor: companyColor,
      imageGrey: companyGrey,
      préRequis: 'Aucun',
      duréeImmersion: 60,
      duréeDebrief: 45,
      choosen: false,
      modal: false,
    },
    {
      id: 4,
      name: 'Nutrition, sommeil et stress : des leviers de performance',
      description:
        'L’obésité est une maladie complexe... Elle résulte de multiples interactions entre notre environnement et notre ADN ! Pour la première fois, nous allons enfin pouvoir comprendre le lien entre notre environnement et le risque d’apparition de l’obésité. Malheureusement nous ne disposons pas de beaucoup de temps... La consommation électrique pour parvenir à générer cette expérience est bien trop importante... Il faudra que vous soyez à tout prix sortis avant les 60 minutes... Relèverez-vous le défi ?',
      objectif: [
        'Connaître la définition de l’obésité.',
        'Savoir repérer les facteurs de risques et son contexte d’apparition.',
        'Savoir orienter vers les personnes ressources.',
      ],
      imageColor: companyColor,
      imageGrey: companyGrey,
      préRequis: 'Aucun',
      duréeImmersion: 60,
      duréeDebrief: 45,
      choosen: false,
      modal: false,
    },
    {
      id: 5,
      name: 'Émotions au travail : un équilibre à trouver',
      description: 'lorem ipsum...',
      objectif: [
        'Bla bla bla tricky',
        'Bla bla bla tricky',
        'Bla bla bla tricky',
      ],
      imageColor: companyColor,
      imageGrey: companyGrey,
      préRequis: 'Aucun',
      duréeImmersion: 60,
      duréeDebrief: 45,
      choosen: false,
      modal: false,
    },
  ],
  Université: [
    {
      id: 1,
      name: "Étudiant : un bouillon d'émotions à surmonter",
      description:
        "Dans cette Escape Room Santé, les participants se verront enfermés dans la chambre de Thomas. Ils devront y retrouver les cinq symptômes qui leur permettront de résoudre l'énigme finale et aider cet étudiant. Une projection futuriste où l’intelligence artificielle domine le monde et ou les compagnons, véritables coachs de santé sont là pour veiller sur chacun... Celui de Thomas l'a enfermé, pour son propre bien, paraîtrait-il ! Puis, il vous a coincé dans sa chambre. Saurez-vous retrouver tous les symptômes pour déjouer l'algorithme ultra-intelligent ?",
      objectif: [
        'Connaître la défnition du trouble dépressif.',
        'Savoir repérer les symptômes du trouble dépressif et son contexte d’appariton.',
        'Savoir orienter vers les personnes ressources.',
      ],
      imageColor: companyColor,
      imageGrey: companyGrey,
      préRequis: 'Aucun',
      duréeImmersion: 60,
      duréeDebrief: 45,
      choosen: false,
      modal: false,
    },
    {
      id: 2,
      name: 'Comportements à risque en soirée étudiante',
      description:
        'Alex, élève en première année de neuro- connectique sociale, est introuvable. Selon le grand code de déontologie de l’Université, vous êtes missionnés pour retrouver sa position. La fréquentation trop importante durant la fête d’hier soir a empêché l’IA de géolocaliser Alex. À l’aide de la mallette, vous devez retracer son parcours et ainsi reconstituer les événements de sa soirée. La situation est urgente, car les données partielles qui nous ont été transmises sont alarmantes. Nous comptons sur vous, vous disposez de 60 minutes ; Bonne chance',
      objectif: [
        'Définition d’une addiction',
        'Acquisition de savoir-être lors d’un regroupement festif.',
        'Compréhension des facteurs de risque et mise en place de la bonne attitude à avoir.',
      ],
      imageColor: companyColor,
      imageGrey: companyGrey,
      préRequis: 'Aucun',
      duréeImmersion: 60,
      duréeDebrief: 45,
      choosen: false,
      modal: false,
    },
    {
      id: 3,
      name: 'Nutrition, sommeil et stress : des leviers de performance',
      description:
        'L’obésité est une maladie complexe... Elle résulte de multiples interactions entre notre environnement et notre ADN ! Pour la première fois, nous allons enfin pouvoir comprendre le lien entre notre environnement et le risque d’apparition de l’obésité. Malheureusement nous ne disposons pas de beaucoup de temps... La consommation électrique pour parvenir à générer cette expérience est bien trop importante... Il faudra que vous soyez à tout prix sortis avant les 60 minutes... Relèverez-vous le défi ?',
      objectif: [
        'Connaître la définition de l’obésité.',
        'Savoir repérer les facteurs de risques et son contexte d’apparition.',
        'Savoir orienter vers les personnes ressources.',
      ],
      imageColor: companyColor,
      imageGrey: companyGrey,
      préRequis: 'Aucun',
      duréeImmersion: 60,
      duréeDebrief: 45,
      choosen: false,
      modal: false,
    },
    {
      id: 4,
      name: 'Émotions au travail : un équilibre à trouver',
      description: 'lorem ipsum...',
      objectif: [
        'Bla bla bla tricky',
        'Bla bla bla tricky',
        'Bla bla bla tricky',
      ],
      imageColor: companyColor,
      imageGrey: companyGrey,
      préRequis: 'Aucun',
      duréeImmersion: 60,
      duréeDebrief: 45,
      choosen: false,
      modal: false,
    },
  ],
  Hospitalier: [
    {
      id: 1,
      name: 'Obésité : Prévention des risques',
      description:
        "L’obésité est une maladie complexe. Elle résulte de multiples interactions entre notre environnement et notre ADN ! Dans une immersion futuriste, les usagers devront résoudre six énigmes associées à des facteurs de risques liés à l'apparition de l'obésité. Ensuite ils seront conviés à échanger sur cette maladie afin de la définir et de partager leur quotidien et question sur cette thématique.",
      objectif: [
        'Connaître la définition de l’obésité.',
        'Savoir repérer les facteurs de risques et son contexte d’apparition.',
        'Savoir orienter vers les personnes ressources.',
      ],
      imageColor: companyColor,
      imageGrey: companyGrey,
      préRequis: 'Aucun',
      duréeImmersion: 60,
      duréeDebrief: 45,
      choosen: false,
      modal: false,
    },
    {
      id: 2,
      name: 'Santé Mental',
      description:
        "L’obésité est une maladie complexe. Elle résulte de multiples interactions entre notre environnement et notre ADN ! Dans une immersion futuriste, les usagers devront résoudre six énigmes associées à des facteurs de risques liés à l'apparition de l'obésité. Ensuite ils seront conviés à échanger sur cette maladie afin de la définir et de partager leur quotidien et question sur cette thématique.",
      objectif: [
        'Connaître la défnition du trouble dépressif.',
        'Savoir repérer les symptômes du trouble dépressif et son contexte d’appariton.',
        'Savoir orienter vers les personnes ressources.',
      ],
      imageColor: companyColor,
      imageGrey: companyGrey,
      préRequis: 'Aucun',
      duréeImmersion: 60,
      duréeDebrief: 45,
      choosen: false,
      modal: false,
    },
  ],
};

const Themes = (state = initialState, action) => {
  const resetUnivers = (ThemeType) => {
    const lenghtArray = state[ThemeType].filter((element) => element.choosen)
      .length;
    if (lenghtArray > 0) {
      const actualChoiceId = state[ThemeType].filter(
        (element) => element.choosen
      )[0].id;
      return state[ThemeType].map((element) => {
        if (element.id === actualChoiceId) {
          return {
            ...element,
            choosen: false,
            modal: false,
          };
        }
        return element;
      });
    }
    return state[ThemeType];
  };
  switch (action.type) {
    case 'RESET_UNIVERS_CLICKED': {
      const ThemeType = action.payload.typeOfTheme;
      return {
        ...state,
        [ThemeType]: resetUnivers(ThemeType),
      };
    }
    case 'UNIVERS_CLICKED': {
      const ThemeType2 = action.payload.typeOfTheme;
      return {
        ...state,
        [ThemeType2]: state[ThemeType2].map((element) => {
          if (element.id === action.payload.idChoice) {
            return {
              ...element,
              choosen: action.payload.choosen,
              modal: action.payload.choosen,
            };
          }
          return element;
        }),
      };
    }
    case 'RESET_UNIVERS_GLOBAL': {
      return initialState;
    }
    default:
      return state;
  }
};

export default Themes;
