import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import trash from '../../../styles/img/Game/trash.svg';
import wrongobjects from '../WrongObjects.json';
import '../ModalWrongObject.css';
import '../Modals.css';
import ModalWrongObject from '../ModalWrongObject';

const Trash = ({ WrongObjectsModal, language, dispatch }) => {
  const { title, closemodal } = wrongobjects.office.trash;
  const message = wrongobjects.office.trash[language];
  const handleClick = () => {
    dispatch({
      type: 'SHOW_MODAL',
      payload: {
        whichRoom: 'Desktop',
        typeOfModal: 'WrongObjectsModal',
        whichModal: 'OfficeTrash',
        show: true,
      },
    });
  };

  return (
    <>
      {WrongObjectsModal.OfficeTrash && (
        <ModalWrongObject
          title={title}
          message={message}
          closemodal={closemodal}
          img={trash}
        />
      )}
      <div>
        <div
          className="trash-office"
          onClick={() => handleClick()}
          onKeyDown={() => handleClick()}
          tabIndex={0}
          role="button"
        >
          <img src={trash} alt="Trash of the office" draggable="false" />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  Objects: state.Objects,
  WrongObjectsModal: state.Modal.Desktop.WrongObjectsModal,
  language: state.GameUsers.language,
});

Trash.propTypes = {
  dispatch: PropTypes.func.isRequired,
  WrongObjectsModal: PropTypes.shape({
    OfficeTrash: PropTypes.bool.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(Trash);
