import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './LPDigitalRoom.css';
import videoDemo from '../../styles/img/digital-room.mov';

const DigitalRoomVideo = () => {
  const [muteVideo, setMuteVideo] = useState(true);

  return (
    <div className="DigitalRoomVideo">
      <div className="DigitalRoomVideo-Title">
        <h2>Digital Room Santé</h2>
      </div>
      <div className="DigitalRoomVideo-Description">
        <p>
          Anxiété, stress, méfiance… Travailler et vivre dans un contexte
          d’épidémie a des répercussions psychologiques importantes, c’est ce
          qui arrive à Tony. Un véritable raz-de marée émotionnel le submerge,
          toutes ses réactions sont plus énigmatiques les unes que les autres.
        </p>
        <p>
          Votre mission est d’identifier les émotions de Tony et la façon dont
          il est possible pour lui de les gérer au quotidien.
        </p>
      </div>
      <div className="DigitalRoomVideo-Video">
        <div className="DigitalRoomVideo-VideoFrame">
          <span
            className={!muteVideo ? 'fas fa-volume-up' : 'fas fa-volume-mute'}
            role="button"
            title={!muteVideo ? 'Couper le son' : 'Activer le son'}
            onClick={() => setMuteVideo(!muteVideo)}
            onKeyDown={() => setMuteVideo(!muteVideo)}
            tabIndex={0}
            aria-label="Mute volume"
          />
          <video src={videoDemo} autoPlay loop id="bgvid" muted={muteVideo}>
            <track kind="captions" />
          </video>
        </div>
      </div>
      <div className="DigitalRoomVideo-Button">
        <Link to="/digital-room-game" target="_blank">
          <button type="button">
            Accéder à la démo{' '}
            <span className="fas fa-chevron-right" title="Accéder à la démo" />
          </button>
        </Link>
      </div>
    </div>
  );
};

export default DigitalRoomVideo;
