import React from 'react';
import './LPDigitalRoom.css';
import NouvelleAquitaine from '../../styles/img/nouv-aquitaine.svg';
import Unitec from '../../styles/img/unitec.png';
import ScienceTransfert from '../../styles/img/science-transfert.png';

const TheyTrustUs = () => {
  return (
    <div className="DigitalRoomVideo">
      <div className="DigitalRoomVideo">
        <div className="DigitalRoomVideo-Title">
          <h2>Nos partenaires</h2>
        </div>
        <div className="DigitalRoomVideo-Description">
          <p>Nous travaillons en synergie avec différents partenaires</p>
        </div>
        <div className="DigitalRoomVideo-Partner">
          <img
            src={NouvelleAquitaine}
            alt="Nouvelle Aquitaine"
            title="Nouvelle Aquitaine"
          />
          <img src={Unitec} alt="Unitec Bordeaux" title="Unitec Bordeaux" />
          <img
            src={ScienceTransfert}
            alt="Aquitaine Science Transfert"
            title="Aquitaine Science Transfert"
          />
        </div>
      </div>
    </div>
  );
};

export default TheyTrustUs;
