import phoneImg from '../../styles/img/Game/phone_OFF.svg';

const phoneStatus = (
  state = {
    image: phoneImg,
    status: 'phone_off',
    phoneNumber: [],
    Modal: {
      modalNoPower: false,
      modalPower: false,
      modalWrongNumber: false,
      modalGoodNumber: false,
    },
  },
  action
) => {
  switch (action.type) {
    case 'TURN_ON_PHONE':
      return {
        ...state,
        image: action.payload.image,
        status: 'phone_on',
      };
    case 'ENTER_GOOD_NUMBER':
      return {
        ...state,
        status: 'good_number',
      };
    case 'SHOW_MODAL_PHONE': {
      const typeOfModal = action.payload.modalType;
      return {
        ...state,
        Modal: {
          ...state.Modal,
          [typeOfModal]: action.payload.showModal,
        },
      };
    }
    case 'ADD_PHONE_DIGIT':
      return {
        ...state,
        phoneNumber: [...state.phoneNumber, action.payload],
      };
    case 'REMOVE_PHONE_DIGIT':
      state.phoneNumber.pop();
      return {
        ...state,
      };
    case 'RESET_PHONE_NUMBER':
      return {
        ...state,
        phoneNumber: [],
      };
    default:
      return state;
  }
};

export default phoneStatus;
