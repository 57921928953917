import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes, { object } from 'prop-types';
import { useHistory } from 'react-router-dom';

const TunnelFooter = ({
  dispatch,
  TunnelNavigation,
  Company,
  Univers,
  sChoice,
  initialChoice,
}) => {
  const CurrentQuestion = TunnelNavigation.filter(
    (element) => element.isActive
  )[0].name;
  const history = useHistory();
  const handleNext = () => {
    const indexQuestionActive = TunnelNavigation.filter(
      (element) => element.isActive
    )[0].index;

    if (indexQuestionActive === TunnelNavigation.length - 1) {
      return null;
    }
    const nameCurrentQuestion = TunnelNavigation.filter(
      (element) => element.isActive
    )[0].name;

    switch (nameCurrentQuestion) {
      case 'Question 1 Type':
        {
          const userChoice = Company[initialChoice].filter(
            (element) => element.choosen
          )[0].name;
          dispatch({
            type: 'USER_CHOICE',
            payload: {
              answer: userChoice,
              choice: 'Type',
            },
          });
        }
        break;
      case 'Question 2 Theme':
        {
          const userChoice = Univers[initialChoice].filter(
            (element) => element.choosen
          )[0].name;
          dispatch({
            type: 'USER_CHOICE',
            payload: {
              answer: userChoice,
              choice: 'Thématiques',
            },
          });
        }
        break;
      case 'Question 3':
        {
          const userChoice = sChoice.filter((element) => element.choosen)[0]
            .name;
          dispatch({
            type: 'USER_CHOICE',
            payload: {
              answer: userChoice,
              choice: 'Déploiement',
            },
          });
        }
        break;
      default:
        return null;
    }

    const nextQuestionActive = TunnelNavigation[indexQuestionActive + 1].name;
    dispatch({
      type: 'NAVIGATION_CHANGE',
      payload: {
        question: nextQuestionActive,
        active: true,
        status: 'doing',
        addition: '+ 20',
      },
    });
    dispatch({
      type: 'NAVIGATION_CHANGE',
      payload: {
        question: nameCurrentQuestion,
        active: false,
        status: 'done',
      },
    });
    dispatch({
      type: 'CHOICE_CHECKED',
      payload: {
        question: nameCurrentQuestion,
        status: 'done',
      },
    });
    return null;
  };

  const handleBack = () => {
    const indexQuestionActive = TunnelNavigation.filter(
      (element) => element.isActive
    )[0].index;
    if (indexQuestionActive === 1) {
      history.push('/');
    }
    const nameCurrentQuestion = TunnelNavigation.filter(
      (element) => element.isActive
    )[0].name;
    const backQuestionActive = TunnelNavigation[indexQuestionActive - 1].name;
    dispatch({
      type: 'NAVIGATION_CHANGE',
      payload: {
        question: backQuestionActive,
        active: true,
        status: 'doing',
      },
    });
    dispatch({
      type: 'NAVIGATION_CHANGE',
      payload: {
        question: nameCurrentQuestion,
        active: false,
        status: 'unDone',
      },
    });
  };

  useEffect(() => {
    if (!initialChoice) {
      history.push('/');
    }
  }, []);

  const isActionsChoosen = sChoice.filter((element) => element.choosen).length;
  const isEntrepriseChoosen = Company.Entreprise.filter(
    (element) => element.choosen
  ).length;
  const isHospitalierChoosen = Company.Hospitalier.filter(
    (element) => element.choosen
  ).length;
  const isUniversiteChoosen = Company.Université.filter(
    (element) => element.choosen
  ).length;
  const isEntrepriseThemeChoosen = Univers.Entreprise.filter(
    (element) => element.choosen
  ).length;
  const isHospitalierThemeChoosen = Univers.Hospitalier.filter(
    (element) => element.choosen
  ).length;
  const isUniversiteThemeChoosen = Univers.Université.filter(
    (element) => element.choosen
  ).length;

  const CompanyCondition =
    isEntrepriseChoosen !== 0 ||
    isHospitalierChoosen !== 0 ||
    isUniversiteChoosen !== 0;
  const UniversCondition =
    isEntrepriseThemeChoosen !== 0 ||
    isHospitalierThemeChoosen !== 0 ||
    isUniversiteThemeChoosen !== 0;

  return (
    <div className="Tunnel-Form-Footer">
      <div>
        <button type="button" className="Back-Button" onClick={handleBack}>
          Retour
        </button>
      </div>
      {CompanyCondition && CurrentQuestion === 'Question 1 Type' && (
        <div>
          <button type="button" className="Next-Button" onClick={handleNext}>
            Étape suivante
          </button>
        </div>
      )}
      {UniversCondition && CurrentQuestion === 'Question 2 Theme' && (
        <div>
          <button type="button" className="Next-Button" onClick={handleNext}>
            Étape suivante
          </button>
        </div>
      )}
      {isActionsChoosen !== 0 && CurrentQuestion === 'Question 3' && (
        <div>
          <button type="button" className="Next-Button" onClick={handleNext}>
            Étape suivante
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  TunnelNavigation: state.Tunnel.TunnelNavigation,
  Univers: state.Themes,
  Company: state.Company,
  sChoice: state.Actions.Actions,
  initialChoice: state.Tunnel.UserChoice.Univers,
});

TunnelFooter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  TunnelNavigation: PropTypes.arrayOf(object).isRequired,
  Company: PropTypes.arrayOf(object).isRequired,
  Univers: PropTypes.arrayOf(object).isRequired,
  sChoice: PropTypes.arrayOf(object).isRequired,
  initialChoice: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(TunnelFooter);
