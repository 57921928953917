import PropTypes, { object } from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Animation from '../../Animation';

const TunnelChoiceActions = ({
  name,
  description,
  imageGrey,
  imageColor,
  idReponse,
  sChoice,
  dispatch,
}) => {
  const [reduxChoice, setReduxChoice] = useState(true);
  const handleClick = (choiceId) => {
    const choosenCompany = sChoice.filter((element) => element.choosen).length;
    if (choosenCompany > 0) {
      dispatch({ type: 'RESET_ACTIONS_CLICKED' });
    }
    setReduxChoice(!reduxChoice);
    dispatch({
      type: 'ACTIONS_CLICKED',
      payload: {
        idChoice: choiceId,
        choosen: reduxChoice,
      },
    });
  };

  const isChoosen = sChoice.filter(
    (element) => element.idReponse === idReponse
  )[0].choosen;

  return (
    <>
      <div
        onClick={() => handleClick(idReponse)}
        onKeyDown={() => handleClick(idReponse)}
        role="button"
        tabIndex={0}
        className={
          isChoosen
            ? `Tunnel-Choice-Container-${idReponse}-choosen`
            : `Tunnel-Choice-Container-${idReponse}`
        }
      >
        <div className="Tunnel-Choice-Univers-Img">
          <img src={isChoosen ? imageColor : imageGrey} alt={name} />
        </div>
        <p> {name} </p>
        {/* <span>{description}</span> */}
        {isChoosen ? (
          <div className="Tunnel-Choice-Check-Good">
            <span className="fas fa-check-circle good" />
          </div>
        ) : (
          <div className="Tunnel-Choice-Check" />
        )}
      </div>
      {isChoosen && (
        <Animation
          wrapperElement="div"
          direction="up"
          className="Tunnel-Choice-Infos-Action"
          distance={200}
        >
          <h3>{name}:</h3>
          <div>
            {' '}
            <span>{description}</span>
          </div>
        </Animation>
      )}
    </>
  );
};

TunnelChoiceActions.propTypes = {
  sChoice: PropTypes.arrayOf(object).isRequired,
  description: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  idReponse: PropTypes.number.isRequired,
  imageColor: PropTypes.string.isRequired,
  imageGrey: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  sChoice: state.Actions.Actions,
});

export default connect(mapStateToProps)(TunnelChoiceActions);
