/* eslint-disable no-case-declarations */
const initialState = {
  Desktop: {
    name: 'Bureau',
    niveau: 1,
    isActive: true,
  },
};

const Room = (state = initialState, action) => {
  switch (action.type) {
    case 'ROOM_ACTIVE':
      const roomType = action.payload.room;
      return {
        ...state,
        [roomType]: {
          ...state[roomType],
          isActive: action.payload.active,
        },
      };
    default:
      return state;
  }
};

export default Room;
