/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import './UserBoard.css';
import { connect } from 'react-redux';
import CameraBlack from '../../../img/volume-up-solid-white.svg';
import MicroBlack from '../../../img/microphone-solid_white.svg';

const Cameras = ({ video }) => {
  return (
    <div className="UserBoard-participant">
      <video autoPlay muted loop>
        <source src={video} />
        <track kind="captions" />
      </video>
      <div className="UserBoard-RightPanel">
        <div id="video-onboard" role="presentation">
          <img
            src={CameraBlack}
            alt="Activer la caméra"
            title="Activer la caméra"
            aria-label="Mute video"
          />
        </div>
        <div
          id="microphone-onboard"
          className="microphone-onboard"
          type="button"
          role="presentation"
        >
          <img
            src={MicroBlack}
            title="Activer le micro"
            aria-label="Mute audio"
            alt="Activer le micro"
          />
        </div>
      </div>
    </div>
  );
};

Cameras.propTypes = {
  video: PropTypes.string.isRequired,
};

export default connect()(Cameras);
