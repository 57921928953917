/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Radium, { StyleRoot } from 'radium';
import { wobble } from 'react-animations';
import { connect } from 'react-redux';
import './Bureau.css';
import './Modals.css';
import './Intro.css';
import next from '../../img/next-ingame.svg';
import countdownSound from '../../styles/sound/countdown.mp3';

const Intro = ({ Modal, dispatch, game }) => {
  const [startAnimation, setStartAnimation] = useState(false);
  const styles = {
    wobble: {
      animation: 'infinite 5s',
      animationName: Radium.keyframes(wobble, 'wobble'),
    },
  };

  const closeIntro = () => {
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'countdown',
        action: true,
      },
    });
    setTimeout(() => {
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'countdownReady',
          action: true,
        },
      });
    }, 5500);
    setTimeout(() => {
      dispatch({ type: 'SHOW_MODAL_INTRO', payload: false });
      dispatch({
        type: 'SHOW_MESSAGE',
        payload: {
          message: 'Bienvenue dans le Bureau',
          show: true,
        },
      });
      dispatch({
        type: 'GAME_ACTION',
        payload: {
          typeOfAction: 'gameStarted',
          action: true,
        },
      });
    }, 6500);
  };

  useEffect(() => {
    if (game.countdown) {
      setTimeout(() => {
        setStartAnimation(true);
        if (document.getElementById('countdown-number')) {
          const countdownNumberEl = document.getElementById('countdown-number');
          let countdown = 5;
          countdownNumberEl.textContent = countdown;
          // eslint-disable-next-line func-names
          setInterval(function () {
            if (countdown > 0) {
              // eslint-disable-next-line no-plusplus
              countdown = --countdown <= 0 ? 5 : countdown;
              countdownNumberEl.textContent = countdown;
            }
          }, 1000);
        }
      }, 1000);
    }
  }, [game.countdown]);

  return (
    <div
      className={Modal.ModalIntro ? 'ModalTricky-Intro' : 'Hide-Modal'}
      id="intro"
    >
      {game.countdown ? (
        <>
          <audio src={countdownSound} autoPlay>
            <track kind="captions" />
          </audio>
          {!game.countdownReady ? (
            <>
              <div id="countdown">
                <div id="countdown-number" />
                <svg id={startAnimation ? 'svg' : 'svg2'}>
                  <circle r="18" cx="20" cy="20" />
                </svg>
              </div>
              <div id={startAnimation ? 'countdownTitle' : 'countdownTitle3'}>
                <h4>Tenez-vous prêt.e !</h4>
              </div>
            </>
          ) : (
            <div id="countdownTitle2">
              <h4>C&apos;est parti !</h4>
            </div>
          )}
        </>
      ) : (
        <div className="ModalTricky-Intro-Container">
          <div className="col-6">
            <div className="ModalTricky-Intro-Card">
              <p className="ModalTricky-Intro-Text">
                Vous êtes dans la première salle de l&apos;Escape Game Tricky
                sur la gestion des émotions en période de confinement.
              </p>
              <p className="ModalTricky-Intro-Text">
                <strong>
                  Aidez Tony à identifier ses émotions et comportements
                </strong>
                pour mieux les comprendre et y faire face.
              </p>
            </div>
            <StyleRoot>
              <button
                className="ButtonAction Action"
                type="button"
                onClick={() => closeIntro()}
                style={styles.wobble}
              >
                Commencer{' '}
                <img
                  className="next-ingame"
                  src={next}
                  alt="Commencer"
                  draggable="false"
                />
              </button>
            </StyleRoot>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  boardCounter: state.boardCounter,
  preventionMessages: state.prevention_messages.allMessages,
  Modal: state.Objects.Modal,
  game: state.GameUsers,
});

Intro.propTypes = {
  dispatch: PropTypes.func.isRequired,
  Modal: PropTypes.shape({
    ModalIntro: PropTypes.bool.isRequired,
  }).isRequired,
  game: PropTypes.shape({
    countdown: PropTypes.bool.isRequired,
    countdownReady: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(Intro);
