import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import lockers from '../../../styles/img/Game/BUREAU-casedesktop.svg';
import wrongobjects from '../WrongObjects.json';
import '../ModalWrongObject.css';
import '../Modals.css';
import ModalWrongObject from '../ModalWrongObject';

const DesktopLockers = ({ WrongObjectsModal, language, dispatch }) => {
  const { title, closemodal } = wrongobjects.office.desktoplockers;
  const message = wrongobjects.office.desktoplockers[language];
  const handleClick = () => {
    dispatch({
      type: 'SHOW_MODAL',
      payload: {
        whichRoom: 'Desktop',
        typeOfModal: 'WrongObjectsModal',
        whichModal: 'modalDesktopLockers',
        show: true,
      },
    });
    dispatch({ type: 'INCREMENT_COUNTER' });
  };

  return (
    <>
      {WrongObjectsModal.modalDesktopLockers && (
        <ModalWrongObject
          title={title}
          message={message}
          closemodal={closemodal}
          img={lockers}
        />
      )}
      <div>
        <div
          className="desktoplockers-office"
          onClick={() => handleClick()}
          onKeyDown={() => {}}
          role="presentation"
        >
          <img src={lockers} alt="Lockers of the desktop" />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  Objects: state.Objects,
  WrongObjectsModal: state.Modal.Desktop.WrongObjectsModal,
  language: state.GameUsers.language,
});

DesktopLockers.propTypes = {
  dispatch: PropTypes.func.isRequired,
  WrongObjectsModal: PropTypes.shape({
    modalDesktopLockers: PropTypes.bool.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(DesktopLockers);
