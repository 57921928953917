import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import hat from '../../../styles/img/Game/hat.svg';
import wrongobjects from '../WrongObjects.json';
import '../ModalWrongObject.css';
import '../Modals.css';
import ModalWrongObject from '../ModalWrongObject';

const Hat = ({ WrongObjectsModal, language, dispatch }) => {
  const { title, closemodal } = wrongobjects.office.hat;
  const message = wrongobjects.office.hat[language];
  const handleClick = () => {
    dispatch({
      type: 'SHOW_MODAL',
      payload: {
        whichRoom: 'Desktop',
        typeOfModal: 'WrongObjectsModal',
        whichModal: 'OfficeHat',
        show: true,
      },
    });
  };

  return (
    <>
      {WrongObjectsModal.OfficeHat && (
        <ModalWrongObject
          title={title}
          message={message}
          closemodal={closemodal}
          img={hat}
        />
      )}
      <div>
        <div
          className="hat-office"
          onClick={() => handleClick()}
          onKeyDown={() => handleClick()}
          tabIndex={0}
          role="button"
        >
          <img src={hat} alt="Hat of the office" draggable="false" />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  Objects: state.Objects,
  WrongObjectsModal: state.Modal.Desktop.WrongObjectsModal,
  language: state.GameUsers.language,
});

Hat.propTypes = {
  dispatch: PropTypes.func.isRequired,
  WrongObjectsModal: PropTypes.shape({
    OfficeHat: PropTypes.bool.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(Hat);
