import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const Step = ({
  step,
  indexOfStep,
  numberStep,
  history,
  setActive,
  isActive,
}) => {
  const handleRedirectionStep = (index) => {
    setActive(!isActive);

    setTimeout(() => {
      history.push(`/conversion/${index}`);
    }, 400);
  };

  const handleStep = (choiceItem) => {
    const responses = JSON.parse(localStorage.getItem('responses'));

    if (!responses) {
      return <Redirect to="/" />;
    }
    let title;
    responses
      .filter((response) => choiceItem.step === response.step)
      // eslint-disable-next-line no-return-assign
      .map((response) => (title = response.title));

    return title;
  };

  const handleStepName = () => {
    if (indexOfStep === 5 && Number(numberStep) === 5) {
      return 'Résumé';
    }

    return handleStep(step);
  };

  const handleStepNumber = () => {
    if (Number(numberStep) === indexOfStep) {
      return 'ConvertionTunnel_step_number_active';
    }
    if (Number(numberStep) > indexOfStep) {
      return 'ConvertionTunnel_step_number_past';
    }
    return 'ConvertionTunnel_step_number';
  };

  const handleStepBar = () => {
    if (Number(numberStep) === indexOfStep) {
      return 'ConvertionTunnel_step_bar_active';
    }

    if (Number(numberStep) > indexOfStep) {
      return 'ConvertionTunnel_step_bar_past';
    }
    return 'ConvertionTunnel_step_bar';
  };

  return (
    <button
      type="button"
      key={step.name}
      className="ConvertionTunnel_step"
      onClick={() => handleRedirectionStep()}
    >
      <div
        className={
          Number(numberStep) > indexOfStep
            ? 'ConvertionTunnel_step_index ConvertionTunnel_step_index_active'
            : 'ConvertionTunnel_step_index'
        }
      >
        <div className={handleStepNumber()}>{indexOfStep}</div>

        <div className="ConvertionTunnel_step_name">{handleStepName(step)}</div>
      </div>
      <div className={handleStepBar()} />
    </button>
  );
};

Step.propTypes = {
  step: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  indexOfStep: PropTypes.number.isRequired,
  numberStep: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Step;
