const initialeState = {
  hasKnife: false,
};

const havingKnife = (state = initialeState, action) => {
  if (action.type === 'KNIFE_FOUND') {
    return {
      hasKnife: action.payload,
    };
  }
  return state;
};

export default havingKnife;
