const initialState = {
  step: 1,
};

const methodoStep = (state = initialState, action) => {
  switch (action.type) {
    case 'INCREMENT_PROGRESSBAR':
      return {
        ...state,
        step: state.step + 1,
      };
    case 'DECREMENT_PROGRESSBAR':
      return {
        ...state,
        step: state.step - 1,
      };
    default:
      return state;
  }
};

export default methodoStep;
