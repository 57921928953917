import PropTypes, { object } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TunnelChoiceActions from '../TunnelChoice/TunnelChoiceActions';

const TunnelThirdQuestion = ({ ActionsChoice, initialChoice }) => {
  let typeOfCompany;
  switch (initialChoice) {
    case 'Entreprise':
      typeOfCompany = 'vos salariés';
      break;
    case 'Hospitalier':
      typeOfCompany = 'votre personnel de santé';
      break;
    case 'Université':
      typeOfCompany = 'vos étudiants';
      break;
    default:
      return typeOfCompany;
  }
  return (
    <>
      <h3>Quelle pour {typeOfCompany} : </h3>
      <div className="Tunnel-Form-Choice-Action">
        {ActionsChoice.map((element) => (
          <TunnelChoiceActions
            name={element.name}
            description={element.description}
            imageColor={element.imageColor}
            imageGrey={element.imageGrey}
            idReponse={element.idReponse}
          />
        ))}
      </div>
    </>
  );
};

TunnelThirdQuestion.propTypes = {
  ActionsChoice: PropTypes.arrayOf(object).isRequired,
  initialChoice: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  ActionsChoice: state.Actions.Actions,
  initialChoice: state.Tunnel.UserChoice.Univers,
});

export default connect(mapStateToProps)(TunnelThirdQuestion);
