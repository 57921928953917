import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clock from '../../../styles/img/Game/clock-office.svg';
import wrongobjects from '../WrongObjects.json';
import '../ModalWrongObject.css';
import '../Modals.css';
import ModalWrongObject from '../ModalWrongObject';

const Clock = ({ WrongObjectsModal, language, dispatch }) => {
  const { title, closemodal } = wrongobjects.office.clock;
  const message = wrongobjects.office.clock[language];
  const handleClick = () => {
    dispatch({
      type: 'SHOW_MODAL',
      payload: {
        whichRoom: 'Desktop',
        typeOfModal: 'WrongObjectsModal',
        whichModal: 'OfficeClock',
        show: true,
      },
    });
  };

  return (
    <>
      {WrongObjectsModal.OfficeClock && (
        <ModalWrongObject
          title={title}
          message={message}
          closemodal={closemodal}
          img={clock}
        />
      )}
      <div>
        <div
          className="clock-office"
          onClick={() => handleClick()}
          onKeyDown={() => handleClick()}
          tabIndex={0}
          role="button"
        >
          <img src={clock} alt="clock of the office" draggable="false" />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  Objects: state.Objects,
  language: state.GameUsers.language,
  WrongObjectsModal: state.Modal.Desktop.WrongObjectsModal,
});

Clock.propTypes = {
  dispatch: PropTypes.func.isRequired,
  WrongObjectsModal: PropTypes.shape({
    OfficeClock: PropTypes.bool.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(Clock);
