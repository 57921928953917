import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './Dashboard.css';

const TurnCounter = ({ NumberOfClick }) => {
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    setAnimate(!animate);
  }, [NumberOfClick]);

  return (
    <>
      <div className="TurnCounter-container">
        <h2>Coups joués</h2>
        <p>{NumberOfClick}</p>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  NumberOfClick: state.GameUsers.count,
});

TurnCounter.propTypes = {
  NumberOfClick: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(TurnCounter);
